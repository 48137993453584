import { errorToaster } from "../../utils/loggerUtils";
import { fetchUtil } from "../../utils/fetchUtils";
import { ImageUtil } from "../../utils/fetchUtils";
import { appendQueryParams } from "../../utils/urlUtils";
import { PAGINATION_LIMIT } from "../../config/constants";
import { getToken } from "../../utils/tokenUtils";

export const CHANGE_TEST_MESSAGE_MODAL_STATE = "CHANGE_TEST_MESSAGE_MODAL_STATE";
export const CHANGE_AUTO_REPLY_MODAL_STATE = "CHANGE_AUTO_REPLY_MODAL_STATE";
export const CAMPAIGN_REPORTS_REQUEST = "CAMPAIGN_REPORTS_REQUEST";
export const CAMPAIGN_REPORTS_SUCCESS = "CAMPAIGN_REPORTS_SUCCESS";
export const CAMPAIGN_REPORTS_FAIL = "CAMPAIGN_REPORTS_FAIL";
export const CAMPAIGN_CONTACTS_REQUEST = "CAMPAIGN_CONTACTS_REQUEST";
export const CAMPAIGN_CONTACTS_SUCCESS = "CAMPAIGN_CONTACTS_SUCCESS";
export const CAMPAIGN_CONTACTS_FAIL = "CAMPAIGN_CONTACTS_FAIL";
export const SCHEDULED_CAMPAIGN_REQUEST = "SCHEDULED_CAMPAIGN_REQUEST";
export const SCHEDULED_CAMPAIGN_SUCCESS = "SCHEDULED_CAMPAIGN_SUCCESS";
export const SCHEDULED_CAMPAIGN_FAIL = "SCHEDULED_CAMPAIGN_FAIL";
export const CAMPAIGN_CANCEL_SUCCESS = "CAMPAIGN_CANCEL_SUCCESS";
export const CURRENT_CAMPAIGN_REQUEST = "CURRENT_CAMPAIGN_REQUEST";
export const CURRENT_CAMPAIGN_SUCCESS = "CURRENT_CAMPAIGN_SUCCESS";
export const CURRENT_CAMPAIGN_FAIL = "CURRENT_CAMPAIGN_FAIL";

export const handleAutoReplyModal = (show) => {
    return { type: CHANGE_AUTO_REPLY_MODAL_STATE, payload: show };
};
export const handleTestMessageModal = (show) => {
    return { type: CHANGE_TEST_MESSAGE_MODAL_STATE, payload: show };
};
export const createShortenUrl = (url) => (dispatch, getState) => {
    const token = getToken();
    let body = JSON.stringify({
        FullUrl: url
    });
    return fetchUtil({
        url: "/url",
        method: "POST",
        body,
        token
    })
        .then((res) => {
            return Promise.resolve(res.Url);
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject({ err });
        });
};
export const sendTestMessage = (bodyData) => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: "/chat/test/message",
        method: "POST",
        body: JSON.stringify(bodyData),
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};
export const createCampaign = (bodyData) => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: "/campaign",
        method: "POST",
        body: JSON.stringify(bodyData),
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};

export const campaignCount = (bodyData) => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: "/campaign/count",
        method: "POST",
        body: JSON.stringify(bodyData),
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};

export const editCampaign = (bodyData) => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: "/campaign",
        method: "PUT",
        body: JSON.stringify(bodyData),
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};
export const imageUpload = (bodyData) => (dispatch, getState) => {
    const token = getToken();
    return ImageUtil({
        url: "/media",
        method: "POST",
        body: bodyData,
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};
export const getCampaignReports = (paramsObj = {}) => (dispatch, getState) => {
    dispatch({ type: CAMPAIGN_REPORTS_REQUEST, Page: paramsObj.Page });
    const token = getToken();
    fetchUtil({
        url: appendQueryParams("/report/campaigns", { Limit: PAGINATION_LIMIT, ...paramsObj }),
        token
    })
        .then((res) => {
            dispatch({ type: CAMPAIGN_REPORTS_SUCCESS, payload: res, Page: paramsObj.Page });
        })
        .catch((err) => {
            dispatch({ type: CAMPAIGN_REPORTS_FAIL });
            errorToaster(err.Message);
        });
};

export const getCurrentCampaign = (id) => (dispatch) => {
    dispatch({ type: CURRENT_CAMPAIGN_REQUEST });
    dispatch(getCampaignById(id))
        .then((res) => {
            dispatch({ type: CURRENT_CAMPAIGN_SUCCESS, payload: res });
        })
        .catch((err) => {
            dispatch({ type: CURRENT_CAMPAIGN_FAIL });
        });
};

export const getCampaignById = (id) => (dispatch, getState) => {
    const token = getToken();

    return fetchUtil({
        url: `/campaign/${id}`,
        token
    })
        .then((res) => {
            return Promise.resolve(res.Campaign);
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};

export const getContactsByCampaignId = (id, paramsObj = {}) => (dispatch, getState) => {
    dispatch({ type: CAMPAIGN_CONTACTS_REQUEST, Page: paramsObj.Page });
    const token = getToken();
    return fetchUtil({
        url: appendQueryParams(`/campaign_contacts/${id}`, {
            Limit: PAGINATION_LIMIT,
            ...paramsObj
        }),
        token
    })
        .then((res) => {
            dispatch({ type: CAMPAIGN_CONTACTS_SUCCESS, payload: res, Page: paramsObj.Page });
            return Promise.resolve(res);
        })
        .catch((err) => {
            dispatch({ type: CAMPAIGN_CONTACTS_FAIL });
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};

export const exportContactsByCampaignId = (id, bodyData) => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: `/campaign_contacts/export/${id}`,
        token,
        method: "POST",
        body: JSON.stringify(bodyData)
    })
        .then((res) => {
            return Promise.resolve(res.CampaignContacts);
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};
export const exportContactsDetailsByCampaignId = (id, bodyData) => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: `/campaign_contacts/details/export/${id}`,
        token,
        method: "POST",
        body: JSON.stringify(bodyData)
    })
        .then((res) => {
            return Promise.resolve(res.CampaignContacts);
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};

export const getScheduledCampaigns = (paramsObj = {}) => (dispatch, getState) => {
    dispatch({ type: SCHEDULED_CAMPAIGN_REQUEST, Page: paramsObj.Page });
    const token = getToken();
    fetchUtil({
        url: appendQueryParams("/scheduled/campaigns", { Limit: PAGINATION_LIMIT, ...paramsObj }),
        token
    })
        .then((res) => {
            dispatch({ type: SCHEDULED_CAMPAIGN_SUCCESS, payload: res, Page: paramsObj.Page });
        })
        .catch((err) => {
            dispatch({ type: SCHEDULED_CAMPAIGN_FAIL });
            errorToaster(err.Message);
        });
};

export const cancelScheduledCampaign = (id) => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: `/scheduled/campaign/${id}`,
        method: "PUT",
        token
    })
        .then((res) => {
            dispatch({ type: CAMPAIGN_CANCEL_SUCCESS, payload: id });
            return Promise.resolve(res);
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};
