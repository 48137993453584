import React from "react";
import { toast } from "react-toastify";

import { ToastMessage } from "../components/ToastMessage";

export const errorToaster = (msg) => {
    toast(<ToastMessage text={msg || "Something went wrong!"} icon="close" />, {
        containerId: "error",
        toastId: 1
    });
};

export const successToaster = (msg) => {
    toast(<ToastMessage text={msg || "Success!"} icon="success" />, {
        containerId: "success",
        toastId: 1,
        autoClose: 3000
    });
};

const customToaster = () => {
    let toastId = null;
    return (text) => {
        if (!toast.isActive(toastId)) {
            toastId = toast(<ToastMessage text={text} />, { containerId: "success" });
        }
    };
};

export const toaster = customToaster();
