/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { SELECT_ALL_CONTACTS_TYPES } from "../../constant";
import TextSmsCampaignContactSearch from "./TextSmsCampaignContactSearch";

const TagInputComponent = ({
    verticalSpacing = 10,
    segments,
    updateContact,
    updateSagments,
    onFocus,
    onBlur,
    selectedContacts,
    selectedSegments,
    resetTagInput,
    allContactSelected,
    deleteSelectedContacts,
    editCampaign,
    focusedBox,
    selectionType,
    updateAllContactSelection,
    hideAllContacts,
    placeholder = null
}) => {
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedContactsList, setSelectedContactsList] = useState([]);
    const [selectedSegmentsList, setSelectedSagmentsList] = useState([]);
    const [isFirstTime, setIsFirstTime] = useState(true);
    const [remainingSagments, setRemainingSagment] = useState([segments]);
    const searchComponent = React.createRef();

    useEffect(() => {
        setRemainingSagment(segments);
    }, [segments]);

    useEffect(() => {
        if (!isFirstTime) {
            return;
        }
        const contactList = Object.keys(selectedContacts);
        // setIsFirstTime(contactList.length === 0);
        let list = [];
        contactList.map((id) => {
            list = [...list, selectedContacts[id]];
            return id;
        });
        setSelectedContactsList(list);
        // console.log("selectedContacts", [...selectedTags, ...list]);
        // setSelectedTags([...selectedTags, ...list]);
    }, [selectedContacts]);

    useEffect(() => {
        const contactList = Object.keys(selectedContacts);
        // setIsFirstTime(contactList.length === 0);
        let list = [];
        let segmentTags = [];
        if (contactList.length) {
            contactList.map((id) => {
                list = [...list, selectedContacts[id]];
                return id;
            });
        }
        const segmentList = Object.keys(selectedSegments);
        // setIsFirstTime(segmentList.length === 0);
        if (segmentList.length) {
            segmentList.map((id) => {
                segmentTags = [...segmentTags, selectedSegments[id]];
                return id;
            });
            setSelectedSagmentsList(segmentTags);
            segmentTags.forEach((seg) => {
                resetSagments(seg);
            });
        }
        if (contactList.length || segmentList.length) {
            setSelectedTags([...list, ...segmentTags]);
        }
    }, [selectedContacts, selectedSegments])

    useEffect(() => {
        if (resetTagInput) {
            setSelectedTags([]);
            setRemainingSagment(segments);
        }
        resetContactSagmentLisitng();
    }, [resetTagInput]);

    const resetContactSagmentLisitng = () => {
        if (!Object.keys(selectedContacts).length) {
            setSelectedContactsList([]);
        }
        if (!Object.keys(selectedSegments).length) {
            setSelectedSagmentsList([]);
        }
    };

    const updateTagsList = (entity) => {
        setSelectedTags([...selectedTags, entity]);
    };
    // const deleteSelectedContactBox = () => {
    //     if (deleteSelectedContacts) {
    //         deleteSelectedContacts();
    //     }
    // };
    const handleContactSelect = (contact) => {
        const updContacts = [...selectedContactsList, contact];
        setSelectedContactsList(updContacts);
        updateContact(updContacts);
        updateTagsList(contact);
        setSelectedTags([...selectedTags, contact]);
    };

    const removeSegmentContacts = (segment) => {
        const updContacts = selectedContactsList.filter((contact) => {
            let contactExist = false;
            for (let userSegment of contact.Segments) {
                if (userSegment.Id === segment.Id) {
                    contactExist = true;
                }
            }
            return !contactExist;
        });
        setSelectedContactsList(updContacts);
        updateContact(updContacts);
        return updContacts;
    };

    const handleSegmentSelect = (segment) => {
        const updSegments = [...selectedSegmentsList, segment];
        setIsFirstTime(false);
        let contacts = [];
        if (selectedContactsList.length) {
            contacts = removeSegmentContacts(segment);
        }
        setSelectedSagmentsList(updSegments);
        updateSagments(updSegments);
        const updTag = [...contacts, ...selectedSegmentsList, segment];
        setSelectedTags(updTag);
        resetSagments(segment);
    };

    const resetSagments = (segment) => {
        const availableSegment = remainingSagments.filter((seg) => seg.Id !== segment.Id);
        setRemainingSagment(availableSegment);
    };

    const removeTagsAndUpdateState = (tag) => {
        const updTags = selectedTags.filter((selTag) => tag.Id !== selTag.Id);
        setSelectedTags(updTags);
        if (tag.Segments) {
            const updContacts = selectedContactsList.filter((selCon) => tag.Id !== selCon.Id);
            setSelectedContactsList(updContacts);
            updateContact(updContacts);
        } else {
            const updSeg = selectedSegmentsList.filter((selSeg) => tag.Id !== selSeg.Id);
            setSelectedSagmentsList(updSeg);
            updateSagments(updSeg);
            const updRemainingSeg = [...remainingSagments, tag];
            setRemainingSagment(updRemainingSeg);
        }
    };
    const removeAllTagsAndUpdateStateAndSelectAll = () => {
        setSelectedTags([]);
        setSelectedContactsList([]);
        updateContact([]);
        const updRemainingSeg = [...remainingSagments, ...selectedSegmentsList];
        setRemainingSagment(updRemainingSeg);
        setSelectedSagmentsList([]);
        updateSagments([]);
        updateAllContactSelection(SELECT_ALL_CONTACTS_TYPES.COMPANY);
    };

    console.log(selectedTags, "selectedTags");

    return (
        <div
            className={`${
                focusedBox && focusedBox === "contact"
                    ? `tag-input-container tag-input-container-focus`
                    : `tag-input-container`
            }`}
            style={{ marginBottom: verticalSpacing }}
        >
            <div className="tag-container">
                {selectionType !== SELECT_ALL_CONTACTS_TYPES.COMPANY ? (
                    <>
                        {/* {allContactSelected && (
                            <div className="tag-inner-container" key={"A1"}>
                                <div className="tag-text">{`Selected Contacts`}</div>
                                <div className="tag-remove" onClick={deleteSelectedContactBox}>
                                    x
                                </div>
                            </div>
                        )} */}
                        {selectedTags.map((tag, i) => (
                            <div className="tag-inner-container" key={i}>
                                <div className="tag-text">{tag.Name ? tag.Name : tag.Title}</div>
                                <div
                                    className="tag-remove"
                                    onClick={(e) => removeTagsAndUpdateState(tag)}
                                >
                                    x
                                </div>
                            </div>
                        ))}
                    </>
                ) : (
                    <>
                        <div className="tag-inner-container" key={Date.now()}>
                            <div className="tag-text">{"All Contacts"}</div>
                            <div
                                className="tag-remove"
                                onClick={(e) =>
                                    updateAllContactSelection(SELECT_ALL_CONTACTS_TYPES.NO)
                                }
                            >
                                x
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className="input-container sms-campaign-contact-search-container">
                <TextSmsCampaignContactSearch
                    updateAllContactSelection={removeAllTagsAndUpdateStateAndSelectAll}
                    hideAllContacts={hideAllContacts}
                    selectionType={selectionType}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onContactClick={handleContactSelect}
                    onSegmentClick={handleSegmentSelect}
                    ref={searchComponent}
                    selectedContacts={selectedContacts}
                    selectedSegments={selectedSegments}
                    segments={remainingSagments}
                    editCampaign={editCampaign}
                    placeholder={placeholder}
                />
            </div>
        </div>
    );
};

export default TagInputComponent;
