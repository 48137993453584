import React from "react";
import "../../App.css";
import { connect } from "react-redux";
import { uploadCsvContacts, uploadDeleteCsvContacts, downloadCsvTemplate } from "../../redux/actions";
import { errorToaster } from "../../utils/loggerUtils";
// import { WrongCross, CorrectTick } from "../../assets/svg-icon/index";
import { CloudUpload } from "../../assets/png-icon/index";
let FILE_TYPES = ["vnd.ms-excel", "csv", ""]; // TODO: remove empty string
class ImportDeleteContactModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSuccessful: false,
      isFailed: false,
      uploadedLength: null,
      isUploaded: false,
      isLoading: false
    };
    this.dragCounter = 0;
    this.abortController = null;
  }

  handleCsvDownloadClick = (e) => {
    e.preventDefault();
    this.props.downloadCsvTemplate().then((res) => {
      let hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(res);
      hiddenElement.target = "_blank";
      hiddenElement.download = `template_${new Date().getTime()}.csv`;
      hiddenElement.click();
    });
  };
  handleFileUpload = (fileObj) => {
    const fileType = fileObj.type.split("/").pop();
    this.abortController = new AbortController();

    if (FILE_TYPES.includes(fileType)) {
      this.setState({ isLoading: true });
      this.props
        .uploadDeleteCsvContacts(fileObj, this.abortController.signal)
        .then((res) => {
        console.log("🚀 ~ file: ImportDeleteContactModal.js:42 ~ ImportDeleteContactModal ~ .then ~ res:", res)
          this.props.handleImportDeleteContactModalOpen()
          this.setState({
            isSuccessful: true,
            isFailed: false,
            isLoading: false
          });
          // this.props.handleImportContactAlertOpen(SuccessContacts.length);
          this.props.onSuccess();
        })
        .catch((err) => {
          this.setState({ isLoading: false });
        });
    } else {
      errorToaster("Invalid File Type");
    }
  };

  render() {
    const { isSuccessful, isLoading } = this.state;
    const { handleImportDeleteContactModalOpen } = this.props;
    return (
      <div>
        {!isSuccessful ? (
          isLoading ? (
            <>
              <div className="import-contact-modal">
                <div className="importcontact-modal-content">
                  <div className="importcontact-modal-header">
                    <div className="fileuploadpercentage">
                      <img src={CloudUpload} alt="img" />
                      <h1 className="import-contacts-upload-heading">
                        <span>30%</span> Completed
                      </h1>
                      <div className="importcontactcsv-btn">
                        <button
                          onClick={() => this.abortController.abort()}
                          className="importcontactcsv-modal-btn"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="import-contact-modal">
              <div className="importcontact-modal-content">
                <div className="importcontact-modal-header">
                  <span
                    className="close importcontact-closebutton"
                    onClick={handleImportDeleteContactModalOpen}
                  >
                    &times;
                  </span>
                  <h3>Import Delete Contacts</h3>
                </div>
                <div className="importcontact-text">
                  <p>
                    Import Delete Contacts into the system using the template provided.
                  </p>

                  <div className="importcontact-btn">

                    <input
                      style={{ display: "none" }}
                      type="file"
                      onChange={(e) => {
                        let event = e;
                        this.handleFileUpload(event.target.files[0]);
                        event.target.value = null;
                      }}
                      accept=".csv"
                      ref={(ref) => (this.inputFile = ref)}
                    />
                    <button
                      className="importcontact-modal-btn upload-contact-file-btn"
                      onClick={() => this.inputFile.click()}
                    >
                      Upload File
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (null)

        }
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    uploadCsvContacts: (data, signal) => dispatch(uploadCsvContacts(data, signal)),
    uploadDeleteCsvContacts: (data, signal) => dispatch(uploadDeleteCsvContacts(data, signal)),
    downloadCsvTemplate: () => dispatch(downloadCsvTemplate())
  };
};

export default connect(null, mapDispatchToProps)(ImportDeleteContactModal);
