import React from "react";
import "../../../App.css";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import { sendTestMessage } from "../../../redux/actions";

import { Spinner } from "../../../components/Loader";
class SendMesageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNumber: "",
            disablePhoneNumber: false,
            textAreaRows: 1,
            showEmoji: false,
            loading: false
        };
    }
    uploadMediaObj = async (Obj) => {
        return new Promise((resolve, reject) => {
            if (Obj === null) {
                resolve(false);
            } else if (Obj.type === "image") {
                let formData = new FormData();
                formData.append("file", Obj.Obj);
                this.props.imageUpload(formData).then(
                    (res) => {
                        resolve(res.location);
                    },
                    (err) => {
                        this.setState({ loading: false });
                        reject(err);
                    }
                );
            } else {
                resolve(Obj.url);
            }
        });
    };
    onInputChange = (e) => {
        e.preventDefault();
        this.setState({
            phoneNumber: e.target.value
        });
    };
    onConfirmMessageSend = (e) => {
        this.setState({ loading: true });
        let ConfirmationRequest = 0;
        if (this.props.ConfirmationRequest) {
            ConfirmationRequest = 1;
        }
        let mediaUrl = null;
        let autoReplyMediaUrl = null;
        this.uploadMediaObj(this.props.mediaObj).then((res) => {
            if (res) {
                mediaUrl = res;
            }
            this.uploadMediaObj(this.props.autoReplyMediaObj).then((response) => {
                if (res) {
                    autoReplyMediaUrl = response;
                }
                let bodyData = {
                    PhoneNumber: this.state.phoneNumber.replace(/[-()\s]/g, ""),
                    Content: this.props.messageText,
                    ConfirmationRequest: ConfirmationRequest,
                    mediaUrl: mediaUrl,
                    AutoMediaUrl: autoReplyMediaUrl
                };
                this.props.sendTestMessage(bodyData).then((res) => {
                    this.props.onClose();
                    this.setState({ loading: false });
                });
            });
        });
    };
    render() {
        return (
            <div className={`${this.props.type}-modal-wrapper custom-modal`}>
                <div className="modal-header-Auto-Reply">
                    <button
                        onClick={() => {
                            this.props.onClose();
                        }}
                        className="close"
                    >
                        &times;
                    </button>
                </div>

                <div className="modal-body reviewed-modal">
                    <div className="row">
                        <div className="col text-center">
                            <h3 className="modal-title">Send a test to be reviewed</h3>
                            <label>Enter the phone number the message will be sent:</label>
                            <div className="form-group send-message-input">
                                <InputMask
                                    id="sendMessage"
                                    mask="+1 (999)-999-9999"
                                    className={`form-control contact-no`}
                                    placeholder="Phone Number"
                                    name="phoneNumber"
                                    onChange={this.onInputChange}
                                    value={this.state.phoneNumber}
                                    type="text"
                                    required
                                    disabled={this.state.disablePhoneNumber}
                                    autoComplete="off"
                                />
                            </div>
                            <div className="form-group">
                                <button
                                    disabled={this.state.loading}
                                    onClick={() => {
                                        this.onConfirmMessageSend();
                                    }}
                                    className="custom-btn"
                                >
                                    {this.state.loading ? (
                                        <Spinner size="lg" color="white" />
                                    ) : (
                                        `Send Test`
                                    )}{" "}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};
const mapDispatchToProps = (dispatch) => {
    return {
        sendTestMessage: (bodyData) => dispatch(sendTestMessage(bodyData))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SendMesageModal);
