import React, { useEffect, useState } from "react";
import Config from "../../config";
import { getFormattedNumberNoDash } from "../../utils/commonUtils";
const fieldAttr = {
    ContactCardMessage: "ContactCardMessage",
    InvitedMessage: "InvitedMessage"
};
function AccountSettingTab(props) {
    const [companyInviteMessage, setCompanyInviteMessage] = useState([]);
    const [contactCardMessage, setContactCardMessage] = useState("");
    useEffect(() => {
        setCompanyInviteMessage(props.companyInviteMessage);
        setContactCardMessage(props.contactCardMessage);
    }, [props.companyInviteMessage, props.contactCardMessage]);
    let companyName = props.user.Company.Name || "";
    let companyPhoneNumber = props.user.Company.PhoneNumber || "";
    let logo =
        props.user.Company.Logo && props.user.Company.Logo.ThumbPath
            ? props.user.Company.Logo.ThumbPath
            : "";
    let userName = props.user.Name;
    let userEmail = props.user.Email;
    const handleInputChange = (e) => {
        let value = e.target.value;
        if(value.length < companyInviteMessage.length ){
            if (fieldAttr.InvitedMessage === e.target.name) {
                props.updateCompanyInviteMessage(value);
            } else {
                props.updateContactCardMessage(value);
            }
        }else if (value.length <= 160) {
            if (fieldAttr.InvitedMessage === e.target.name) {
                props.updateCompanyInviteMessage(value);
            } else {
                props.updateContactCardMessage(value);
            }
        }
    };
    return (
        <div className="setting-tab-content-account">
            <div className="row">
                <div className="w-2">
                    <div className="account-row1-box p-0">
                        <div
                            className="account-logo"
                            style={{
                                backgroundImage: `url(${Config.env().BASE_IMG_PATH}/${logo})`
                            }}
                        ></div>
                    </div>
                </div>
                <div className="w-5">
                    <div className="account-row1-box">
                        <h4>Company</h4>
                        <p>{companyName}</p>
                        <p> {getFormattedNumberNoDash(companyPhoneNumber)}</p>
                    </div>
                </div>
                <div className="w-5">
                    <div className="account-row1-box">
                        <h4>User</h4>
                        <p>{userName} </p>
                        <p>{userEmail}</p>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="w-12">
                    <div className="account-row2-box">
                        <h4 style={{ marginBottom: 6.66 }}>Segments</h4>
                        <ul>
                            {props.segments.map((segment, index) => {
                                return (
                                    <li key={index}>
                                        <span>{segment.Title}</span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="w-12">
                    <div className="account-row3-box">
                        <h4 style={{ color: "#1D3152", marginBottom: 6.66 }}>
                            Individual Contact Card Message
                        </h4>
                        <textarea
                            disabled={!props.isAdmin}
                            onBlur={props.submitContactCardMessage}
                            name={fieldAttr.ContactCardMessage}
                            onChange={(e) => handleInputChange(e)}
                            value={contactCardMessage || ""}
                        ></textarea>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="w-12">
                    <div className="account-row3-box">
                        <h4 style={{ color: props.isAdmin ? "#1D3152" : ``, marginBottom: 6.66 }}>
                            Onboard Auto Message
                        </h4>
                        <textarea
                            disabled={!props.isAdmin}
                            onBlur={props.submitCompanyInvitedMessage}
                            // readOnly={!(props.isAdmin && !isSSOAuthenticated())}
                            name={fieldAttr.InvitedMessage}
                            onChange={(e) => handleInputChange(e)}
                            value={companyInviteMessage || ""}
                        ></textarea>
                        <p>
                            Need to update segments? <span>Contact us.</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccountSettingTab;
