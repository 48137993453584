import React, { useState } from "react";
import { useCancelCampaign, useGetScheduledCampaigns } from "../../../hooks/api/campaigns";
import { Button, Table, Tooltip, Spin, Tag, Popconfirm } from "antd";
import { MailFilled, MessageFilled } from "@ant-design/icons";
import moment from "moment";
import Header from "../../../components/atoms/Typography/Header";
import { useHistory } from "react-router-dom";
import { useGetContacts, useGetSegments } from "../../../hooks/api/contacts";
import { isEmpty, truncate } from "lodash";

const ScheduledCampaigns = ({ search }) => {
  const history = useHistory();
  const segments = useGetSegments();
  const [{Contacts: contacts = []}] = useGetContacts({ByPhoneNumber: "false"});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
    showSizeChanger: false
  })

  const cancelCampaign = useCancelCampaign();
  const {data, isLoading} = useGetScheduledCampaigns({
    Limit: 5,
    Page: pagination.current,
    Title: search ?? null,
  }, {
    onSuccess: ({TotalCampaigns}) => setPagination(prev => ({...prev, total: TotalCampaigns}))
  });

  const columns = [{
    title: "Name",
    dataIndex: "Title",
    key: "Title",
    render: (_, { Type, Title, Content }) =>
    <div className="flex gap-2">
      {([1, 2].includes(Type))
        ? <Tooltip title={Content}>{truncate(Content)}</Tooltip>
        : Title
      }
    </div>
  },{
    title: "Type",
    key: "Type",
    render: (_, { Type, AsEmailFallback }) =>
    <div className="flex gap-2">
      {([1, 2].includes(Type) || !!AsEmailFallback) && <MessageFilled className="text-2xl text-gray-500" />}
      {Type === 3 && <MailFilled className="text-2xl text-gray-500" />}
    </div>
  },{
    title: "Recipients",
    render: (_, { Meta }) => {
      if(Meta?.AllContacts === 1) return <Tag>All</Tag>;
      return (
        <>
          {Meta?.SegmentIds?.map((id) => (
            <Tag key={id}>{segments?.find(({Id}) => Id === id)?.Title}</Tag>
          ))}
          {Meta?.ContactIds?.map((id) => (
            <Tag key={id}>{contacts?.find(({Id}) => Id === id)?.Name}</Tag>
          ))}
        </>
      )
    }
  },{
    title: "Scheduled for",
    dataIndex: "SentOn",
    key: "SentOn",
    render: (_, {SentOn}) => moment(SentOn).format('MM/DD/YYYY @h:mma zz')
  },{
    title: "",
    key: "Actions",
    render: (_, campaign) => (
    <div className="flex gap-2">
      <Button onClick={() => {
        campaign.Type === 3
          ? history.push(`/dashboard/email/edit-campaign/${campaign.Id}`)
          : history.push(`/dashboard/text/edit-campaign/${campaign.Id}`)
      }}>
        Edit
      </Button>
      <Popconfirm
        title="Are you sure you want to cancel this campaign?"
        description="This action is irreversible."
        okType="default"
        okText="Yes"
        showCancel={false}
        onConfirm={() => cancelCampaign(campaign.Id)}
      >
        <Button danger>
          Cancel
        </Button>
      </Popconfirm>
    </div>
    )
  }]

  if(!isEmpty(data?.Campaigns) || isLoading) {
    return (
      <Spin spinning={isLoading}>
        <Header>Scheduled Campaigns</Header>
        {!isLoading && (
          <Table
            columns={columns}
            dataSource={data?.Campaigns || []}
            pagination={pagination}
            loading={isLoading}
            onChange={pagination => setPagination(prev => ({...prev, ...pagination}))}
          />
        )}
      </Spin>
    );
  }
  return <></>;
}

export default ScheduledCampaigns;
