import React from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import { signOut } from "../../redux/actions";
import { Spinner } from "../../components/Loader";
import { isSSOAuthenticated } from "../../utils/ssoUtils";

class Logout extends React.Component {
    componentDidMount() {
        this.props
            .signOut()
            .then((res) => {
                this.props.history.replace("/account/login");
            })
            .catch((err) => {
                localStorage.clear();
            });
    }
    render() {
        if (!this.props.isAuthenticated && !isSSOAuthenticated()) {
            return <Redirect to="/account/login" />;
        }
        return (
            <div
                style={{
                    width: "100vw",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <Spinner color="green" size="xxxl" />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Logout));
