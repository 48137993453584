import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import withUser from "../../hoc/withUser";
import DefaultAside from "./DefaultAside";
import routes from "../../routes";
import { isSSOAuthenticated } from "../../utils/ssoUtils";
import { ApplicationTypes } from "../../config/constants";
import { useEffect } from "react";
import { getWebchatUnreadCount } from "../../redux/actions";

function DefaultLayout(props) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getWebchatUnreadCount());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isClubChatAccessAllowed = () => {
        let clubChat = props.user.Apps.find((app) => app.Id === ApplicationTypes.CLUB_CHAT);
        if (clubChat) {
            return true;
        }
        return false;
    };

    if (!props.userAuthenticated && sessionStorage.apiKey) {
        return <Redirect to={`/SSO/${sessionStorage.apiKey}`} />;
    } else if (!props.isAuthenticated && !isSSOAuthenticated()) {
        return <Redirect to="/account/login" />;
    }

    const getRolesBasedRoute = () => {
        let clubChat = props.user.Apps.find((app) => app.Id === ApplicationTypes.CLUB_CHAT);
        let webChat = props.user.Apps.find((app) => app.Id === ApplicationTypes.WEB_CHAT);
        let filteredRoutes = [];
        if (clubChat) {
            filteredRoutes = routes.filter((item) => item.permission.includes(clubChat.Id));
        }
        if (webChat) {
            filteredRoutes = [
                ...filteredRoutes,
                ...routes.filter((item) => item.permission.includes(ApplicationTypes.WEB_CHAT))
            ];
        }
        return filteredRoutes;
    };

    return (
        <div id="app-wrapper" className="app-wrapper" style={{ position: 'relative' }}>
            <DefaultAside user={props.user} />
            <div className="main-content" id="main-content">
                <Switch>
                    {getRolesBasedRoute().map((route, index) => {
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                name={route.name}
                                exact={route.exact}
                                render={(props) => (
                                    <route.component
                                        {...props}
                                        heading={route.name}
                                        childRoutes={route.routes}
                                    />
                                )}
                            />
                        );
                    })}
                    {isClubChatAccessAllowed() ? (
                        <Redirect to="/dashboard/inbox" />
                    ) : (
                        <Redirect to="/dashboard/webchat" />
                    )}
                </Switch>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        userAuthenticated: state.ssoAuth.userAuthenticated,
        user: state.auth.user
    };
};

export default connect(mapStateToProps, null)(withUser(DefaultLayout));
