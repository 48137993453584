import { Checkbox } from "pretty-checkbox-react";
import React from "react";
import "../../App.css";
import { Spinner } from "../../components/Loader";

const ConfirmationModal = (props) => {
    const {
        handleModalClose,
        content,
        title,
        handleModalClick,
        buttonContent,
        color,
        startLoading,
        buttonDisabled,
        handleCheckboxStatus,
        checkboxStatus,
        checkBoxMessage,
        checkBox
    } = props;

    return (
        <div className="delete-contact-modal">
            <div className="delete-contact-modal-content">
                <div className={`delete-contact-modal-header ${color}`}>
                    <span onClick={handleModalClose} className="close importcontactcsv-closebutton">
                        &times;
                    </span>
                    <h3>{title}</h3>
                </div>
                <div className="delete-contact-modal-text">
                    <p>{content}</p>
                </div>
                <div className={`sendaction`}>
                    <div className={`onboarddiv`}>
                        {checkBox ? (
                            <div class={`onBoard`}>
                                <Checkbox
                                    checked={checkboxStatus}
                                    onChange={(e) => handleCheckboxStatus(e)}
                                />
                                {/* <RoundCheckBox id={CHECKBOX_SELEC.NO} checked={checkboxStatus} onChange={(e) => handleCheckboxStatus(e)} /> */}
                                <p> {checkBoxMessage}</p>
                            </div>
                        ) : null}
                    </div>
                    <div className={`sendnowbtn`}>
                        {startLoading ? (
                            <Spinner size="lg" color={`${color}`} />
                        ) : (
                            // <Spinner size="lg" color={`black`} />
                            <button
                                className={`delete-contact-modal-btn ${color}`}
                                onClick={handleModalClick}
                                disabled={buttonDisabled}
                                style={{ opacity: buttonDisabled ? 0.5 : 1 }}
                            >
                                {buttonContent}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ConfirmationModal;
