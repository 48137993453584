import React from "react";

import "./RegistrationSuccess.css";
import { Success } from "../../assets/svg-icon";

class RegistrationSuccess extends React.Component {
    render() {
        return (
            <div className="registration-success-main-container">
                <div className="registration-success-main-wrapper">
                    <div className="registration-success-icon">
                        <img src={Success} alt="check" />
                    </div>
                    <h1 className="registration-success-heading">Registration Successful</h1>
                </div>
            </div>
        );
    }
}

export default RegistrationSuccess;
