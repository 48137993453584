import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {
    closeEyeLogo,
    openEyeLogo,
    footerLogoImage,
    LogoSvg
} from "../../../assets/svg-icon/index";
import "../Form.css";
import { login } from "../../../redux/actions";
import { Spinner } from "../../../components/Loader";
import { isSSOAuthenticated } from "../../../utils/ssoUtils";

const Login = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [hidden, toggleShow] = useState(false);
    const dispatch = useDispatch();
    const handleLoginSubmit = (e) => {
        e.preventDefault();
        if (email.trim().length === 0 || password.trim().length === 0) {
            return;
        }
        setIsLoading(true);
        dispatch(login(email, password)).catch((err) => {
            setIsLoading(false);
        });
    };
    if (props.isAuthenticated || isSSOAuthenticated()) {
        return <Redirect to="/dashboard" />;
    }
    return (
        <div className="login-screen">
            <div className="container">
                <div className="row">
                    <div className="w-12">
                        <div className="login-box">
                            <form className="login-form" onSubmit={handleLoginSubmit}>
                                <div className="login-logo">
                                    <img src={LogoSvg} className="img-fluid" alt="img" />
                                </div>
                                <div className="login-form">
                                    <div className="form-group">
                                        <label>Email Address</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            value={email}
                                            required
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <div className="p-relative">
                                            <input
                                                type={!hidden ? "password" : "text"}
                                                required
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                className="form-control"
                                            />
                                            <span
                                                className="eye"
                                                onClick={() => toggleShow(!hidden)}
                                            >
                                                {!hidden ? (
                                                    <img
                                                        src={closeEyeLogo}
                                                        className="img-fluid"
                                                        alt="img"
                                                    />
                                                ) : (
                                                    <img
                                                        src={openEyeLogo}
                                                        className="img-fluid"
                                                        alt="img"
                                                    />
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button
                                            type="submit"
                                            className={`${
                                                email.trim().length !== 0 &&
                                                password.trim().length !== 0
                                                    ? `valid`
                                                    : ``
                                            }`}
                                            disabled={isLoading}
                                        >
                                            <span>login</span>
                                            {isLoading ? <Spinner color="white" size="lg" /> : null}
                                        </button>
                                        <a href="/account/forget">Forgot Password</a>
                                    </div>
                                </div>
                                <div className="login-footer-logo">
                                    <img src={footerLogoImage} className="img-fluid" alt="img" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated
    };
};

export default connect(mapStateToProps, null)(Login);
