import React from "react";

import "./LoadingPage.css";
import { Spinner } from "../../components/Loader";

const LoadingPage = (props) => {
    const { loading, error } = props;
    return (
        <div className="verify-container">
            <div className="verify-page-content">
                <div className="verify-page-loader">
                    {loading && <Spinner size="xxxl" color="gray" />}
                </div>
                <div className="verify-page-text">
                    {!error ? "Verifying..." : "Authentication failed, unauthorized token"}
                </div>
            </div>
        </div>
    );
};

export default LoadingPage;
