import React, { useState } from "react";
import { CheckOutlined, EditOutlined } from "@ant-design/icons";

const EditWrapper = ({
  children,
  value
}) => {
  const [enabled, enable] = useState(false);

  if(enabled) {
    return (
      <label className="flex gap-2 items-center">
        {children}
        <CheckOutlined className="inline mx-2 align-baseline" onClick={() => enable(false)} />
      </label>
    )
  }
  return (
    <label>
      {value}
      <EditOutlined className="inline mx-2 align-baseline" onClick={() => enable(true)} />
    </label>
  )
}

export default EditWrapper;
