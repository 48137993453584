import io from "socket.io-client";
import Config from "../config";
import { store } from "../redux/store";
import { changeSocketStatus, setPendingMessageError } from "../redux/actions";
import { getToken } from "../utils/tokenUtils";
import { isSSOAuthenticated } from "../utils/ssoUtils";
class Socket {
    static socket = null;

    static getSocket = () => {
        if (Socket.socket === null) {
            const actorId = isSSOAuthenticated()
                ? store.getState().ssoAuth.user.Id
                : store.getState().auth.user.Id;
            const authToken = getToken();
            Socket.socket = io(
                `${
                    Config.env().API_URL
                }?actorId=${actorId}&actorType=${1}&authorization=${authToken}`,
                {
                    transports: ["websocket"],
                    upgrade: false,
                    reconnection: true,
                    reconnectionAttempts: Infinity,
                    reconnectionDelay: 1000,
                    reconnectionDelayMax: 5000
                }
            );
        }
        return Socket.socket;
    };

    static init = () => {
        Socket.getSocket().on("connect", () => {
            console.log("socket connected");
            store.dispatch(changeSocketStatus(true));
        });

        Socket.getSocket().on("disconnect", (reason) => {
            console.log(" I AM DISCONNECTED", reason);
            store.dispatch(changeSocketStatus(false));
            if (window.location.pathname.includes("inbox")) {
                store.dispatch(setPendingMessageError());
            }
        });

        Socket.getSocket().on("error", (error) => {
            if (error.type === 401) {
                console.log("not authorized");
            }
        });
    };

    static sendMessage = (data, cb) => {
        const { ChatId, Content, SenderId, SenderType, MessageUUID, MediaUrl } = data;
        // TODO Change this with our media URL or mark as NULL
        Socket.getSocket().emit(
            "Message",
            {
                MediaUrl,
                ChatId,
                Content,
                SenderId,
                SenderType,
                MessageUUID
            },
            cb
        );
    };

    static messageRead = (data) => {
        let { chatId, chatMessageId } = data;

        Socket.getSocket().emit("MessageRead", {
            ChatId: chatId,
            ChatMessageId: chatMessageId
        });
    };

    static listenMessageRead = (cb) => {
        Socket.getSocket().on("MessageRead", (data) => {
            cb(data);
        });
    };

    static listenMessage = (cb) => {
        Socket.getSocket().on("Message", (data) => {
            cb(data);
        });
    };

    static listenReadUnreadWebchat = (cb) => {
        Socket.getSocket().on("webchat_unread_rooms", (data) => {
            cb(data);
        });
    };

    static listenContactStatus = (cb) => {
        Socket.getSocket().on("ContactOptStatus", cb);
    };

    static remove = (name, listener = null) => {
        if (Socket.socket) {
            if (listener) {
                Socket.getSocket().removeListener(name, listener);
            } else {
                Socket.getSocket().removeAllListeners(name);
            }
        }
    };

    static listenContactUpload = (id, cb) => {
        Socket.getSocket().on(`contact-upload-${id}`, (data) => {
            cb(data);
        });
    };

    static joinRoom = (data) => {
        Socket.getSocket().emit("join-room", {
            room: data.room
        });
    };
    
    static leaveRoom = (data) => {
        Socket.getSocket().emit("leave-room", {
            room: data.room
        });
    };
}

export default Socket;

