import React from "react";

import "../../../App.css";
// import { Spinner } from "../../../components/Loader";
import { scrollToBottom } from "../../../utils/scrollUtils";
import anchorme from "anchorme";
import { Avatar } from "../../../components/Avatar";

function InboxSentMessages(props) {
    const { messageData, time, onMessageResend, firstMessageClass, userData } = props;
    let companyFirstName = userData.Company ? userData.Company.Name : "";
    let companyLastName = userData.Company ? userData.Company.Name : "";
    let companySplitlLastName = userData.Company ? userData.Company.Name.split(" ")[1] : "";
    let sentTag = "Sent";
    if (messageData.SenderInfo) {
        try {
            const senderData = JSON.parse(messageData.SenderInfo);
            if (senderData && senderData.SenderName) {
                companyFirstName = senderData.SenderName.split(" ")[0] || companyFirstName;
                companyLastName = senderData.SenderName.split(" ")[1] || companyFirstName[1];
            }
            if (senderData && senderData.AutoResponse) {
                sentTag = "Auto-Reply Sent";
            }
        } catch (e) {
            console.log(e, "ERROR");
        }
    } else if (companySplitlLastName) {
        companyLastName = companySplitlLastName;
    } else {
        companyLastName = companyFirstName[1] || "";
    }
    let { media, message } = props;
    message = anchorme(message);
    let isLoading = messageData.hasOwnProperty("isAcknowledged") && !messageData.isAcknowledged;
    let isError = messageData.hasOwnProperty("Error") && messageData.Error;
    let img = null;
    const Scroll = () => {
        if (props.isImageLoadingScroll) {
            scrollToBottom();
        }
    };
    if (media) {
        img = <img onLoad={Scroll} alt="uploaded" src={media} />;
    } else {
        media = null;
    }
    return (
        <div
            className={`inbox-chatbox-sent-message-container message-sender ${
                firstMessageClass ? firstMessageClass : ""
            } `}
        >
            <div className="row">
                <div className="w-11">
                    <div className="message-sender-chat">
                        <div className="new-inbox-chatbox-sent-message-wrapper">
                            <div className="inbox-chatbox-sent-message">
                                <div
                                    className="inbox-chatbox-sent-message-content"
                                    dangerouslySetInnerHTML={{ __html: message }}
                                ></div>
                                <div onClick={() => window.open(media)}>{img}</div>
                            </div>
                        </div>
                        <label className="inbox-chatbox-sent-message-date">
                            {!isLoading && !isError ? (
                                <>
                                    {sentTag}: {time}
                                </>
                            ) : isError ? (
                                <span
                                    className="inbox-message-error"
                                    onClick={() => onMessageResend(messageData, media)}
                                >
                                    Message failed
                                </span>
                            ) : isLoading ? (
                                // <Spinner color="green" />
                                <span style={{ display: "flex", justifyContent: "end" }}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="10"
                                        viewBox="0 0 20 20"
                                    >
                                        <circle
                                            cx="10"
                                            cy="10"
                                            r="8"
                                            stroke="#008000"
                                            strokeWidth="1"
                                            fill="none"
                                        />
                                    </svg>
                                </span>
                            ) : null}
                        </label>
                    </div>
                </div>
                <div className="w-1" style={{ textAlign: "center" }}>
                    <Avatar
                        FirstName={companyFirstName}
                        LastName={companyLastName}
                        myClass="message-sender-img"
                    />
                </div>
            </div>
        </div>
    );
}

export default InboxSentMessages;

