import React from "react";
import "../../../App.css";
import { scrollToBottom } from "../../../utils/scrollUtils";
import { Avatar } from "../../../components/Avatar";
function InboxReceivedMessage(props) {
    let { message, time, media, name, messageData } = props;
    let companyFirstName = name || "";
    let companyLastName = name || "";
    let companySplitlLastName = name.split(" ")[1];
    if (messageData.SenderInfo) {
        try {
            const senderData = JSON.parse(messageData.SenderInfo);
            if (senderData && senderData.SenderName) {
                companyFirstName = senderData.SenderName.split(" ")[0] || companyFirstName;
                companyLastName = senderData.SenderName.split(" ")[1] || companyFirstName[1];
            }
        } catch (e) {}
    } else if (companySplitlLastName) {
        companyLastName = companySplitlLastName;
    } else {
        companyLastName = companyFirstName[1] || "";
    }
    let img = null;
    if (media) {
        img = (
            <img
                onLoad={() => scrollToBottom()}
                alt="uploaded"
                src={media}
                style={{ maxHeight: "400px", maxWidth: "350px" }}
            />
        );
    } else {
        media = null;
    }
    return (
        <div className="inbox-chatbox-received-message-container message-receiver">
            <div className="row">
                <div className="w-1" style={{ textAlign: "center" }}>
                    <Avatar
                        FirstName={companyFirstName}
                        LastName={companyLastName}
                        myClass="message-receiver-img"
                    />
                </div>
                <div className="w-11">
                    <div className="message-receiver-chat">
                        <div className="new-inbox-chatbox-received-message-wrapper">
                            <div className="inbox-chatbox-received-message">
                                <div className="inbox-chatbox-received-message-content">
                                    {message}
                                </div>
                                <div>{img}</div>
                            </div>
                        </div>
                        <label className="inbox-chatbox-received-message-date">
                            {" "}
                            Received: {time}{" "}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InboxReceivedMessage;
