import React from "react";
// import { SearchCloseIcon } from "../../assets/svg-icon/index";
import "../../App.css";
function SearchBar({
    placeholder,
    onSubmit,
    onChange,
    value,
    classes,
    clearText,
    clearSearchText,
    ...rest
}) {
    // let searchRef = React.createRef();
    const handleSubmit = (e) => {
        if (!onSubmit) {
            e.preventDefault();
            return;
        }
        onSubmit(e);
    };
    // const handleClose = (e) => {
    //     searchRef.value = "";
    //     e.target.value = "";
    //     onChange(e);
    // }
    const createInputReference = (ref) => {
        // searchRef = ref;
        if (clearText && ref) {
            clearSearchText(false);
            ref.value = "";
        }
    };
    return (
        <div className="search-field-wrapper">
            <form style={{ position: "relative" }} onSubmit={handleSubmit}>
                <input
                    ref={(ref) => createInputReference(ref)}
                    className={`search-bar ${classes}`}
                    type="text"
                    placeholder={placeholder}
                    onBlur={handleSubmit}
                    onChange={onChange}
                    style={{
                        boxShadow: "none !important",
                        width: "100%"
                    }}
                    // value={value}
                    {...rest}
                />
                {/* {value.length > 0 ? (<img src={SearchCloseIcon} alt="img" onClick={(e) => handleClose(e)} className="Search-Close-Btn" />) : null} */}
                <button className="hidden" type="submit"></button>
            </form>
        </div>
    );
}

export default SearchBar;
