import React from "react";
import { connect } from "react-redux";
import { Spinner } from "../../Loader";
import { ChatMessageTypes } from "../../../config/constants";
import { convertDateTime } from "../../../utils/dateTimeUtils";
import { InboxChatBoxInput, InboxSentMessages, InboxReceivedMessage } from "../";
import withUser from "../../../hoc/withUser";
import InboxMessageNotification from "../InboxMessageNotification/InboxMessageNotification";
class InboxMessageArea extends React.Component {
    getSnapshotBeforeUpdate() {
        let scrollContainer = document.getElementById("messages-parent");
        return {
            prevScrollHeight: scrollContainer.scrollHeight,
            prevScrollTop: scrollContainer.scrollTop
        };
    }
    componentDidUpdate(_, __, { prevScrollTop, prevScrollHeight }) {
        let scrollContainer = document.getElementById("messages-parent");

        scrollContainer.scrollTop = scrollContainer.scrollHeight - prevScrollHeight + prevScrollTop;
    }
    render() {
        const {
            handleScroll,
            currentChatMessages,
            fetching,
            handleMessageResend,
            handleMessageSend,
            user,
            isImageLoadingScroll
        } = this.props;
        const getUserData = () => {
            const { currentChatData } = this.props;
            let name = currentChatData.Contact
                ? `${currentChatData.Contact.FirstName} ${currentChatData.Contact.LastName}`
                : "-";
            let contact = currentChatData.Contact ? currentChatData.Contact.PhoneNumber : "-";
            return {
                name,
                contact
            };
        };
        return (
            <div
                className={
                    this.props.showNotificationPermission
                        ? "inbox-chatbox-body-min-height fancy-scroll"
                        : "inbox-chatbox-body fancy-scroll"
                }
                id="chatbox-scroll-div"
                style={{ position: "relative" }}
            >
                {currentChatMessages.length > 0 && fetching && (
                    <div
                        className="table-scroll-loader"
                        style={{ position: "absolute", top: "0", left: "50%" }}
                    >
                        <Spinner size="xl" color="green" />
                    </div>
                )}
                <div
                    className="inbox-chatbot-message-wrapper middle-chat-content"
                    id="messages-parent"
                    onScroll={handleScroll}
                >
                    {currentChatMessages.length > 0 &&
                        currentChatMessages.map((data, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {data.SenderType === ChatMessageTypes.ONBOARDFORMNOTIFICATION ||
                                    data.SenderType === ChatMessageTypes.CONTACTCARDNOTIFICATION ? (
                                        <InboxMessageNotification
                                            userData={user}
                                            messageData={data}
                                            message={data.Content}
                                            media={data.MediaUrl}
                                            time={convertDateTime(
                                                data.CreatedAt
                                            ).toCompanyTimezone()}
                                            onMessageResend={handleMessageResend}
                                            isImageLoadingScroll={isImageLoadingScroll}
                                            senderType={data.SenderType}
                                        />
                                    ) : data.SenderType === ChatMessageTypes.COMPANY ? (
                                        <InboxSentMessages
                                            userData={user}
                                            messageData={data}
                                            message={data.Content}
                                            media={data.MediaUrl}
                                            time={convertDateTime(
                                                data.CreatedAt
                                            ).toCompanyTimezone()}
                                            onMessageResend={handleMessageResend}
                                            isImageLoadingScroll={isImageLoadingScroll}
                                        />
                                    ) : (
                                        <InboxReceivedMessage
                                            messageData={data}
                                            message={data.Content}
                                            media={data.MediaUrl}
                                            name={getUserData().name}
                                            time={convertDateTime(
                                                data.CreatedAt
                                            ).toCompanyTimezone()}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        })}
                    {!currentChatMessages.length ? (
                        <div className="inbox-chatbox-body-loader">
                            {fetching ? (
                                <Spinner size="xxl" color="green" />
                            ) : (
                                <p className="no-data-text" style={{ color: "#A7B9C5" }}>
                                    No Messages to show
                                </p>
                            )}
                        </div>
                    ) : null}
                </div>
                <div id="input-container" className="middle-chat-form">
                    {
                        <div style={{ flexGrow: "1" }}>
                            <InboxChatBoxInput
                                name={getUserData().name}
                                onMessageSend={handleMessageSend}
                            />
                        </div>
                    }
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        isImageLoadingScroll: state.chat.isImageLoadingScroll,
        currentChatData: state.chat.currentChat.data,
        showNotificationPermission: state.auth.showNotificationPermission
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withUser(InboxMessageArea));
