import React, { useMemo, useCallback, useState } from "react";
import { toast } from "react-toastify";

import moment from "moment";

import head from "lodash/head";
import pick from "lodash/pick";
import omit from "lodash/omit";
import keys from "lodash/keys";
import noop from "lodash/noop";

import { Drawer, Divider, Checkbox, Tooltip, Popconfirm, Spin, Input } from "antd";
import { MailOutlined, NumberOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";

import { useGetChatNotificationsStatus } from "../../../hooks/api/chats";
import { convertDateTime } from "../../../utils/dateTimeUtils";
import { OnBoardIcon, SendContactCardIcon, contactCardIconGreen, onBoardIconGreen } from "../../../assets/svg-icon";
import EditableInput from "../../atoms/Input/Editable";
import { useGetSegments, useSendContactCard, useSendOnBoardForm, useUpdateContact } from "../../../hooks/api/contacts";

const BASE_CONTACT_FIELDS = ["Id", "AppId", "CompanyId"];

const ContactDetails = ({
  data,
  onClose = noop,
  refech,
  reftechHandler
}) => {
  const segments = useGetSegments();
  const {
    data: {
      ContactCard,
      Onboard
    } = {},
    isFetching: isFetchingChatData
  } = useGetChatNotificationsStatus(data?.Id);
  
  const [error, setError] = useState(null);
  const [onBoardMessage, sendOnboardMessage] = useState(true);

  const updateContact = useUpdateContact({
    onSuccess: () => {

      toast("Updated", { containerId: "success" });
      reftechHandler()
      if(refech){
        refech();
      }
    },
    onError: (res, data) => {
      toast(res?.Message || "Something went wrong", { containerId: "error" });
      setError(head(keys(omit(data, BASE_CONTACT_FIELDS))));
    },
  });

  const sendOnBoardForm = useSendOnBoardForm({
    onSuccess() {toast("Onboard Form Sent!", { containerId: "success" })},
    onError() {toast("Something went wrong", { containerId: "error" })},
  });
  const sendContactCard = useSendContactCard({
    onSuccess() {toast("Contact Card Sent!", { containerId: "success" })},
    onError() {toast("Something went wrong", { containerId: "error" })},
  });
 
  const updateContactField = useCallback((field) => (value) =>{
    let payload= {
      ...pick(data, BASE_CONTACT_FIELDS),
      AgeGatedStatus: data?.AgeGatedStatus,
      Email:data?.Email,
      FirstName:data?.FirstName,
      LastName:data?.LastName,
      MemberId:data?.MemberId,
      BirthdayDate:contactMeta?.BirthdayDate,
      Gender:contactMeta?.Gender,
      Note:contactMeta?.Note,
      SegmentIds:data?.Segments.map(({Id}) => Id),
    };
    payload[field]=value
    updateContact({...payload});

     //  eslint-disable-next-line
  }, [updateContact, data]) 
  
  const resetError = (field) => () => error === field && setError(null);

  const contactMeta = useMemo(() => JSON.parse(data?.Meta || "{}"), [data]);
  const initials = useMemo(() => data && `${data?.FirstName[0]}${data?.LastName[0]}`.toUpperCase(), [data]);

  return (
    <Drawer
      open={data}
      onClose={onClose}
      destroyOnClose
    >
      <div className="flex flex-col items-center gap-5">
        <div
          className="h-20 w-20 bg-secondary text-white rounded-full flex items-center justify-around text-2xl font-medium"
        >
          {initials}
        </div>
        
        <span className="text-secondary text-2xl font-medium">
          {data?.FirstName} {data?.LastName}
        </span>

        {data?.PhoneNumber && (
          <Spin spinning={isFetchingChatData} wrapperClassName="w-2/3">
            <div className="flex justify-between">
              <Tooltip
                title={Onboard
                  ? `Sent ${convertDateTime(Onboard.CreatedAt).toCompanyTimezone().replace(", ", " at ")}`
                  : "Onboard form not sent"
                }
              >
                <Popconfirm
                  title="Send Onboard Form"
                  description={() => (
                    <>
                      <div className="mb-2">
                        User(s) will recieve a message with the Onboardform.
                        <br/>
                        Responses will be reflected in their contact card
                      </div>
                      <br/>
                      <Checkbox
                        checked={!onBoardMessage}
                        onChange={() => sendOnboardMessage(!onBoardMessage)}
                      >
                        Send onboard form only with no onboard message
                      </Checkbox>
                    </>
                  )}
                  showCancel={false}
                  okType="default"
                  okText="Send Now"
                  placement="left"
                  onConfirm={() => sendOnBoardForm({Ids: [data.Id], SentOnboardForm: !onBoardMessage ? 0 : 1})}
                >
                  <div className="flex flex-col items-center cursor-pointer">
                    <img
                      src={Onboard ? onBoardIconGreen : OnBoardIcon}
                      className="img-fluid w-10"
                      alt="img"
                    />
                    <span>Onboard Form</span>
                  </div>
                </Popconfirm>
              </Tooltip>
                <Tooltip
                  title={ContactCard
                    ? `Sent ${convertDateTime(ContactCard.CreatedAt).toCompanyTimezone().replace(", ", " at ")}`
                    : "Contact not sent"
                  }
                >
                <Popconfirm
                  title="Resend Contact Card"
                  description="The subscriber will receive a message with your contact card. Are you sure you want to continue?"
                  showCancel={false}
                  okType="default"
                  okText="Resend Now"
                  placement="left"
                  onConfirm={() => sendContactCard({Ids: [data.Id]})}
                >
                  <div className="flex flex-col items-center cursor-pointer">
                    <img
                      src={ContactCard ? contactCardIconGreen : SendContactCardIcon }
                      className="img-fluid w-10"
                      alt="img"
                    />
                    <span>Contact Card</span>
                  </div>
                </Popconfirm>
                </Tooltip>
            </div>
          </Spin>
        )}

        <Divider />

        <div className="w-full">
          <label className="text-md text-secondary">Phone Number</label>
          <Input prefix={<PhoneOutlined />} value={data?.PhoneNumber} readOnly />
        </div>

        <EditableInput
          className="w-full"
          label="Email"
          prefix={<MailOutlined />}
          value={data?.Email}
          onSave={updateContactField("Email")}
          error={error === "Email"}
          onReset={resetError("Email")}
        />

        <EditableInput
          className="w-full"
          placeholder="Select Gender"
          label="Gender"
          type="select"
          error={error === "Gender"}
          onSave={updateContactField("Gender")}
          onReset={resetError("Gender")}
          extraInputProps={{
            allowClear: true
          }}
          options={[{
            value: 1,
            label: 'Male'
          }, {
            value: 2,
            label: 'Female'
          }]}
          value={contactMeta?.Gender}
          prefix={<UserOutlined />}
        />

        <EditableInput
          className="w-full"
          label="Date Of Birth"
          type="date"
          error={error === "BirthdayDate"}
          onSave={updateContactField("BirthdayDate")}
          onReset={resetError("BirthdayDate")}
          value={
            moment(contactMeta?.BirthdayDate || null).isValid()
              ? moment(contactMeta?.BirthdayDate)
              : ""
          }
        />
      
        <EditableInput
          className="w-full"
          label="Member #"
          placeholder="#Membership (if you have one)"
          prefix={<NumberOutlined />}
          value={data?.MemberId}
          error={error === "MemberId"}
          onSave={updateContactField("MemberId")}
          onReset={resetError("MemberId")}
        />

        <Divider />
      
        <EditableInput
          className="w-full"
          label="Notes"
          placeholder="Notes"
          type="textarea"
          extraInputProps={{
            style: {
              height: 80,
              resize: 'none'
            }
          }}
          value={contactMeta?.Note}
          direction="vertical"
          error={error === "Note"}
          onSave={updateContactField("Note")}
          onReset={resetError("Note")}
        />

        <EditableInput
          className="w-full"
          placeholder="Segments"
          label="Segments"
          type="select"
          extraInputProps={{
            mode: "multiple"
          }}
          options={segments.map(({Title: label, Id: value}) => ({value, label}))}
          value={data?.Segments.map(({Id}) => Id)}
          direction="vertical"
          error={error === "SegmentIds"}
          onSave={updateContactField("SegmentIds")}
          onReset={resetError("SegmentIds")}
        />
      </div>
    </Drawer>
  )
};

export default ContactDetails;
