import { PAGINATION_LIMIT } from "../../config/constants";
import {
    CHANGE_CONTACT_MODAL_STATE,
    CONTACT_LIST_REQUEST,
    CONTACT_LIST_SUCCESS,
    CONTACT_LIST_FAIL,
    ADD_CONTACT_SUCCESS,
    UPDATE_CONTACT,
    SEGMENTS_REQUEST,
    SEGMENTS_SUCCESS,
    SEGMENTS_FAIL,
    DELETE_CONTACT,
    CONTACT_ADDED,
    SET_UPLOAD_LIST,
} from "../actions";

const initialState = {
    showAddContactModal: false,
    allContacts: {
        fetching: false,
        list: [],
        totalContacts: 0,
        dataAvailable: true
    },
    segments: {
        data: [],
        fetching: false
    },
    contactAdded: false,
    uploads: []
};

export default (state = initialState, action) => {
    // console.log(action.type);
    switch (action.type) {
        case CONTACT_LIST_REQUEST:
            return {
                ...state,
                allContacts: {
                    ...state.allContacts,
                    fetching: true,
                    list: (() => {
                        if (action.Page > 1) {
                            return [...state.allContacts.list];
                        } else {
                            return [];
                        }
                    })()
                }
            };
        case CONTACT_LIST_SUCCESS:
            return {
                ...state,
                allContacts: {
                    ...state.allContacts,
                    fetching: false,
                    totalContacts: action.payload.TotalContacts,
                    dataAvailable: action.payload.Contacts.length >= PAGINATION_LIMIT,
                    list: (() => {
                        if (action.Page && action.Page > 1) {
                            return [...state.allContacts.list, ...action.payload.Contacts];
                        } else {
                            return action.payload.Contacts;
                        }
                    })()
                }
            };
        case CONTACT_LIST_FAIL:
            return {
                ...state,
                allContacts: {
                    ...state.allContacts,
                    fetching: false
                }
            };
        case ADD_CONTACT_SUCCESS:
            return {
                ...state,
                allContacts: {
                    ...state.allContacts,
                    list: [action.payload, ...state.allContacts.list]
                }
            };
        case CHANGE_CONTACT_MODAL_STATE:
            return {
                ...state,
                showAddContactModal: action.payload
            };
        case UPDATE_CONTACT:
            return {
                ...state,
                allContacts: {
                    ...state.allContacts,
                    list: state.allContacts.list.map((contact) => {
                        if (contact.Id === action.payload.Id) {
                            return {
                                ...contact,
                                ...action.payload
                            };
                        }
                        return contact;
                    })
                }
            };
        case DELETE_CONTACT:
            return {
                ...state,
                allContacts: {
                    ...state.allContacts,
                    list: state.allContacts.list.filter((contact) => {
                        return contact.Id !== action.payload;
                    })
                }
            };
        case SEGMENTS_REQUEST:
            return {
                ...state,
                segments: {
                    ...state.segments,
                    fetching: true
                }
            };
        case SEGMENTS_SUCCESS:
            return {
                ...state,
                segments: {
                    ...state.segments,
                    fetching: false,
                    data: action.payload
                }
            };
        case SEGMENTS_FAIL:
            return {
                ...state,
                segments: {
                    ...state.segments,
                    fetching: false
                }
            };
        case CONTACT_ADDED:
            return {
                ...state,
                contactAdded: action.payload
            };
        case SET_UPLOAD_LIST:
            return {
                ...state,
                uploads: [...action.payload]
            };
        default:
            return state;
    }
};
