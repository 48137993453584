import React from "react";
import { createPortal } from "react-dom";
import classNames from "classnames";

const container = document.createElement("DIV");
document.body.prepend(container);

const Overlay = ({ active, className, children }) =>
    createPortal(
        <>
            {active && (
                <div
                    className={classNames(
                        className,
                        "absolute w-screen h-screen inset-0 bg-white z-50"
                    )}
                >
                    {children}
                </div>
            )}
        </>,
        container
    );

export default Overlay;
