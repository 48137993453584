import { store } from "../redux/store";
import { AUTH_FAIL } from "../redux/actions";
import { errorToaster } from "../utils/loggerUtils";
import { isSSOAuthenticated } from "../utils/ssoUtils";

export const handleFetchError = async (res) => {
    if (res.status >= 400 && res.status < 600) {
        if ((store.getState().auth.isAuthenticated || isSSOAuthenticated()) && res.status === 401) {
            errorToaster("Your Session has been Expired!");
            localStorage.clear();
            store.dispatch({ type: AUTH_FAIL });
            return;
        }
        let exp = {
            Message: (await res.json()).Message,
            Status: res.status
        };
        throw exp;
    }
    return res.json();
};
