import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchUtil } from "../../../utils/fetchUtils";
import { getToken } from "../../../utils/tokenUtils";
import Config from "../../../config";

import { get, isFunction, trim } from "lodash";

const getDesign = async (id) => {
    const token = getToken();
    return await fetchUtil({
        url: `/email/design/${id}`,
        token
    });
};

export const useGetDesign = (id, options) => {
    const { data = [] } = useQuery({
        queryKey: ["design", id],
        queryFn: () => getDesign(id),
        ...options,
        enabled: !!id
    });

    return data;
};

const getDesigns = async () => {
    const token = getToken();
    return await fetchUtil({
        url: `/email/design`,
        token
    });
};

export const useGetDesigns = (options) => {
    const { data = [], ...rest } = useQuery({
        queryKey: ["designs"],
        queryFn: getDesigns,
        ...options
    });

    return { data, ...rest };
};

const upsertDesign = async (template) => {
    const token = getToken();
    return await fetchUtil({
        method: "PUT",
        url: `/email/design`,
        token,
        body: JSON.stringify(template)
    });
};

export const useUpsertTemplate = (options) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
        mutationFn: upsertDesign,
        ...options,
        onSuccess(data) {
            options.onSuccess(data);
            queryClient.invalidateQueries("designs");
        }
    });

    return mutate;
};

const getPublicDesigns = async () => {
    const token = getToken();
    return await fetchUtil({
        url: `/email/design/public`,
        token
    });
};

export const useGetPublicDesigns = () => {
    const { data = [] } = useQuery({
        queryKey: ["publicDesigns"],
        queryFn: getPublicDesigns,
    });

    return data;
};
;

const getInitialDesigns = async () => {
    const token = getToken();
    return await fetchUtil({
        url: `/email/design/initial`,
        token
    });
};

export const useGenerateInitialDesigns = () => {
    const queryClient = useQueryClient();

    return async (cb) => {
        const token = getToken();
        const data = await getInitialDesigns();
        const company = await fetchUtil({url: '/get/user/company', token});

        company.Logo = `${Config.env().BASE_IMG_PATH}/${company.Logo?.Path}`;

        await Promise.all(
            data.map(async ({ name, id }) => {
                const container = window.document.createElement("DIV");
                container.id = id;
                container.style.display = "none";
                window.document.body.appendChild(container);
                const editor = window.unlayer.createEditor({
                    id: `${id}`,
                    projectId: 143749,
                    templateId: id,
                    designTags: company,
                });

                await new Promise(resolve => {
                    editor.addEventListener("design:loaded", resolve);
                });

                const generatedHtml = await new Promise(resolve => editor.exportHtml(resolve));

                await upsertDesign({
                    html_content: generatedHtml.html,
                    design_declaration: generatedHtml.design,
                    name: trim(name.replace(/\[\[(\w+\.?)*\]\]/g, (_, key) => get(company, key, "")))
                })

                container.remove();
            })
        );

        queryClient.invalidateQueries("designs");

        if(isFunction(cb)) cb();
    };
};


const deleteTemplate = async (id) => {
    const token = getToken();
    return await fetchUtil({
        method: "DELETE",
        url: `/email/design/${id}`,
        token
    });
};

export const useDeleteTemplate = (options = {}) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
        mutationFn: deleteTemplate,
        ...options,
        onSuccess(data) {
            if(isFunction(options.onSuccess)) options.onSuccess(data);
            queryClient.invalidateQueries("designs");
        }
    });

    return mutate;
};
