import React, { useRef, useState, useCallback } from "react";
import noop from "lodash/noop";
import Config from "../../../config";

import UnlayerEmailEditor from "react-email-editor";
import TemplateSelection from "./templateSelection";
import { Button, Dropdown, Popconfirm } from "antd";
import { useGetDesign, useUpsertTemplate } from "../../../hooks/api/email/designs";
import { LeftOutlined } from "@ant-design/icons";
import EditableHeader from "../../molecules/EditableHeader";
import { toast } from "react-toastify";
import { useGetCompany } from "../../../hooks/api/company";
import { isEmpty } from "lodash";

const EmailEditor = ({ onClose = noop, onSave = noop, onNameChange = noop, template }) => {
    const editorRef = useRef(null);
    const { data: company}  = useGetCompany();
    const [templatesLibraryActive, showTemplatesLibrary] = useState(!template);
    const [templateId, setTemplateId] = useState(template);
    const [templateName, setTemplateName] = useState("New Template");

    const upsertTemplate = useUpsertTemplate({
        onSuccess: async ({ id }) => {
            const { html } = await exportTemplate();
            setTemplateId(id);
            onSave({ content: html, id });
        },
        onError() {
            toast("Something Went Wrong", { containerId: "error" });
        }
    });

    useGetDesign(templateId, {
        onSuccess(data) {
            editorRef.current?.editor?.loadDesign(data.design_declaration);
            setTemplateName(data.name);
        },
        refetchOnWindowFocus: false
    });

    const onTemplateChange = ({ id, name, isPublic }) => {
        if (isPublic) {
            editorRef.current?.editor.loadTemplate(id);
        }

        setTemplateName(name);
        onNameChange(name);
        setTemplateId(isPublic ? null : id);
        showTemplatesLibrary(false);

        if (!id) {
            editorRef.current?.editor?.loadBlank();
        }
    };

    const exportTemplate = useCallback(() => {
        return new Promise((resolve) => {
            editorRef.current?.editor.exportHtml(resolve);
        });
    }, [editorRef]);

    const onSaveTemplate = useCallback(
        async (id) => {
            const { design, html } = await exportTemplate();

            upsertTemplate({
                id,
                html_content: html,
                design_declaration: design,
                name: templateName
            });
        },
        [upsertTemplate, exportTemplate, templateName]
    );

    return (
        <div className="flex flex-col h-full">
            <div className="flex justify-between h-20 shadow z-50 px-10 items-center shrink-0">
                {templatesLibraryActive
                    ? (
                        <Button
                            type="text"
                            className="text-black flex items-center"
                            onClick={() => showTemplatesLibrary(false)}
                        >
                            <LeftOutlined />
                            Back
                        </Button>
                    )
                    : (
                        
                        <Popconfirm
                            title="Unsaved changes will be lost!"
                            okType="default"
                            okText="OK"
                            showCancel={false}
                            onConfirm={onClose}
                        >
                            <Button
                                type="text"
                                className="text-black flex items-center"
                            >
                                <LeftOutlined />
                                Back to Campaign
                            </Button>
                        </Popconfirm>
                    )}
                {!templatesLibraryActive && (
                    <>
                        <EditableHeader
                            value={templateName}
                            onChange={(name) => {
                                onNameChange(name);
                                setTemplateName(name);
                            }}
                        />
                        <div className="flex gap-4">
                            <Button onClick={() => editorRef.current?.editor.showPreview()}>
                                Preview
                            </Button>
                            <Dropdown
                                menu={{
                                    items: [
                                        {
                                            key: 1,
                                            label: (
                                                <span onClick={() => onSaveTemplate(null)}>
                                                    Save as New Template
                                                </span>
                                            )
                                        },
                                        {
                                            key: 2,
                                            label: (
                                                <span onClick={() => showTemplatesLibrary(true)}>
                                                    Switch Templates
                                                </span>
                                            )
                                        }
                                    ]
                                }}
                            >
                                <Button>Template</Button>
                            </Dropdown>
                            <Button
                                onClick={() => onSaveTemplate(templateId)}
                                className="bg-main text-white"
                            >
                                Save and Exit
                            </Button>
                        </div>
                    </>
                )}
            </div>
            {templatesLibraryActive && <TemplateSelection onChange={onTemplateChange} />}
            <div className={templatesLibraryActive ? "hidden" : "h-full shrink-1"}>
                {!isEmpty(company) && (
                    <UnlayerEmailEditor
                        ref={editorRef}
                        style={{
                            height: "90vh"
                        }}
                        projectId={143749}
                        options={{
                            user: {
                                id: company?.Id,
                            },
                            customCSS: [
                                `
                                    .blockbuilder-preview {
                                    background: #fff
                                    }
                                `
                            ],
                            appearance: {
                                panels: {
                                    tools: {
                                        dock: "left"
                                    }
                                }
                            },
                            features: {
                                preview: false,
                            },
                            mergeTags: {
                                first_name: {
                                name: "First Name",
                                value: "{{first_name}}",
                                sample: "John"
                                },
                                last_name: {
                                name: "Last Name",
                                value: "{{last_name}}",
                                sample: "Doe"
                                }
                            },
                            designTags: {
                                ...company,
                                Logo: `${Config.env().BASE_IMG_PATH}/${company?.Logo?.Path}`
                            }
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default EmailEditor;
