import React from "react";
import { Switch, Route } from "react-router-dom";

import "../../App.css";
import { ChatFilters } from "../../config/constants";
import { InboxMessageUnselected, InboxChatBox, InboxContactsList } from "../../components/Inbox";

function InboxAllMessages(props) {
    const handleChatSelectClick = (id) => {
        props.history.replace(`/dashboard/inbox/all-messages/${id}`);
    };

    return (
        <div className="inbox-container">
            <Switch>
                <Route
                    path="/dashboard/inbox/all-messages/:id?"
                    render={(props) => (
                        <InboxContactsList
                            {...props}
                            onConversationClick={handleChatSelectClick}
                            filterType={ChatFilters.All}
                        />
                    )}
                />
            </Switch>
            <div className=" article-middle-chat inbox-chatbox-container">
                <Switch>
                    <Route
                        path="/dashboard/inbox/all-messages/:id"
                        exact={true}
                        component={InboxChatBox}
                    />
                    <Route component={InboxMessageUnselected} />
                </Switch>
            </div>
        </div>
    );
}

export default InboxAllMessages;
