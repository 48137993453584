import moment from "moment-timezone";

import { store } from "../redux/store";
import { CompanyTimeZones } from "../config/constants";
import { isSSOAuthenticated } from "./ssoUtils";

export const dateTimeFormat = {
    appDateTimeFormat: "MM/DD/YYYY, hh:mm A",
    appDateFormat: "MM/DD/YYYY",
    apiDateTimeFormat: "YYYY-MM-DD HH:mm:ss",
    formattedBirthday: "MM-DD-YYYY"
};

export const getTimeZoneDatabaseName = () => {
    const user = isSSOAuthenticated() ? store.getState().ssoAuth.user : store.getState().auth.user;
    const timeZone = user.Company ? user.Company.TimeZone : null;
    return timeZone ? CompanyTimeZones[timeZone] : null;
};

export const convertDateTime = (unixTimeStamp, dateOnly = false) => {
    const timeZoneDatabaseName = getTimeZoneDatabaseName();

    const getTimeZoneMoment = () => {
        return timeZoneDatabaseName
            ? moment
                  .utc(unixTimeStamp)
                  .local()
                  .tz(timeZoneDatabaseName)
            : moment.utc().local();
    };

    const toCompanyTimezone = (optionalFormat = null) => {
        let format =
            optionalFormat ||
            (dateOnly ? dateTimeFormat.appDateFormat : dateTimeFormat.appDateTimeFormat);
        const convertedDateTime = getTimeZoneMoment().format(format);
        return convertedDateTime;
    };

    const toGmt = () => {
        return moment(unixTimeStamp)
            .utc()
            .format(dateTimeFormat.apiDateTimeFormat);
    };

    return {
        toCompanyTimezone,
        toGmt,
        getTimeZoneMoment
    };
};

export const companyTime = {
    getHour() {
        return moment
            .utc()
            .local()
            .tz(getTimeZoneDatabaseName())
            .hour();
    },
    getCurrentDate() {
        return moment
            .utc()
            .local()
            .tz(getTimeZoneDatabaseName());
    }
};

export const isValidDate = (dateValue, format) => {
    return moment(dateValue, format, true).isValid();
};
