import React from "react";
import { connect } from "react-redux";
class ContactAlertModal extends React.Component {
    constructor() {
        super();
        this.state = {
            isModalOpen: false
        };
        this.AlertReference = React.createRef();
    }
    componentDidMount() {
        this.bindDocumentListener();
    }
    bindDocumentListener = () => {
        if (document.getElementsByClassName("ReactModalPortal")[0]) {
            document
                .getElementsByClassName("ReactModalPortal")[0]
                .addEventListener("click", this.documentClick, false);
        } else {
            document.getElementById("root").addEventListener("click", this.documentClick, false);
        }
    };

    unbindDocumentListener = () => {
        if (document.getElementsByClassName("ReactModalPortal")[0]) {
            document
                .getElementsByClassName("ReactModalPortal")[0]
                .removeEventListener("click", this.documentClick, false);
        } else {
            document.getElementById("root").removeEventListener("click", this.documentClick, false);
        }
    };
    documentClick = (e) => {
        if (
            this.AlertReference &&
            this.AlertReference.current &&
            !this.AlertReference.current.contains(e.target)
        ) {
            this.props.handleClose();
        }
    };
    render() {
        const { color, handleClose, icon, content, closeButtonContent } = this.props;
        let alertButtonContent = "close";
        if (closeButtonContent) {
            alertButtonContent = closeButtonContent;
        }
        return (
            <div className="contact-alert-modal">
                <div className={`contact-alert-modal-content ${color}`} ref={this.AlertReference}>
                    <img style={{ width: "25px" }} src={icon} alt="img"></img>
                    <div className="importcontactcsv-text-content">
                        <p>{content}</p>
                    </div>
                    <div className="importcontactcsv-btn">
                        <button
                            onClick={handleClose}
                            className="alertclosebutton focus-outline-remove cursor-pointer"
                        >
                            {alertButtonContent}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
export default connect(null, null)(ContactAlertModal);
