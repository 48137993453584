import React, { useMemo, useState } from "react";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useDeleteTemplate, useGenerateInitialDesigns, useGetDesigns, useGetPublicDesigns } from "../../../hooks/api/email/designs";

import noop from "lodash/noop";
import capitalize from "lodash/capitalize";

import { isEmpty } from "lodash";
import { Spin, Card, Popconfirm, Menu} from "antd";

const Templates = ({templates, isPublic, onChange}) => {
  const deleteTemplate = useDeleteTemplate();
  return (
    <div className="flex flex-wrap gap-10 mt-10">
      {templates?.map(({thumbnail_url, name, id}) => (
        <Card
          key={id || 1}
          title={name}
          extra={
              isPublic
                ? null
                : (
                  <Popconfirm
                    title="This action is irreversible!"
                    okType="default"
                    okText="Delete anyway"
                    showCancel={false}
                    onConfirm={(e) => e.stopPropagation() || deleteTemplate(id)}
                    onClick={e => e.stopPropagation()}
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                )
          }
          onClick={() => onChange({id, name, isPublic})}
          size="small"
        >
          {thumbnail_url && <img alt="preview" src={thumbnail_url} className="m-auto h-60" />}
          {isPublic && <img alt="preview" src={`https://api.unlayer.com/v2/editor/143749/templates/${id}/thumbnail`} className="m-auto h-60 w-60 object-cover" />}
          { !id && (
              <div className="h-full m-auto flex content-center justify-center">
                <PlusCircleOutlined className="text-3xl text-[#344c60]" />
              </div>
            )
          }
        </Card>
      ))}
    </div>
  )
}

const TemplateSelection = ({ onChange = noop }) => {
    const {data: designs} = useGetDesigns({
      onSuccess(data) {
        if(isEmpty(data)) {
          generateInitial(() => setDesignsGeneration(false));
          setDesignsGeneration(true);
        }
      }
    });
    const {tags = [], data: publicDesigns = {}} = useGetPublicDesigns();
    const generateInitial = useGenerateInitialDesigns();
    const [activeCategory, setActiveCategory] = useState("private");
    const [isDesignsGeneration, setDesignsGeneration] = useState(false);

    const items = useMemo(() => ([
      {
        label: "Templates Library",
        key: "private"
      },
      ...tags.map(tag => ({
        key: tag,
        label: tag.split('_').map(capitalize).join(' ')
      }))
    ]), [tags]);

    return (
      <div className="px-10 py-5">
        <Menu activeKey={activeCategory} onSelect={({ key }) => setActiveCategory(key)} mode="horizontal" items={items} />
        <Spin spinning={isDesignsGeneration} tip="Templates Generation...">
          <Templates
            templates={publicDesigns[activeCategory] || designs}
            isPublic={activeCategory !== "private"}
            onChange={onChange} />
        </Spin>
      </div>
    );
};

export default TemplateSelection;
