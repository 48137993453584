import React from "react";
import classNames from "classnames";

const Card = ({ title, prefix, postfix, children, ...props }) => (
    <div
        {...props}
        className={classNames(
            "border rounded p-4 gap-4 flex flex-col bg-gray-100",
            props?.className
        )}
    >
        <div className="flex w-full items-center gap-4">
            {prefix}
            <h2 className="font-bold text-lg text-[#344c60] font-['Montserrat']">{title}</h2>
            {postfix}
        </div>
        {children}
    </div>
);

export default Card;
