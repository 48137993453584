import {
    ME_REQUEST,
    ME_SUCCESS,
    ME_FAIL,
    CHANGE_ADMIN_VIEW,
    SET_SHOW_NOTIFICATION_PERMISSION,
    SET_COMPANY_INVITED_MESSAGE,
    SET_SOUND_STATUS,
    BRNAD_REGISTER_FORM
} from "../actions";
import AuthenticationTypes from "../../config/constants/AuthenticationTypes";
import BrandStatus from "../../config/constants/BrandStatus";
import PhoneTypes from "../../config/constants/PhoneTypes";
const initialState = {
    fetching: false,
    error: "",
    code: "",
    isAuthenticated: false,
    isAdmin: false,
    redirectUrl: null,
    showNotificationPermission: true,
    isBrandVaildToOpen: BrandStatus.NOT_SET, // -1 not Set , 2 Pending , 3 Approve , 4 Cancle, 1 not Avaliable
    user: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case BRNAD_REGISTER_FORM: {
            return {
                ...state,
                isBrandVaildToOpen: action.payload
            };
        }
        case ME_REQUEST:
            return {
                ...state,
                error: "",
                fetching: true
            };
        case SET_SHOW_NOTIFICATION_PERMISSION:
            return {
                ...state,
                showNotificationPermission: !state.showNotificationPermission
            };
        case ME_SUCCESS:
            let isBrandVaildToOpen = state.isBrandVaildToOpen;

            if (isBrandVaildToOpen === BrandStatus.NOT_SET) {
                if (PhoneTypes.TollFree !== action.payload.User.Company.PhoneType) {
                    //Toll-free number are not suppose to be register in Brand and Campains
                    isBrandVaildToOpen = action.payload.User.Company.BrandStatus;
                } else {
                    isBrandVaildToOpen = BrandStatus.CANCEL;
                }
            }
            if (action.payload.User.Type !== AuthenticationTypes.SUB_ADMIN) {
                isBrandVaildToOpen = BrandStatus.CANCEL;
            }

            return {
                ...state,
                isBrandVaildToOpen: isBrandVaildToOpen,
                error: "",
                fetching: false,
                isAdmin: state.isAdmin,
                redirectUrl: state.redirectUrl,
                isAuthenticated: true,
                user: {
                    ...state.user,
                    ...action.payload.User,
                    RC_Token: action.token
                }
            };
        case ME_FAIL:
            return {
                ...state,
                fetching: false,
                isAuthenticated: false,
                error: "Not Authorized"
            };
        case CHANGE_ADMIN_VIEW:
            return {
                ...state,
                isAdmin: action.payload,
                redirectUrl: action.url
            };
        case SET_COMPANY_INVITED_MESSAGE:
            return {
                ...state,
                user: {
                    ...state.user,
                    Company: {
                        ...state.user.Company,
                        ...action.payload
                    }
                }
            };
        case SET_SOUND_STATUS:
            return {
                ...state,
                user: {
                    ...state.user,
                    Company: {
                        ...state.user.Company,
                        SoundStatus: action.payload
                    }
                }
            };
        default:
            return state;
    }
};
