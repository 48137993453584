import React, { useMemo, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import moment from "moment";
import classnames from "classnames";

import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import pick from "lodash/pick";
import some from "lodash/some";
import map from "lodash/map";

import { Button, Segmented, Switch, Input } from "antd";
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import Card from "../../components/atoms/Cards";
import Select from "../../components/atoms/Input/Select";
import TextInput from "../../components/atoms/Input/Text";
import ActionCard from "../../components/atoms/Cards/EditCard";
import EmailEditor from "../../components/organisms/EmailEditor";

import useFormState from "../../hooks/formState";
import { useUpsertCampaign, useSendTestEmail } from "../../hooks/api/email/email";
import { useGetSenders } from "../../hooks/api/email/senders";
import { useGetContacts, useGetContactsTotalCount, useGetSegments } from "../../hooks/api/contacts";
import { useGetCampaign, usePrefetchCampaigns } from "../../hooks/api/campaigns";
import NewSenderForm from "../../components/organisms/NewSenderForm";
import EditableHeader from "../../components/molecules/EditableHeader";
import Overlay from "../../components/atoms/Overlay";
import DatePicker from "react-datepicker";

import "./fix.css";

const INITIAL_STATE = {
    name: `Campaign - ${moment().format("MM/DD/YY")}`,
    send_at: null,
    template_name: null,
    template_id: null,
    testEmails: [],
    from: null,
    segments: [],
    contacts: [],
    subject: null,
    content: null,
    fallbackContent: null,
    fallbackEnabled: false
};

const EmailCampaign = () => {
    const history = useHistory();
    const { id } = useParams();
    const [isEditorActive, openEditor] = useState(false);
    const [sendOption, setSendOption] = useState("Send it Now");
    const [initialEmailState, setInitialEmailState] = useState(INITIAL_STATE)

    const [emailData, { set: setEmailData, reinit: reinitEmailData }] = useFormState(initialEmailState);
    const [tempData, {
        set: setTempData,
        reset: resetTempData,
        reinit: reinitTempData
    }] = useFormState(emailData);

    const senders = useGetSenders();
    const segments = useGetSegments();
    const [{Contacts: contacts = []}] = useGetContacts({ByEmail: true, ByPhoneNumber: false});
    const prefetch = usePrefetchCampaigns();
    const { sendTestEmail, isLoading: isTestSending } = useSendTestEmail(emailData);
    const { sendEmail, isLoading: isCampaignSending } = useUpsertCampaign(id, emailData, {
        onSuccess() {
            history.push('/dashboard/reports/campaigns');
            toast(`Campaign ${emailData.send_at ? 'Scheduled' : 'Sent'} Successfully`, { containerId: "success" })
        }
    });

    useGetCampaign(id, {
        onSuccess: (campaign) => {
            setSendOption("Schedule");
            setInitialEmailState({
                name: campaign.Campaign.Title,
                send_at: campaign.Campaign.SentOn,
                from: campaign.Campaign.Email.from,
                subject: campaign.Campaign.Email.subject,
                content: campaign.Campaign.Email.content,
                template_id: campaign.Campaign.Email.template_id,
                contacts: campaign.Campaign.Meta.ContactIds ?? [],
                template_name: campaign.Campaign.Email.template_name,
                fallbackEnabled: campaign.Campaign.AsEmailFallback,
                segments: campaign.Campaign.Meta.AllContacts
                    ? [-1]
                    : campaign.Campaign.Meta.SegmentIds,
                ...(
                    campaign.Campaign.AsEmailFallback
                        ? {fallbackContent: campaign.Campaign.Content}
                        : {}
                )
            });
        }
    });

    const contactsTotalCount = useGetContactsTotalCount();
    const totalSelectedContactsCount = useMemo(() => {
        if (emailData.segments.includes(-1))
            return {
                total: contactsTotalCount.TotalActiveContacts,
                email: contactsTotalCount.TotalEmailContacts
            };

        return contactsTotalCount?.Segments?.filter(({ segment }) =>
            emailData.segments.includes(segment.Id)
        ).reduce(
            (count, { TotalActiveContacts, TotalEmailContacts }) => {
                count.total += TotalActiveContacts;
                count.email += TotalEmailContacts;

                return count;
            },
            {
                total: 0,
                email: 0
            }
        );
    }, [contactsTotalCount, emailData]);

    const toOptions = useMemo(() => {
        return [
            { type: 'ALL', value: -1, label: "All" },
            ...(segments.map(({ Id: value, Title: label }) => ({ type: 'SEG', label, value })) || []),
            ...(contacts.filter(({ Email }) => Email).map(({ Id, Email, Name }) => ({
                type: 'CONT',
                label: `${Name} <${Email}>`,
                value: Id
            })) || [])
        ];
    }, [segments, contacts]);

    const prefetchAndSend = () => {
        prefetch();
        sendEmail();
    }

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
    
        return currentDate.getTime() < selectedDate.getTime();
    };

    const to = useMemo(
        () => tempData.segments.concat(tempData.contacts),
        [tempData.segments, tempData.contacts]
    )
    const toLabels = useMemo(() => {
            if (emailData.segments.includes(-1)) return "All Contacts";

            const selectedSegments = segments
                .filter(({ Id }) => emailData.segments.includes(Id))
                .map(({ Title }) => Title);
            
            const selectedContacts = contacts
                .filter(({ Id }) => emailData.contacts.includes(Id))
                .map(({ Name }) => Name);
            
            return [...selectedSegments, ...selectedContacts].join(", ")
        },
        [segments, contacts, emailData.segments, emailData.contacts]
    );
    
    const canBeSent = useMemo(() => {
        if (some(pick(emailData, ['subject','from','content']), isEmpty))
            return false;

        return sendOption === "Send Test"
            ? !isEmpty(emailData.testEmails)
            : !isEmpty([...emailData.segments, ...emailData.contacts])
    }
    , [emailData, sendOption])

    useEffect(reinitEmailData, [initialEmailState, reinitEmailData]);
    useEffect(reinitTempData, [initialEmailState, reinitTempData]);

    return (
        <div className={
            classnames(
                "flex flex-col pt-20 p-8 scroll-styling gap-6 max-w-screen-2xl mx-auto",
                {
                    "max-h-0 overflow-hidden": isEditorActive
                },
            )}
        >
            <Overlay className="bg-white" active={isEditorActive}>
                <EmailEditor
                    onClose={() => openEditor(false)}
                    onNameChange={setTempData("template_name")}
                    onSave={({ content, id }) => {
                        setEmailData("content")(content);
                        setEmailData("template_name")(tempData.template_name);
                        setEmailData("template_id")(id);
                        openEditor(false);
                    }}
                    template={emailData.template_id}
                />
            </Overlay>
            <EditableHeader value={emailData.name} onChange={setEmailData("name")} />
            <div className="flex gap-6">
                <div className="flex flex-col gap-6 w-3/4">
                    <ActionCard
                        title="Subject"
                        data={emailData.subject}
                        onSave={() => {
                            setEmailData("subject")(tempData.subject);
                            setEmailData("fallbackContent")(
                                `${tempData.subject}\nView full email at [[link]]`
                            );
                        }}
                        onCancel={resetTempData("subject")}
                        isCompleted={!isEmpty(emailData.subject)}
                    >
                        <TextInput
                            placeholder="Subject"
                            value={tempData.subject}
                            onChange={(e) => setTempData("subject")(e.target.value)}
                        />
                    </ActionCard>
                    <ActionCard
                        title="To"
                        data={
                            !isEmpty(to) && [
                                {
                                    label: "Send to:",
                                    value: toLabels,
                                    props: {
                                        className: "w-full"
                                    }
                                },
                                {
                                    label: "Total Recipients",
                                    value: totalSelectedContactsCount?.total + emailData.contacts.length
                                },
                                {
                                    label: "Total unique email recipients",
                                    value: totalSelectedContactsCount?.email + emailData.contacts.length
                                }
                            ]
                        }
                        onSave={() => {
                            setEmailData("segments")(tempData.segments)
                            setEmailData("contacts")(tempData.contacts)
                        }}
                        onCancel={() => {
                            resetTempData("segments")()
                            resetTempData("contacts")()
                        }}
                        isCompleted={!isEmpty(emailData.segments) || !isEmpty(emailData.contacts)}
                    >
                        <Select
                            multiple
                            placeholder="Enter recipients list"
                            value={to}
                            onChange={(_, selected) => {
                                if(some(selected, {type: "ALL"}) && !tempData.segments.includes(-1)) {
                                    setTempData("segments")([-1]);
                                    setTempData("contacts")([]);
                                } else {
                                    setTempData("segments")(map(filter(selected, {type: "SEG"}), "value"));
                                    setTempData("contacts")(map(filter(selected, {type: "CONT"}), "value"));
                                }
                            }}
                            options={toOptions}
                        />
                    </ActionCard>
                    <ActionCard
                        title="From"
                        data={emailData.from}
                        onSave={() => setEmailData("from")(tempData.from)}
                        onCancel={resetTempData("from")}
                        isCompleted={!isEmpty(emailData.from)}
                    >
                        <Select
                            placeholder="<from@company.com>"
                            options={senders.map(({ email, name }) => ({
                                value: email,
                                label: `${name} <${email}>`
                            }))}
                            value={tempData.from}
                            onChange={setTempData("from")}
                            dropdownFooter={<NewSenderForm />}
                        />
                    </ActionCard>
                    <ActionCard
                        title="Send"
                        isCompleted={canBeSent}
                        hideActions
                    >
                        <div className="flex flex-col gap-4 w-1/2">
                            <div className="flex flex-col gap-2">
                                <div className="flex justify-between">
                                    <div>
                                        <span className="font-bold text-[#344c60] font-['Montserrat']">
                                            Enable SMS Fallback
                                        </span>
                                        <p className="font-medium text-xs">For contacts with phone numbers only</p>
                                    </div>
                                    <div className="flex gap-2">
                                        <span className="text-xs">
                                            Fallback is {emailData.fallbackEnabled ? 'on' : 'off'}
                                        </span>
                                        <Switch
                                            size="small"
                                            className={!emailData.fallbackEnabled ? "bg-[#344c60]" : ""}
                                            checked={emailData.fallbackEnabled}
                                            onChange={setEmailData("fallbackEnabled")}
                                        />
                                    </div>
                                </div>
                                <Input.TextArea
                                    value={emailData.fallbackContent}
                                    onChange={(e) =>
                                        setEmailData("fallbackContent")(e.target.value)
                                    }
                                    disabled={!emailData.fallbackEnabled}
                                />
                            </div>
                            <Segmented
                                size="large"
                                options={["Send Test", "Send it Now", "Schedule"]}
                                value={sendOption}
                                onChange={(option) => {
                                    if (option === "Send it Now") {
                                        setEmailData("send_at")(null);
                                    }
                                    setSendOption(option);
                                }}
                                className="border"
                                block
                            />

                            {sendOption === "Send Test" && (
                                <Select
                                    className="w-full"
                                    value={emailData.testEmails}
                                    onChange={setEmailData("testEmails")}
                                    placeholder="Enter Addresses to Send Test Email To"
                                    options={senders.map(({ email }) => ({
                                        label: email,
                                        value: email
                                    }))}
                                    placement="topLeft"
                                    multiple
                                    freeInput
                                />
                            )}
                            {sendOption === "Schedule" && (
                                <DatePicker
                                    className="h-12 rounded border px-2 w-full"
                                    selected={emailData.send_at || new Date()}
                                    onChange={(val) => filterPassedTime(val) && setEmailData("send_at")(val)}
                                    timeInputLabel="Time:"
                                    minDate={new Date()}
                                    minTime={new Date()}
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    showTimeInput
                                />
                            )}

                            <Button
                                className="bg-main text-white h-12 text-md font-medium"
                                onClick={sendOption === "Send Test" ? sendTestEmail : prefetchAndSend}
                                disabled={!canBeSent || isCampaignSending || isTestSending}
                            >
                                {sendOption === "Schedule" ? "Schedule" : "Send"}
                            </Button>
                        </div>
                    </ActionCard>
                </div>
                <div className="w-1/4">
                    <Card
                        title="Content"
                        prefix={
                            isEmpty(emailData.content) ? (
                                <ExclamationCircleOutlined className="text-xl text-gray-400" />
                            ) : (
                                <CheckCircleOutlined className="text-xl text-main" />
                            )
                        }
                        postfix={
                            !isEmpty(emailData.content) && (
                                <Button
                                    type="text"
                                    className="justify-self-end ml-auto text-[#344c60] font-medium"
                                    onClick={() => openEditor(true)}
                                >
                                    Edit
                                </Button>
                            )
                        }
                    >
                        {emailData.content ? (
                            <iframe
                                title="Email Preview"
                                className="h-[500px] overflow-hidden"
                                width={320}
                                height={500}
                                scrolling={"no"}
                                srcDoc={emailData.content}
                            />
                        ) : (
                            <div className="h-[500px] w-3/4 m-auto rounded flex flex-col items-center justify-center gap-10">
                                <Button
                                    onClick={() => openEditor(true)}
                                    className="bg-main text-white h-10"
                                >
                                    Start Design
                                </Button>
                            </div>
                        )}
                        {emailData.template_name && (
                            <span className="font-medium m-auto">{emailData.template_name}</span>
                        )}
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default EmailCampaign;
