import { CHANGE_SOCKET_STATUS } from "../actions";

export default (state = false, action) => {
    switch (action.type) {
        case CHANGE_SOCKET_STATUS:
            return action.payload;
        default:
            return state;
    }
};
