import React from "react";

import Gallery from "../../assets/images/gallery.svg";
import Cross from "../../assets/images/close.svg";
import { Spinner } from "../Loader";
export const ImageBox = ({
    mediaObject = {},
    isImageUploading = false,
    clearFileInput,
    mediaUrl = null,
    isMediaUpdate = false
}) => {
    let mediaObjectName =
        mediaObject && mediaObject.Obj && mediaObject.Obj.name
            ? mediaObject.Obj.name.length > 15
                ? `${mediaObject.Obj.name.substr(1, 10)}...`
                : mediaObject.Obj.name
            : null;
    return (
        <>
            <div
                className={`${
                    mediaObject && Object.keys(mediaObject).length && !isImageUploading
                        ? `image-box-textarea-chat display-block-default`
                        : `image-box-textarea-chat display-none-default`
                }`}
            >
                {
                    <div className="image-box-value image-box-container">
                        <div className="image-left-container">
                            <img
                                src={Gallery}
                                className="img-fluid image-gallery-icon"
                                alt="React Icon 1"
                            />

                            {isImageUploading ? (
                                <Spinner size="lg" color="grey" />
                            ) : (
                                mediaObjectName && mediaObjectName
                            )}
                        </div>
                        <div>
                            <img
                                src={Cross}
                                onClick={clearFileInput}
                                className="img-fluid image-cross-icon"
                                alt="React Icon 1"
                            />
                        </div>
                    </div>
                }
            </div>
            {mediaUrl && !isMediaUpdate ? (
                <div className="image-box-textarea-chat">
                    {
                        <div className="image-box-value">
                            <img
                                src={Gallery}
                                style={{ width: "23px" }}
                                className="img-fluid"
                                alt="React Icon 1"
                            />
                            <span style={{ marginLeft: "10px" }}>
                                {isImageUploading ? (
                                    <Spinner size="lg" color="grey" />
                                ) : mediaUrl != null ? (
                                    mediaUrl.length > 15 ? (
                                        `${mediaUrl.substr(mediaUrl.length - 16, 10)}...`
                                    ) : (
                                        mediaUrl
                                    )
                                ) : (
                                    ``
                                )}
                            </span>

                            <img
                                style={{
                                    width: "10px",
                                    float: "right",
                                    marginRight: "5px"
                                }}
                                src={Cross}
                                onClick={clearFileInput}
                                className="img-fluid"
                                alt="React Icon 1"
                            />
                        </div>
                    }
                </div>
            ) : null}
        </>
    );
};
