import React, { useState } from 'react';
import { NavLink, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { Button, Dropdown, Input } from 'antd';

import Header from '../../../components/atoms/Typography/Header';
import { MailOutlined, MessageOutlined, PlusOutlined } from '@ant-design/icons';
import SentCampaigns from '../components/SentCampaigns';
import ScheduledCampaigns from '../components/ScheduledCampaigns';

const Reports = ({ user }) => {
  const history = useHistory();
  const [search, setSearch] = useState("");

  const handleNewCampaignClick = () => {
    if(!user.CanUseEmailCampaings) {
      history.push("/dashboard/text/sms-campaign");
    }
  }

  const newCampaignOptions = [{
    label: (
      <NavLink to="/dashboard/text/sms-campaign" className='flex gap-2'>
        <MessageOutlined
          style={{
            color: "#1d3152",
            margin: "0 5px"
          }}
        />
        <h3>New SMS Campaign</h3>
      </NavLink>
    ),
    key: 'sms',
  }, {
    label: (
      <NavLink to="/dashboard/email/email-campaign" className='flex gap-2'>
        <MailOutlined
          style={{
            color: "#1d3152",
            margin: "0 5px"
          }}
        />
        <h3>New Email Campaign</h3>
      </NavLink>
    ),
    key: 'email',
  },];

  return (
    <div className='flex flex-col gap-2 px-10 pt-10'>
      <div className='flex gap-2 justify-end'>
        <Header className='text-4xl mr-auto'>Campaigns</Header>
        <div className="w-4">
          <Input.Search
            placeholder="Search Campaigns"
            size="large"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </div>
        <Dropdown menu={{items: newCampaignOptions}} trigger={['click']}>
          <Button
            size='large'
            className='bg-[#3aaf50] text-white'
            onClick={handleNewCampaignClick}
          >
            <div className='flex align-middle gap-2'>
            <PlusOutlined /> New Campaign
            </div>
          </Button>
        </Dropdown>
      </div>
      <ScheduledCampaigns search={search} />
      <SentCampaigns search={search} />
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.auth.user
})

export default connect(mapStateToProps)(Reports);
