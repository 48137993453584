import { EditFilled } from "@ant-design/icons";
import React, { useState } from "react";
import Header from "../atoms/Typography/Header";
import TextInput from "../atoms/Input/Text";
import { Button } from "antd";

const EditableHeader = ({ value, onChange }) => {
    const [isActive, activate] = useState(false);

    return isActive ? (
        <div className="flex gap-4 items-center">
            <TextInput value={value} onChange={(e) => onChange(e.target.value)} />
            <Button
                onClick={() => activate(false)}
                type="text"
                className="font-medium text-[#344c60]"
            >
                Save
            </Button>
        </div>
    ) : (
        <Header>
            {value} <EditFilled onClick={() => activate(true)} />
        </Header>
    );
};

export default EditableHeader;
