import React from "react";
import classNames from "classnames";

const Label = ({ children, className = "" }) => (
  <span
    className={classNames("text-lg text-gray-400 font-medium", className)}
  >
    {children}
  </span>
);

export default Label;

