import React, { useState, useMemo } from "react";
import moment from "moment";
import { Input, Statistic, Table } from "antd";


import Header from "../../../../../components/atoms/Typography/Header";
import { useGetEmailStats } from "../../../../../hooks/api/email/email";


const EmailCampaignDetails = ({campaign}) => {
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: false
  })
  const {data, isFetching} = useGetEmailStats(campaign?.Id, {
    onSuccess: (({messages}) => setPagination(prev => ({...prev, total: messages.length})))
  });
  
  const columns = [{
    title: "Name",
    dataIndex: "name",
    key: "Name",
  }, {
    title: "Email",
    dataIndex: "email",
    key: "email",
  }, {
    title: "Result",
    key: "Phone",
    render: (_, { status, opened }) => {
      if (status === "processed") return "Pending";
      if (status === "not_delivered") return (
        <span className="text-[#c54646]">Failed</span>
      );

      return (
        <span className="text-[#3aaf50]">
          {opened ? "Opened" : "Delivered"}
        </span>
      )
    },
  }]

  const rows = useMemo(() => 
    data?.messages.slice(
      (pagination.current - 1) * pagination.pageSize,
      (pagination.current - 1) * pagination.pageSize + pagination.pageSize
    ),
    [data, pagination]
  )


  return (
    <div className="flex flex-col gap-5">
      <div className="flex justify-between">
        <div className="flex flex-col gap-2">
          <Header>Campaign Reporting</Header>
          <span className="text-gray-400">
            Sent
            {moment(campaign?.SentOn).format('MM/DD/YYYY @h:ma zz')}
          </span>
        </div>
        <Input.Search
          className="w-1/3"
          size="large"
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder="Search for recipients"
        />
      </div>

      <div className="flex justify-around">
        <Statistic
          title="Recipients"
          value={campaign?.TotalContacts + campaign?.Meta?.EmailContacts}
        />
        <Statistic
          title="Delivered"
          value={data?.delivered} 
          valueStyle={{ color: '#3aaf50' }}
        />
        <Statistic
          title="Unique Opens"
          value={data?.unique_opens} 
          valueStyle={{ color: '#3aaf50' }}
        />
        <Statistic
          title="Unique Clicks"
          value={data?.unique_clicks} 
          valueStyle={{ color: '#3aaf50' }}
        />
        <Statistic
          title="Errors"
          value={(data?.bounce_drops || 0) + (data?.spam_report_drops || 0)}
          valueStyle={{ color: '#c54646' }}
        />
      </div>

      <Table
        columns={columns}
        dataSource={rows}
        loading={isFetching}
        onChange={pagination => setPagination(prev => ({...prev, ...pagination}))}
        pagination={pagination}
      />
    </div>
  )
}

export default EmailCampaignDetails;
