import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import "../../App.css";
import Reports from "./Reports/Reports";

class Campaigns extends React.Component {
    constructor() {
        super();
        this.searchSubmit = null;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location !== this.props.location) {
            this.setState({ searchText: "" });
        }
    }
    getSubmitMethod = (onSubmit) => {
        this.searchSubmit = onSubmit;
    };
    render() {
        return (
            <div className="tabs-container">
                <Switch>
                    <Route
                        path="/dashboard/reports/campaigns/"
                        render={(props) => (
                            <Reports {...props} sendSubmitMethod={this.getSubmitMethod} />
                        )}
                    />
                    <Redirect to="/dashboard/reports/campaigns" />
                </Switch>
            </div>
        );
    }
}

export default Campaigns;
