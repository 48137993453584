const COMPANY_TIMEZONE = {
    PACIFIC_TIME: 1,
    MOUNTAIN_TIME: 2,
    CENTRAL_TIME: 3,
    EASTERN_TIME: 4
};

const TIMEZONE_DATABASE_NAME = {
    [COMPANY_TIMEZONE.PACIFIC_TIME]: "America/Los_Angeles",
    [COMPANY_TIMEZONE.MOUNTAIN_TIME]: "America/Denver",
    [COMPANY_TIMEZONE.CENTRAL_TIME]: "America/Chicago",
    [COMPANY_TIMEZONE.EASTERN_TIME]: "America/New_York"
};

export default TIMEZONE_DATABASE_NAME;
