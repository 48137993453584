import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchUtil } from "../../../utils/fetchUtils";
import { getToken } from "../../../utils/tokenUtils";

import { toast } from "react-toastify";
import { isFunction } from "lodash";

const getSenders = async () => {
    const token = getToken();
    return await fetchUtil({
        url: `/email/sender`,
        token
    });
};

export const useGetSenders = () => {
    const { data = [] } = useQuery({
        queryKey: ["senders"],
        queryFn: getSenders
    });

    return data;
};

const createSender = async ({name, email}) => {
    const token = getToken();
    return await fetchUtil({
        method: "POST",
        url: `/email/sender`,
        body: JSON.stringify({
            name,
            email
        }),
        token
    });
};

export const useCreateSender = (options) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
        ...options,
        mutationFn: createSender,
        onSuccess: (res) => {
            queryClient.invalidateQueries("senders");
            if(isFunction(options.onSuccess)) options.onSuccess(res);
        },
        onError: (res) => {
            toast("Something went wrong", { containerId: "error" });
            if(isFunction(options.onError)) options.onError(res);
        },
    });

    return mutate;
};
