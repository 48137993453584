import React from "react";

import "../../App.css";

const Avatar = (props) => {
    const { FirstName = "", LastName = "", WithName = false, myClass, online } = props;
    return WithName ? (
        <div className="sidebar-name-pic">
            <span>
                {props.firstName && props.firstName.charAt(0).toUpperCase()}
                {props.firstName &&
                    props.firstName.length > 1 &&
                    props.firstName.charAt(1).toUpperCase()}
            </span>
            <label>
                {props.firstName} {props.lastName}
            </label>
        </div>
    ) : (
        <div className={`${myClass} ${online ? "online" : ""}`}>
            <p>
                {FirstName && FirstName.charAt(0).toUpperCase()}
                {LastName && LastName.charAt(0).toUpperCase()}
            </p>
        </div>
    );
};

export default Avatar;
