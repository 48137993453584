import React from "react";

import "../../../App.css";
import { Spinner } from "../../Loader";

function Button(props) {
    const {
        text,
        children,
        color,
        onClick,
        loading,
        classes = "",
        loaderProps,
        disabled,
        ...rest
    } = props;
    return (
        <button
            className={`btn btn-${color} ${classes} ${disabled ? "disabled" : ""}`}
            disabled={disabled}
            onClick={onClick}
            {...rest}
        >
            <>{children ? children : text}</> {loading && <Spinner {...loaderProps} />}
        </button>
    );
}

export default Button;
