import React from "react";

import SMSCampaignDetails from "./SMS";
import EmailCampaignDetails from "./Email";
import { Tabs } from "antd";

const CampaignDetails = ({campaign}) => {
  const tabs = [{
    key: 'email',
    label: "Email Results",
    children: <EmailCampaignDetails campaign={campaign} />
  }, {
    key: 'sms',
    label: "SMS Results",
    children: <SMSCampaignDetails campaign={campaign} />
  }]
  return (
    <>
      {
        !!(campaign?.Type === 3 && campaign?.AsEmailFallback)
          ? <Tabs defaultActiveKey="email" items={tabs} />
          : !!(campaign?.Type === 3)
            ? <EmailCampaignDetails campaign={campaign} />
            : <SMSCampaignDetails campaign={campaign} />
      }
    </>
  )
}

export default CampaignDetails;
