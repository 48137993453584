import React, { Component } from "react";

import "./ToastMessage.css";

class ToasterMessage extends Component {
    render() {
        const { text, icon = "success" } = this.props;
        return (
            <div className="toaster-message-container">
                <div className="message-icon-wrapper">
                    <span className={`${icon}-mark`}></span>
                </div>
                <span className="toaster-text">{text}</span>
            </div>
        );
    }
}

export default ToasterMessage;
