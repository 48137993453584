import React from "react";
import { connect } from "react-redux";
import "../../App.css";
import { CsvGray, CsvGreen, CloudGray, CloudUpload, CloudDone } from "../../assets/png-icon/index";
import { ImportCsvOrange, Alert } from "../../assets/svg-icon/index";
import { uploadCsvContacts, downloadCsvTemplate } from "../../redux/actions";
import { errorToaster } from "../../utils/loggerUtils";
import { CancelBtnRed } from "../../components/Button";

let FILE_TYPES = ["vnd.ms-excel", "csv", ""]; // TODO: remove empty string

class ImportContacts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dragging: false,
            isLoading: false,
            isSuccessful: false,
            isFailed: false,
            uploadedLength: null,
            successContacts: [],
            failedContacts: []
        };
        this.dragCounter = 0;
        this.abortController = null;
    }

    handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter++;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({ dragging: true });
        }
    };

    handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter--;
        if (this.dragCounter > 0) {
            return;
        }
        this.setState({ dragging: false });
    };

    handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ dragging: false });
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            let fileObj = e.dataTransfer.files[0];
            this.handleFileUpload(fileObj);
            e.dataTransfer.clearData();
            this.dragCounter = 0;
        }
    };

    handleFileUpload = (fileObj) => {
        const fileType = fileObj.type.split("/").pop();
        this.abortController = new AbortController();

        if (FILE_TYPES.includes(fileType)) {
            this.setState({ isLoading: true });
            this.props
                .uploadCsvContacts(fileObj, this.abortController.signal)
                .then((res) => {
                    let { FailedContacts, SuccessContacts } = res;
                    if (!!SuccessContacts.length) {
                        this.setState({
                            isSuccessful: true,
                            isFailed: false,
                            uploadedLength: SuccessContacts.length,
                            isLoading: false
                        });
                    } else {
                        this.setState({
                            isFailed: true,
                            isSuccessful: false,
                            uploadedLength: FailedContacts.length,
                            isLoading: false
                        });
                    }
                    this.setState({
                        successContacts: SuccessContacts,
                        failedContacts: FailedContacts
                    });
                })
                .catch((err) => {
                    this.setState({ isLoading: false });
                });
        } else {
            errorToaster("Invalid File Type");
        }
    };

    handleCsvDownloadClick = (e) => {
        e.preventDefault();
        this.props.downloadCsvTemplate().then((res) => {
            let hiddenElement = document.createElement("a");
            hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(res);
            hiddenElement.target = "_blank";
            hiddenElement.download = `template_${new Date().getTime()}.csv`;
            hiddenElement.click();
        });
    };

    hasUserUploaded = () => {
        const { isSuccessful, isFailed } = this.state;
        return isSuccessful || isFailed;
    };

    handleBackToContactsClick = () => {
        this.props.history.push("/dashboard/contacts/all-contacts");
    };

    renderImage = (src, alt, show, classes = "") => {
        let styles = show ? { display: "initial" } : { display: "none" };
        return <img src={src} alt={alt} style={styles} className={classes} />;
    };

    renderImportContactsResult = () => {
        const { successContacts, failedContacts } = this.state;

        return successContacts.length || failedContacts.length ? (
            <div className="import-contacts-result fancy-scroll">
                <table className="result-table">
                    <thead>
                        <tr>
                            <th>Number</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {successContacts.map((contact, index) => {
                            return (
                                <tr key={index}>
                                    <td>{contact.PhoneNumber}</td>
                                    <td className="success">Success</td>
                                </tr>
                            );
                        })}
                        {failedContacts.map((contact, index) => {
                            return (
                                <tr key={index}>
                                    <td>{contact.PhoneNumber}</td>
                                    <td className="failed">Failed</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        ) : null;
    };

    render() {
        const { dragging, isLoading, isSuccessful, uploadedLength } = this.state;

        return (
            <div className="import-contacts-wrapper">
                <div className="import-contacts-heading-wrapper">
                    <h1 className="import-contacts-heading">Import New Contacts</h1>
                    <hr />
                    <div className="import-contacts-description-wrapper">
                        <div className="import-contacts-description">
                            Drag-and-drop a CSV file below to import your contacts into ClubChat.{" "}
                            <div>
                                <a
                                    className="click-here"
                                    href="void"
                                    onClick={this.handleCsvDownloadClick}
                                >
                                    Click here
                                </a>{" "}
                                to download a template of the proper CSV format.
                            </div>
                        </div>
                        <div className="import-contacts-note">
                            *All new contacts will automatically be sent an opt-in message
                        </div>
                    </div>
                </div>
                <div className="import-contacts-csv-drop">
                    <div className="import-contacts-csv-drop-frame-wrapper">
                        <div
                            className="inline-block import-contacts-csv-drop-frame"
                            onDragEnter={this.handleDragEnter}
                            onDragLeave={this.handleDragLeave}
                            onDragOver={this.handleDragOver}
                            onDrop={this.handleDrop}
                        >
                            {this.renderImage(
                                CsvGreen,
                                "dotted green",
                                isLoading || this.state.isSuccessful
                            )}
                            {this.renderImage(
                                ImportCsvOrange,
                                "dotted orange",
                                !isLoading && this.state.isFailed
                            )}
                            {this.renderImage(
                                CsvGray,
                                "dotted black",
                                !isLoading && !this.hasUserUploaded()
                            )}
                            <div className="csv-drop-description">
                                {this.renderImage(
                                    CloudDone,
                                    "cloud green",
                                    !isLoading && this.state.isSuccessful
                                )}
                                {this.renderImage(
                                    Alert,
                                    "Alert",
                                    !isLoading && this.state.isFailed,
                                    "alert"
                                )}
                                {this.renderImage(
                                    CloudUpload,
                                    "cloud gradient",
                                    isLoading && !this.hasUserUploaded()
                                )}
                                {this.renderImage(
                                    CloudGray,
                                    "cloud black",
                                    !isLoading && !this.hasUserUploaded()
                                )}
                                {!isLoading && !this.hasUserUploaded() ? (
                                    <h1>
                                        {!dragging ? (
                                            <>
                                                Drag & drop your CSV file here or{<br />}
                                                <a href="void" className="uploader">
                                                    Browse your device{" "}
                                                    <input
                                                        type="file"
                                                        onChange={(e) => {
                                                            let event = e;
                                                            this.handleFileUpload(
                                                                event.target.files[0]
                                                            );
                                                            event.target.value = null;
                                                        }}
                                                        accept=".csv"
                                                    />
                                                </a>
                                            </>
                                        ) : (
                                            "Drop here"
                                        )}
                                    </h1>
                                ) : (
                                    <h1 className="import-contacts-upload-heading">
                                        {isLoading ? (
                                            <>
                                                <span>30%</span> Completed
                                                <div>
                                                    <CancelBtnRed
                                                        onClick={() => this.abortController.abort()}
                                                    />
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <span>{uploadedLength} contacts</span> have{" "}
                                                {isSuccessful
                                                    ? `successfully
                                                been imported`
                                                    : `failed to import`}
                                            </>
                                        )}
                                    </h1>
                                )}
                                {!isLoading && this.state.isSuccessful && (
                                    <div
                                        className="import-contacts-back-btn"
                                        onClick={this.handleBackToContactsClick}
                                    >
                                        Back to All Contacts
                                    </div>
                                )}
                                {!isLoading && this.state.isFailed && (
                                    <p className="import-contacts-failed-msg">
                                        Phone Numbers must have +1
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    {this.renderImportContactsResult()}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        uploadCsvContacts: (data, signal) => dispatch(uploadCsvContacts(data, signal)),
        downloadCsvTemplate: () => dispatch(downloadCsvTemplate())
    };
};

export default connect(null, mapDispatchToProps)(ImportContacts);
