import React from "react";
import { ChatMessageTypes } from "../../../config/constants";
const InboxMessageNotification = (props) => {
    let time = props.time ? props.time.replace(",", " at ") : props.time;
    return (
        <div className="w-11/12">
            <div
                className="message-server-chat flex items-center justify-between"
            >
                <div className="flex-grow border-t-gray-300 border-t-2 border-dashed"></div>
                <div>
                    <p
                        className="server-notification-text mx-8 my-4 text-center text-gray-400 text-lg"
                    >
                        {props.senderType === ChatMessageTypes.ONBOARDFORMNOTIFICATION
                            ? `Onboard form sent on ${time}`
                            : `Contact card sent on ${time}`}{" "}
                    </p>
                </div>
                <div className="flex-grow border-t-gray-300 border-t-2 border-dashed"></div>
            </div>
        </div>
    )
}
export default InboxMessageNotification;
