import { errorToaster } from "../../utils/loggerUtils";
import { fetchUtil } from "../../utils/fetchUtils";
import { persistor } from "../store";
import { getToken } from "../../utils/tokenUtils";
import { FieldStatus, FormType } from "../../constant";
import { appendQueryParams } from "../../utils/urlUtils";

export const ME_REQUEST = "ME_REQUEST";
export const ME_SUCCESS = "ME_SUCCESS";
export const ME_FAIL = "ME_FAIL";
export const SIGN_OUT = "SIGN_OUT";
export const AUTH_FAIL = "AUTH_FAIL";
export const CHANGE_ADMIN_VIEW = "CHANGE_ADMIN_VIEW";
export const SET_SHOW_NOTIFICATION_PERMISSION = "SET_SHOW_NOTIFICATION_PERMISSION";
export const SET_COMPANY_INVITED_MESSAGE = "SET_COMPANY_INVITED_MESSAGE";
export const SET_SOUND_STATUS = "SET_SOUND_STATUS";
export const BRNAD_REGISTER_FORM = "BRNAD_REGISTER_FORM";
export const setSoundStatusRequest = (status) => (dispatch, getState) => {
    dispatch({
        type: SET_SOUND_STATUS,
        payload: status
    });
};
export const handleBrandregister = (show) => {
    return {
        type: BRNAD_REGISTER_FORM,
        payload: show
    };
};

export const login = (email, password) => (dispatch, getState) => {
    let body = JSON.stringify({
        Email: email,
        Password: password
    });

    return fetchUtil({
        url: "/login",
        method: "POST",
        body
    })
        .then((res) => {
            if (res.Token) {
                localStorage.RCToken = res.Token;
                dispatch(getMe());
                return Promise.resolve(true);
            }
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};

export const resetPassword = (email) => (dispatch, getState) => {
    let body = JSON.stringify({
        Email: email
    });

    return fetchUtil({
        url: "/reset/password",
        method: "POST",
        body
    })
        .then((res) => {
            return Promise.resolve(true);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const changePassword = (password, code) => (dispatch, getState) => {
    let body = JSON.stringify({
        Password: password,
        Code: code
    });

    return fetchUtil({
        url: "/change/password",
        method: "POST",
        body
    })
        .then((res) => {
            return Promise.resolve(true);
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};

export const getMe = () => (dispatch, getState) => {
    dispatch({ type: ME_REQUEST });

    return new Promise((resolve, reject) => {
        if (localStorage.RCToken !== undefined) {
            fetchUtil({
                url: "/me",
                token: localStorage.RCToken
            })
                .then((res) => {
                    if (res) {
                        dispatch({
                            type: ME_SUCCESS,
                            payload: res,
                            token: localStorage.RCToken
                        });
                        resolve(res);
                    }
                })
                .catch((err) => {
                    dispatch({ type: ME_FAIL });
                    reject(err);
                });
        } else {
            dispatch({ type: ME_FAIL });
            reject(false);
        }
    });
};
export const setShowPermisionNotification = () => (dispatch) => {
    dispatch({ type: SET_SHOW_NOTIFICATION_PERMISSION });
};
export const signOut = () => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: "/signout",
        method: "POST",
        token
    })
        .then((res) => {
            if (res) {
                persistor.purge().then((res) => {
                    dispatch({ type: SIGN_OUT });
                    localStorage.clear();
                    sessionStorage.clear();
                    return Promise.resolve(true);
                });
            }
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const changeAdminView = (isAdmin, url = null) => {
    return { type: CHANGE_ADMIN_VIEW, payload: isAdmin, url };
};
export const updateCompanyInvitedMessage = (bodyData) => (dispatch, getState) => {
    const token = getToken();
    let body = JSON.stringify(bodyData);
    return fetchUtil({
        url: "/company",
        method: "PUT",
        token,
        body
    })
        .then((res) => {
            if (res) {
                dispatch({ type: SET_COMPANY_INVITED_MESSAGE, payload: bodyData });
                return Promise.resolve(true);
            }
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getRegisterFormFields = (data) => (dispatch, getState) => {
    const token = getToken();
    let queryParams = {
        Status: FieldStatus.Show,
        FormId: FormType.OnBoard
    };
    if (data.Code) {
        queryParams["Code"] = data.Code;
    } else {
        queryParams["CompanyId"] = data.CompanyId;
    }
    return fetchUtil({
        url: appendQueryParams("/company/form/fields", queryParams),
        token
    })
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((e) => {
            return Promise.reject(e);
        });
};
