import React from "react";
import { connect } from "react-redux";
import withUser from "../../hoc/withUser";
import { getSegments, addContact, getRegisterFormFields } from "../../redux/actions";
import InputMask from "react-input-mask";
import { SegmentListCross } from "../../assets/svg-icon";
import { Spinner } from "../../components/Loader";
import { validateEmail, validateUSPhoneNumber } from "../../utils/validateHelperUtils";
import { FormFieldSlug } from "../../constant";
class AddContactModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            phoneNumber: "",
            email: "",
            emailError: false,
            firstNameError: false,
            lastNameError: false,
            phoneNumberError: false,
            loading: false,
            disablePhoneNumber: false,
            segments: [],
            segmentIds: [],
            isFocused: "",
            allowedSlugs: []
        };
    }
    onInputChange = (e) => {
        let isErrorFieldInState = false;
        if (`${e.target.name}Error` in this.state) {
            isErrorFieldInState = true;
        }
        this.setState({
            [e.target.name]: e.target.value,
            ...(isErrorFieldInState && { [`${e.target.name}Error`]: false }),
            isFieldUpdated: true
        });
    };
    segmentAddOrRemove = (segmentObj) => {
        if (segmentObj.selected) {
            const { segments, segmentIds } = this.state;
            this.setState({
                segments: segments.map((val) => {
                    if (val.Id === segmentObj.Id) {
                        val.selected = false;
                    }
                    return val;
                }),
                segmentIds: segmentIds.filter((val) => val !== segmentObj.Id)
            });
        } else {
            const { segments, segmentIds } = this.state;
            segmentIds.push(segmentObj.Id);
            this.setState({
                segments: segments.map((val) => {
                    if (val.Id === segmentObj.Id) {
                        val.selected = true;
                    }
                    return val;
                }),
                segmentIds
            });
        }
    };
    AddOrRemoveFocus = (data) => {
        this.setState({
            isFocused: data
        });
    };
    componentDidMount() {
        const { getRegisterFormFields, user } = this.props;
        getRegisterFormFields({ CompanyId: user.Company.Id }).then((val) => {
            let formFields = val.FormFields;
            // console.log(val)
            this.setState({
                allowedSlugs: formFields.map((val) => {
                    return val.FormFieldId.Slug;
                })
            });
        });
        this.props.getSegments().then(
            (res) => {
                this.setState({
                    segments: res
                });
            },
            (err) => {}
        );
    }
    isFieldsValid = () => {
        const { firstName, lastName, email } = this.state;

        if (
            !firstName.trim().length ||
            !lastName.trim().length
        ) {
            this.setState({
                firstNameError: !firstName.trim().length,
                lastNameError: !lastName.trim().length
            });
            return false;
        }
        if (!validateEmail(email) && email.length > 0) {
            this.setState({
                emailError: true
            });
            return false;
        }
        return true;
    };
    handleAddContactSubmit = (e) => {
        e.preventDefault();
        if (this.state.isLoading) {
            return;
        }
        this.setState({ isLoading: true });
        let { firstName, lastName, phoneNumber, segmentIds, email } = this.state;
        const isValid = this.isFieldsValid();
        if (isValid) {
            if (phoneNumber && !validateUSPhoneNumber(phoneNumber)) {
                this.setState({ phoneNumberError: true });
                return;
            }
            phoneNumber = phoneNumber.replace(/[\s()-]/g, "");
            this.props
                .addContact(firstName, lastName, phoneNumber, segmentIds, email)
                .then((res) => {
                    this.props.handleAddContactCallBack(res);
                    this.setState({
                        firstName: "",
                        lastName: "",
                        phoneNumber: "",
                        email: ""
                    });
                })
                .finally(() => {
                    this.setState({
                        isLoading: false,
                        firstNameError: false,
                        lastNameError: false,
                        emailError: false
                    });
                });
        } else {
            this.setState({ isLoading: false });
        }
    };
    render() {
        const { handleClose } = this.props;
        const {
            firstNameError,
            firstName,
            lastName,
            lastNameError,
            email,
            phoneNumber,
            phoneNumberError,
            segments,
            isFocused,
            loading
        } = this.state;
        return (
            <div className="contacts-newcontact-modal">
                <div className="contacts-newcontact-modal-content">
                    <div className="contacts-newcontact-modal-header">
                        <span
                            onClick={handleClose}
                            className="close"
                            id="contacts-newcontact-closebutton"
                        >
                            &times;
                        </span>
                        <h3>New Contact</h3>
                        <form onSubmit={this.handleAddContactSubmit}>
                            <div className="contacts-newcontact-form">
                                <div className="form-group">
                                    <label
                                        className={isFocused === "fname" ? `contacts-label` : ``}
                                    >
                                        First Name*
                                    </label>
                                    {firstNameError ? (
                                        <div className="error-strip">First Name is required</div>
                                    ) : null}
                                    <input
                                        onFocus={() => this.AddOrRemoveFocus("fname")}
                                        onBlur={() => this.AddOrRemoveFocus("")}
                                        placeholder="First Name"
                                        name="firstName"
                                        onChange={this.onInputChange}
                                        value={firstName}
                                    />
                                </div>
                                <div className="form-group">
                                    <label
                                        className={isFocused === "lname" ? `contacts-label` : ``}
                                    >
                                        Last Name*
                                    </label>
                                    {lastNameError ? (
                                        <div className="error-strip">Last Name is required</div>
                                    ) : null}
                                    <input
                                        onFocus={() => this.AddOrRemoveFocus("lname")}
                                        onBlur={() => this.AddOrRemoveFocus("")}
                                        placeholder="Last Name"
                                        name="lastName"
                                        onChange={this.onInputChange}
                                        value={lastName}
                                    />
                                </div>
                                {this.state.allowedSlugs.includes(FormFieldSlug.email) && (
                                    <div className="form-group">
                                        {/* <label className={isFocused === "email" ? `contacts-label` : ``}>Email Address:</label>
                                    {emailError ? <div className="error-strip">Valid Email Address is required</div> : null} */}
                                        <input
                                            onFocus={() => this.AddOrRemoveFocus("email")}
                                            onBlur={() => this.AddOrRemoveFocus("")}
                                            placeholder="Email Address"
                                            name="email"
                                            type="email"
                                            onChange={this.onInputChange}
                                            value={email}
                                        />
                                    </div>
                                )}

                                <div className="form-group">
                                    <label
                                        className={isFocused === "phone" ? `contacts-label` : ``}
                                    >
                                        Phone Number
                                    </label>
                                    {phoneNumberError ? (
                                        <div className="error-strip">Phone Number is invalid</div>
                                    ) : (
                                        ``
                                    )}
                                    <InputMask
                                        onFocus={() => this.AddOrRemoveFocus("phone")}
                                        onBlur={() => this.AddOrRemoveFocus("")}
                                        mask="+1 (999)-999-9999"
                                        placeholder="Phone Number"
                                        name="phoneNumber"
                                        onChange={this.onInputChange}
                                        value={phoneNumber}
                                        type="text"
                                        autoComplete="off"
                                    />
                                </div>

                                <div className="tab-segment">
                                    <div className="row">
                                        <div className="w-2">
                                            <h4>Lists</h4>
                                        </div>

                                        <div className="w-10">
                                            <div className="segment-listing">
                                                <ul>
                                                    {segments.map((segment) => {
                                                        if (!segment.selected) {
                                                            return false;
                                                        }
                                                        return (
                                                            <li
                                                                key={segment.Id}
                                                                style={{ cursor: "pointer" }}
                                                                // onClick={() => this.segmentAddOrRemove(segment)}
                                                            >
                                                                <span>
                                                                    <img
                                                                        src={SegmentListCross}
                                                                        className="img-fluid"
                                                                        alt="img"
                                                                        onClick={() =>
                                                                            this.segmentAddOrRemove(
                                                                                segment
                                                                            )
                                                                        }
                                                                    />
                                                                    {segment.Title}
                                                                </span>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-segment-list">
                                    <div className="row">
                                        <div className="w-2"></div>
                                        <div className="w-10">
                                            <ul>
                                                {segments.map((segment) => {
                                                    return (
                                                        <li
                                                            key={segment.Id}
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() =>
                                                                this.segmentAddOrRemove(segment)
                                                            }
                                                        >
                                                            <span
                                                                className={
                                                                    segment.selected
                                                                        ? `segmentSelected`
                                                                        : ``
                                                                }
                                                            >
                                                                {segment.Title}
                                                            </span>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="newcontact-buttons">
                                        {!loading ? (
                                            <button className="createcontact-btn">
                                                Create Contact
                                            </button>
                                        ) : (
                                            <button disabled={true} className="createcontact-btn">
                                                <Spinner size="lg" color={"white"} />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getSegments: () => dispatch(getSegments()),
        addContact: (firstName, lastName, phone, segmentIds, email) =>
            dispatch(addContact(firstName, lastName, phone, segmentIds, email)),
        getRegisterFormFields: (data) => dispatch(getRegisterFormFields(data))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withUser(AddContactModal));
