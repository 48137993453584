import { errorToaster } from "../../utils/loggerUtils";
import { fetchUtil } from "../../utils/fetchUtils";
import { getToken } from "../../utils/tokenUtils";
import { setSoundStatusRequest } from "../actions/index";
export const UPDATE_SOUND_STATUS = "UPDATE_SOUND_STATUS";
export const SET_EMAIL_ALERT = "SET_EMAIL_ALERT";
export const setEmailAlert = (status, emailAddresses, emailAlertTime) => (dispatch, getState) => {
    const token = getToken();
    let body = JSON.stringify({
        status,
        emailAddresses,
        emailAlertTime
    });
    return fetchUtil({
        url: "/setting/emailalert",
        token,
        method: "POST",
        body
    })
        .then((response) => {
            return dispatch(getEmailAlertData())
                .then((res) => {
                    dispatch({ type: SET_EMAIL_ALERT, payload: res });
                    return Promise.resolve(res);
                })
                .catch((err) => {
                    errorToaster(err.Message);
                    return Promise.reject(err);
                });
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};

export const setAutoResponseAlert = (
    status,
    content,
    autoResponseSchedule,
    mediaUrl,
    isMediaUpdate
) => (dispatch, getState) => {
    const token = getToken();
    let body = null;
    if (isMediaUpdate) {
        body = JSON.stringify({
            status,
            content,
            mediaUrl,
            autoResponseSchedule
        });
    } else {
        body = JSON.stringify({
            status,
            content,
            autoResponseSchedule
        });
    }
    return fetchUtil({
        url: "/setting/autoresponse",
        token,
        method: "POST",
        body
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};

export const getAutoResponse = () => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: `/setting/autoresponse`,
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};

export const setSoundStatus = (status) => (dispatch, getState) => {
    const token = getToken();
    let body = JSON.stringify({
        status
    });
    return fetchUtil({
        url: "/setting/sound",
        token,
        method: "POST",
        body
    })
        .then((response) => {
            dispatch(setSoundStatusRequest(status));
            // setSoundStatus(status)
            return Promise.resolve(true);
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};

export const getEmailAlertData = () => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: `/setting/emailalert`,
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};

export const getWebChat = () => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: `/webchats/listing/agent`,
        token
    })
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};
export const getUsersByCompany = () => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: `/get/company/users`,
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};

export const getWebChatWidgetSettings = (WebsiteId) => (dispatch, getState) => {
    return fetchUtil({
        url: `/webchat/${WebsiteId}`,
        token: getToken()
    })
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((e) => {
            errorToaster(e.Message);
            return Promise.reject(e);
        });
};

export const updateWidgetSettings = (payload) => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        method: "PUT",
        body: JSON.stringify(payload),
        url: `/update/widget_settings`,
        token
    })
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((e) => {
            errorToaster(e.Message);
            return Promise.reject(e);
        });
};
