import moment from "moment";
export const validateUSPhoneNumber = (number) => {
    let re = /^[+]1\s\([0-9]{3}\)-[0-9]{3}-[0-9]{4}$/g;
    if (re.test(number)) {
        return true;
    }
    return false;
};

export const validateEmail = (email) => {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const ShortDate = "MM/DD/YYYY";

export function isValideDate(testDate) {
    let date_regex = /^(0[1-9]|1[0-2])[- /.](0[1-9]|1\d|2\d|3[01])[- /.](19|20)\d{2}$/;
    if (!date_regex.test(testDate)) {
        return false;
    }
    return true;
}
export function Is_Of_Age(contact, age) {
    let Meta = JSON.parse(contact.Meta);
    if (!Meta) {
        return false;
    }
    if (!Meta.hasOwnProperty("BirthdayDate")) {
        return false;
    }
    if (!isValideDate(Meta.BirthdayDate)) {
        return false;
    }
    let my_dob = moment(Meta.BirthdayDate, ShortDate);
    let today = moment();
    return today.diff(my_dob, "years", false) >= age;
}

export function Is_Of_Age_Date(dob, age) {
    if (!isValideDate(dob)) {
        return false;
    }

    let my_dob = moment(dob, ShortDate);
    let today = moment();
    return today.diff(my_dob, "years", false) >= age;
}
