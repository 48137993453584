import React, { useState } from "react";
import { connect } from "react-redux";

import { Button } from "antd";

import { useCreateSender } from "../../../hooks/api/email/senders";

import TextInput from "../../atoms/Input/Text";

const NewSenderForm = ({ user }) => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState(`@${user?.Company?.Domain}`);

  const createSender = useCreateSender({
    onSuccess: () => {
      setName("");
      setEmail(`@${user?.Company?.Domain}`);
    }
  });

  return (
    <div className="flex gap-2 flex-wrap mt-2">
      <TextInput
        placeholder="Name"
        className="flex-grow"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextInput
        placeholder="Email"
        className="flex-grow"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      
      <Button
        disabled={!name || !email.replace(`@${user?.Company?.Domain}`, '')}
        className="bg-main text-white w-full"
        onClick={() => createSender({name, email})}
      >
        Add
      </Button>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
})

export default connect(mapStateToProps)(NewSenderForm);
