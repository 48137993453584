import React from "react";
import { connect } from "react-redux";

import { isSSOAuthenticated } from "../utils/ssoUtils";

export default (WrapedComponent) => {
    class EnhancedComponent extends React.Component {
        render() {
            const user = isSSOAuthenticated() ? this.props.ssoUser : this.props.user;
            return <WrapedComponent {...this.props} user={user} />;
        }
    }
    const mapStateToProps = (state) => {
        return {
            user: state.auth.user,
            ssoUser: state.ssoAuth.user
        };
    };
    return connect(mapStateToProps, null)(EnhancedComponent);
};
