import withUser from "../../hoc/withUser";
import { connect } from "react-redux";
import Days from "../../config/constants/AutoResponseSelectDays";
import React from "react";
import { greenPlusIcon } from "../../assets/svg-icon/index";
import { MessageBox } from "../MessageBox/index";
import Switch from "react-input-switch";
import { Spinner } from "../../components/Loader";
import { imageUpload, setAutoResponseAlert, getAutoResponse } from "../../redux/actions";
class AccountSettingAutoResponseTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
            autoResponseSchedule: [],
            AutoResponderStatus: 0,
            content: "",
            mediaUrl: null,
            isMediaUpdate: false,
            mediaObject: null,
            UploadMedia: false,
            loading: false
        };
    }
    componentDidMount() {
        this.props.getAutoResponse().then((res) => {
            this.setState({
                AutoResponderStatus: res.status,
                mediaUrl: res.mediaUrl,
                content: res.content,
                autoResponseSchedule: res.autoResponseSchedule,
                fetching: false
            });
            if (res.autoResponseSchedule.length === 0) {
                this.setState((prevState) => ({
                    autoResponseSchedule: [
                        ...prevState.autoResponseSchedule,
                        {
                            SelectedDay: Days.EveryDay,
                            To: "08:00",
                            From: "18:00",
                            error: false,
                            default: true
                        }
                    ]
                }));
            }
        });
    }
    Time = function(timeString) {
        var t = timeString.split(":");
        this.hour = parseInt(t[0]);
        this.minutes = parseInt(t[1]);
        this.isBiggerThan = function(other) {
            return (
                this.hour > other.hour || (this.hour === other.hour && this.minutes > other.minutes)
            );
        };
    };
    timeIsBetween = (start, end, check) => {
        return start.hour <= end.hour
            ? check.isBiggerThan(start) && !check.isBiggerThan(end)
            : (check.isBiggerThan(start) && check.isBiggerThan(end)) ||
                  (!check.isBiggerThan(start) && !check.isBiggerThan(end));
    };
    addClick() {
        let autoResponseSchedules = this.state.autoResponseSchedule;
        autoResponseSchedules = autoResponseSchedules.filter((val) => !val.default);
        this.setState({
            autoResponseSchedule: [
                ...autoResponseSchedules,
                { SelectedDay: Days.EveryDay, To: "", From: "", error: false, default: false }
            ]
        });
    }
    removeClick(i) {
        let autoResponseSchedule = [...this.state.autoResponseSchedule];
        autoResponseSchedule.splice(i, 1);
        this.setState({ autoResponseSchedule });
    }
    handleSumitAutoResponse = async () => {
        this.setState({ ...this.state, loading: true });
        let mediaUrl = null;
        if (this.state.mediaObject && this.state.mediaObject.Obj) {
            mediaUrl = await this.uploadMediaObj(this.state.mediaObject);
        }
        let autoResponseSchedule = this.state.autoResponseSchedule;
        if (autoResponseSchedule.length === 0 && !this.state.AutoResponderStatus) {
            autoResponseSchedule = [
                {
                    SelectedDay: Days.EveryDay,
                    To: "08:00",
                    From: "18:00",
                    error: false,
                    default: true
                }
            ];
        }
        this.setState({ ...this.state, loading: true });
        this.props
            .setAutoResponseAlert(
                this.state.AutoResponderStatus,
                this.state.content,
                autoResponseSchedule,
                mediaUrl,
                this.state.isMediaUpdate
            )
            .then((res) => {
                this.setState({ ...this.state, loading: false });
                this.props.handleAutoResponseConformation();
            })
            .catch(() => {
                this.setState({ ...this.state, loading: true });
            });
    };
    handleautoResponseSchedule() {
        let AutoResponderStatus = this.state.AutoResponderStatus ? 0 : 1;
        if (AutoResponderStatus && !this.state.autoResponseSchedule.length) {
            this.setState({
                autoResponseSchedule: [
                    {
                        SelectedDay: Days.EveryDay,
                        To: "08:00",
                        From: "18:00",
                        error: false,
                        default: true
                    }
                ]
            });
        }
        this.setState({ AutoResponderStatus: AutoResponderStatus });
    }

    uploadMediaObj = async (Obj) => {
        return new Promise((resolve, reject) => {
            if (Obj === null) {
                resolve(false);
            } else if (Obj.type === "image") {
                let formData = new FormData();
                formData.append("file", Obj.Obj);
                this.setState({ ...this.state, UploadMedia: true });
                this.props.imageUpload(formData).then(
                    (res) => {
                        this.setState({ ...this.state, UploadMedia: false });
                        resolve(res.location);
                    },
                    (err) => {
                        this.setState({ ...this.state, UploadMedia: false });
                        reject(err);
                    }
                );
            } else {
                resolve(Obj.Obj.url);
            }
        });
    };
    handleChangeContent = (e) => {
        e.preventDefault();
        this.setState({
            content: e.target.value
        });
    };
    clearFileInput = () => {
        this.setState({
            mediaObject: null
        });
    };
    updateMediaObjectGiyf = (Obj) => {
        this.setState({ mediaObject: Obj, isMediaUpdate: true });
    };
    _handleImageChange = (Obj) => {
        this.setState({ mediaObject: Obj, isMediaUpdate: true });
    };
    handleClearMeiaUrl = () => {
        this.setState({ ...this.state, mediaUrl: null, isMediaUpdate: true });
    };
    checkdayValidation(i, j, autoResponseSchedule) {
        if (
            autoResponseSchedule[i].SelectedDay === autoResponseSchedule[j].SelectedDay ||
            autoResponseSchedule[i].SelectedDay === Days.EveryDay ||
            autoResponseSchedule[j].SelectedDay === Days.EveryDay
        ) {
            return true;
        } else if (
            (autoResponseSchedule[i].SelectedDay === Days.Weekend &&
                (autoResponseSchedule[j].SelectedDay === Days.Sunday ||
                    autoResponseSchedule[j].SelectedDay === Days.Saturday)) ||
            (autoResponseSchedule[j].SelectedDay === Days.Weekend &&
                (autoResponseSchedule[i].SelectedDay === Days.Sunday ||
                    autoResponseSchedule[i].SelectedDay === Days.Saturday))
        ) {
            return true;
        } else if (
            (autoResponseSchedule[i].SelectedDay === Days.Weekday &&
                (autoResponseSchedule[j].SelectedDay === Days.Monday ||
                    autoResponseSchedule[j].SelectedDay === Days.Tuesday ||
                    autoResponseSchedule[j].SelectedDay === Days.Wednesday ||
                    autoResponseSchedule[j].SelectedDay === Days.Thurday ||
                    autoResponseSchedule[j].SelectedDay === Days.Firday)) ||
            (autoResponseSchedule[j].SelectedDay === Days.Weekday &&
                (autoResponseSchedule[i].SelectedDay === Days.Monday ||
                    autoResponseSchedule[i].SelectedDay === Days.Tuesday ||
                    autoResponseSchedule[i].SelectedDay === Days.Wednesday ||
                    autoResponseSchedule[i].SelectedDay === Days.Thurday ||
                    autoResponseSchedule[i].SelectedDay === Days.Firday))
        ) {
            return true;
        }
        return false;
    }
    checkErrorValidation(autoResponseSchedule) {
        for (let i = autoResponseSchedule.length - 1; i > 0; i--) {
            for (let j = 0; j < autoResponseSchedule.length; j++) {
                if (this.checkdayValidation(i, j, autoResponseSchedule) && i !== j) {
                    const isBetweenToInLoop = this.timeIsBetween(
                        new this.Time(autoResponseSchedule[j].To),
                        new this.Time(autoResponseSchedule[j].From),
                        new this.Time(autoResponseSchedule[i].To)
                    );
                    const isBetweenFromInLoop = this.timeIsBetween(
                        new this.Time(autoResponseSchedule[j].To),
                        new this.Time(autoResponseSchedule[j].From),
                        new this.Time(autoResponseSchedule[i].From)
                    );

                    const isBetweenToOverLoop = this.timeIsBetween(
                        new this.Time(autoResponseSchedule[j].To),
                        new this.Time(autoResponseSchedule[j].From),
                        new this.Time(autoResponseSchedule[i].To)
                    );
                    const isBetweenFromOverLoop = this.timeIsBetween(
                        new this.Time(autoResponseSchedule[j].To),
                        new this.Time(autoResponseSchedule[j].From),
                        new this.Time(autoResponseSchedule[i].From)
                    );
                    if (
                        isBetweenToInLoop ||
                        isBetweenFromInLoop ||
                        isBetweenToOverLoop ||
                        isBetweenFromOverLoop
                    ) {
                        autoResponseSchedule[i].error = true;
                        break;
                    } else {
                        autoResponseSchedule[i].error = false;
                    }
                }
            }
        }
        return autoResponseSchedule;
    }
    handleChange(i, type, event) {
        let autoResponseSchedule = [...this.state.autoResponseSchedule];
        if (type === "to") {
            autoResponseSchedule[i].To = event.target.value;
        } else if (type === "from") {
            autoResponseSchedule[i].From = event.target.value;
        } else {
            autoResponseSchedule[i].SelectedDay = Number(event.target.value);
        }
        if (
            new this.Time(autoResponseSchedule[i].To).isBiggerThan(
                new this.Time(autoResponseSchedule[i].From)
            )
        ) {
            autoResponseSchedule[i].error = true;
        } else {
            autoResponseSchedule[i].error = false;
        }
        autoResponseSchedule = this.checkErrorValidation(autoResponseSchedule);
        this.setState({ autoResponseSchedule });
    }
    isDisableSubmitButton = () => {
        for (let i = 0; i < this.state.autoResponseSchedule.length; i++) {
            if (
                this.state.autoResponseSchedule[i].error ||
                this.state.autoResponseSchedule[i].To === "" ||
                this.state.autoResponseSchedule[i].From === ""
            ) {
                return true;
            }
        }
        if (this.state.UploadMedia) {
            return true;
        }
        if (this.state.autoResponseSchedule.length === 0) {
            return true;
        }
        return false;
    };
    createUI() {
        return this.state.autoResponseSchedule.map((el, i) => (
            <div className="addinghours" key={i}>
                <div className="row margin-bottom-5">
                    <div className="w-3">
                        <div className="addinghoursbox">
                            <select
                                value={el.SelectedDay}
                                className={`${el.error ? "red-border" : ""}`}
                                onChange={this.handleChange.bind(this, i, "day")}
                            >
                                <option disabled>Choose one...</option>
                                <option value={Days.EveryDay}>Everyday</option>
                                <option value={Days.Weekday}>Weekdays</option>
                                <option value={Days.Weekend}>Weekends</option>
                                <option value={Days.Monday}>Monday</option>
                                <option value={Days.Tuesday}>Tuesday</option>
                                <option value={Days.Wednesday}>Wednesday</option>
                                <option value={Days.Thurday}>Thursday</option>
                                <option value={Days.Firday}>Friday</option>
                                <option value={Days.Saturday}>Saturday</option>
                                <option value={Days.Sunday}>Sunday</option>
                            </select>
                        </div>
                    </div>
                    <div className="w-1">
                        <div className="addinghoursbox">
                            <label>from {el.error}</label>
                        </div>
                    </div>
                    <div className="w-3">
                        <div className="addinghoursbox">
                            <input
                                value={el.To || ""}
                                className={`${el.error ? "red-border" : ""}`}
                                onChange={this.handleChange.bind(this, i, "to")}
                                type="time"
                                placeholder="9:00 am"
                            />
                        </div>
                    </div>
                    <div className="w-1">
                        <div className="addinghoursbox">
                            <label>to</label>
                        </div>
                    </div>
                    <div className="w-3">
                        <div className="addinghoursbox">
                            <input
                                value={el.From || ""}
                                className={`${el.error ? "red-border" : ""}`}
                                onChange={this.handleChange.bind(this, i, "from")}
                                type="time"
                                placeholder="5:00 pm"
                            />
                        </div>
                    </div>
                    <div className="w-1" onClick={this.removeClick.bind(this, i)}>
                        <div className="addinghoursbox">
                            <span className="close-btn">&times;</span>
                        </div>
                    </div>
                </div>
            </div>
        ));
    }
    render() {
        return (
            <div className="setting-tab-content">
                {this.state.fetching ? (
                    <div style={{ textAlign: "center" }}>
                        {" "}
                        <Spinner size="xxl" color="green" />{" "}
                    </div>
                ) : (
                    <div>
                        <h5>
                            {/* Turn OFF autoresponse to stop sending messages during inactive hours */}
                            Automatically response to incoming messages when you're unavailable
                            <span>
                                <div className="inactivehourstoggle">
                                    <div className="toggleswitching">
                                        <Switch
                                            styles={{
                                                trackChecked: {
                                                    backgroundColor: "#3AAF50"
                                                }
                                            }}
                                            value={this.state.AutoResponderStatus}
                                            onChange={this.handleautoResponseSchedule.bind(this)}
                                        />
                                    </div>
                                </div>
                            </span>
                        </h5>

                        {this.state.AutoResponderStatus ? (
                            <div className="AutoAddingHours">{this.createUI()} </div>
                        ) : null}
                        {this.state.AutoResponderStatus ? (
                            <h4>
                                <img
                                    src={greenPlusIcon}
                                    className="img-fluid"
                                    alt="img"
                                    onClick={this.addClick.bind(this)}
                                />
                                Add active autoresponder hours
                            </h4>
                        ) : null}

                        {this.state.AutoResponderStatus ? (
                            <div className="setting-modal-form">
                                <MessageBox
                                    clearMediaUrl={this.handleClearMeiaUrl}
                                    mediaUrl={this.state.mediaUrl}
                                    isMediaUpdate={this.state.isMediaUpdate}
                                    messageText={this.state.content}
                                    textAreaRows={10}
                                    handleChange={this.handleChangeContent}
                                    clearFileInput={this.clearFileInput}
                                    _handleImageChange={this._handleImageChange}
                                    updateMediaObjectGiyf={this.updateMediaObjectGiyf}
                                />
                            </div>
                        ) : null}
                        <div className="form-btn">
                            {!this.state.loading ? (
                                <button
                                    className={`chat-form-btn ${
                                        !this.isDisableSubmitButton() ? "valid" : ""
                                    }`}
                                    disabled={
                                        this.isDisableSubmitButton() &&
                                        this.state.AutoResponderStatus
                                    }
                                    onClick={() => this.handleSumitAutoResponse()}
                                >
                                    Save
                                </button>
                            ) : (
                                <button disabled={true} className="createcontact-btn">
                                    <Spinner size="lg" color={"white"} />
                                </button>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        imageUpload: (bodyData) => dispatch(imageUpload(bodyData)),
        getAutoResponse: () => dispatch(getAutoResponse()),
        setAutoResponseAlert: (status, content, autoResponseSchedule, mediaUrl, isMediaUpdate) =>
            dispatch(
                setAutoResponseAlert(status, content, autoResponseSchedule, mediaUrl, isMediaUpdate)
            )
    };
};
export default connect(null, mapDispatchToProps)(withUser(AccountSettingAutoResponseTab));
