import React from "react";
import classnames from "classnames";

// TODO: Actually fix styles
import "./fix.css";

const InputWrapper = ({ label, className, children }) => {
    return (
        <div
            className={classnames(
                `flex flex-col group/input cursor-pointer relative base`,
                className
            )}
        >
            {label && (
                <label className="text-gray-300 group-[:focus-within]/input:text-gray-800 group-[:focus-within]/input:font-medium text-xs mb-1">
                    {label}
                </label>
            )}
            {children}
        </div>
    );
};

export default InputWrapper;
