import React, { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import InputMask from "react-input-mask";
import moment from "moment";
import "./Registration.css";
import { getPublicSegments, registerContact, getRegisterFormFields } from "../../redux/actions";
import { Spinner } from "../../components/Loader";
import { errorToaster } from "../../utils/loggerUtils";
import { MaterialInput } from "../../components/Input";
import { validateEmail } from "../../utils/validateHelperUtils";
import { getAgeFromDate, getObjectByKeys } from "../../utils/commonUtils";
import { GenderTypes } from "../../config/constants";
import { ShortDate } from "../../utils/validateHelperUtils";
import {
    AGE_GATED_STATUS,
    FormFieldSlug,
    MIN_AGE_STATUS,
    MIN_AGE_TO_JOIN_CLUBCHAT,
    NOT_AVALIABLE
} from "../../constant";
import { clubChatLogo } from "../../assets/svg-icon";
import Config from "../../config";
import { dateTimeFormat, isValidDate } from "../../utils/dateTimeUtils";
const PRIORITIZE_SEGMENTS = ["social", "tennis", "golf"];

const Registration = (props) => {
    let { Code, PhoneNumber } = useMemo(() => {
        let params = new URLSearchParams(props.location.search);
        let Code = params.get("Code");
        let PhoneNumber = params.get("PhoneNumber");
        return { Code, PhoneNumber };
    }, [props.location]);
    const dispatch = useDispatch();
    const [segments, setSegments] = useState([]);
    const [segmentsLoading, setSegmentsLoading] = useState(false);
    const [selectedSegments, setSelectedSegments] = useState({});
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [birthdayError, setBirthdayError] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [birthday, setBirthday] = useState("");
    const [memberId, setMemberId] = useState("");
    const [gender, setGender] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [allowedSlug, setAllowedSlug] = useState([]);
    const [company, setCompany] = useState(null);
    const [ageGatedStatus, setAgeGatedStatus] = useState(false);
    const [isEligibleForAgeGated, setIsEligibleForAgeGated] = useState(false);
    useEffect(() => {
        setSegmentsLoading(true);

        const getOrderedSegments = (segments) => {
            let orderedSegments = [];
            for (let segment of segments) {
                if (PRIORITIZE_SEGMENTS.includes(segment.Title.toLowerCase())) {
                    orderedSegments.unshift(segment);
                } else {
                    orderedSegments.push(segment);
                }
            }
            return orderedSegments;
        };
        dispatch(getRegisterFormFields({ Code }))
            .then((val) => {
                let formFields = val.FormFields;
                let allowedSlugs = formFields.map((val) => {
                    if (val.FormFieldId) {
                        return val.FormFieldId.Slug;
                    }
                    return 0;
                });
                setAllowedSlug(allowedSlugs.filter((val) => val));
            })
            .catch((e) => {
                console.log(e, "  this is error");
            });
        dispatch(getPublicSegments(Code))
            .then((res) => {
                setSegments(getOrderedSegments(res.Segments));
                if (res.Contact && res.Contact.Id) {
                    const { FirstName, LastName } = res.Contact;
                    setCompany(res.Company);
                    if (res.Company && res.Company.AgeGated) {
                        // let meta = JSON.parse(res.Contact.Meta);
                        // if (res.Contact.Meta) {
                        //     if (meta.BirthdayDate) {
                        //         let formattedDate = meta.BirthdayDate.replace(/[-]/g, "/");
                        //         // setBirthday(formattedDate);
                        //         let calculatedAge = getAgeFromDate(formattedDate);
                        //         if (calculatedAge >= MIN_AGE_STATUS) {
                        //             if (res.Company.AgeGated) {
                        //                 setIsEligibleForAgeGated(true)
                        //             } else {
                        //                 setIsEligibleForAgeGated(false)
                        //             }
                        //         }
                        //     } else {
                        //         isEligibleForAgeGated(true)
                        //     }
                        // } else {
                        // console.log("isEligibleForAgeGated", )
                        setIsEligibleForAgeGated(true);
                        // }
                    }
                    if (FirstName === NOT_AVALIABLE) {
                        setFirstName("");
                    } else {
                        setFirstName(FirstName);
                    }
                    setLastName(LastName);
                    if (res.Contact.Email) {
                        setEmail(res.Contact.Email);
                    }
                    if (
                        res.Contact.AgeGatedStatus &&
                        res.Contact.AgeGatedStatus === AGE_GATED_STATUS.Yes &&
                        res.Company.AgeGated
                    ) {
                        setAgeGatedStatus(true);
                    } else {
                        setAgeGatedStatus(false);
                    }
                    if (res.Contact.MemberId) {
                        setMemberId(res.Contact.MemberId);
                    }
                    if (res.Contact.Meta) {
                        try {
                            let meta = JSON.parse(res.Contact.Meta);
                            if (meta.BirthdayDate) {
                                let formattedDate = meta.BirthdayDate.replace(/[-]/g, "/");
                                setBirthday(formattedDate);
                            }
                            if (meta.Gender) {
                                setGender(meta.Gender);
                            }
                        } catch (err) {}
                    }
                    if (res.Contact.Segments && res.Contact.Segments.length) {
                        let selectedSegments = getObjectByKeys(res.Contact.Segments);
                        setSelectedSegments(selectedSegments);
                    }
                }
            })
            .catch((err) => {})
            .finally(() => {
                setSegmentsLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, Code]);
    const handleSegmentSelect = (e, segment) => {
        const currentSelectedSegments = { ...selectedSegments };
        if (!currentSelectedSegments[segment.Id]) {
            currentSelectedSegments[segment.Id] = segment;
        } else {
            delete currentSelectedSegments[segment.Id];
        }
        setSelectedSegments(currentSelectedSegments);
    };

    const handleAgeGatedStatusChange = (event) => {
        const validDate = isValidDate(birthday, dateTimeFormat.appDateFormat);
        if (validDate) {
            let calculatedAge = getAgeFromDate(birthday);

            if (calculatedAge <= 0) {
                setBirthdayError("You must be at least 13 years old to join ClubChat.");
            } else if (calculatedAge < MIN_AGE_TO_JOIN_CLUBCHAT) {
                setBirthdayError("You must be at least 13 years old to join ClubChat.");
            } else if (calculatedAge >= MIN_AGE_STATUS) {
                // if (company.AgeGated) {
                //     console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&")
                //     // setIsEligibleForAgeGated(true)
                //     setBirthdayError("You must be at least 21 years old to join ClubChat.")
                // }
                setBirthdayError(false);
            } else {
                if (company.AgeGated && event.target.checked) {
                    // setIsEligibleForAgeGated(true)
                    setBirthdayError(
                        "You must be at least 21 years old to receive age-gated content."
                    );
                } else {
                    setBirthdayError(false);
                }
                // setIsEligibleForAgeGated(false)
            }
        } else {
            setBirthdayError("Please enter a valid date.");
        }
        setAgeGatedStatus(event.target.checked);
    };

    const handleFirstNameChange = (e) => {
        setFirstNameError(false);
        setFirstName(e.target.value);
    };

    const hanleLastNameChange = (e) => {
        setLastNameError(false);
        setLastName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmailError(false);
        setEmail(e.target.value);
    };

    const handleBirthdayChange = (e) => {
        if (e && e.target && e.target.value) {
            const validDate = isValidDate(e.target.value, dateTimeFormat.appDateFormat);
            console.log(
                "🚀 ~ file: Registration.js ~ line 198 ~ handleBirthdayChange ~ validDate",
                validDate
            );

            if (validDate) {
                let calculatedAge = getAgeFromDate(e.target.value);

                if (calculatedAge <= 0) {
                    setBirthdayError("You must be at least 13 years old to join ClubChat.");
                } else if (calculatedAge < MIN_AGE_TO_JOIN_CLUBCHAT) {
                    setBirthdayError("You must be at least 13 years old to join ClubChat.");
                } else if (calculatedAge >= MIN_AGE_STATUS) {
                    // if (company.AgeGated) {
                    //     // setIsEligibleForAgeGated(true)
                    //     setBirthdayError("You must be at least 21 years old to join ClubChat.")
                    // }
                    setBirthdayError(false);
                } else {
                    if (company.AgeGated && e.target.value) {
                        // setIsEligibleForAgeGated(true)
                        setBirthdayError(
                            "You must be at least 21 years old to receive age-gated content."
                        );
                    } else {
                        setBirthdayError(false);
                    }
                    // setIsEligibleForAgeGated(false)
                }
            } else {
                setBirthdayError("Please enter a valid date.");
            }
        }
        setBirthday(e.target.value);
    };

    const handleMemberIdChange = (e) => {
        setMemberId(e.target.value);
    };

    const handleGenderChange = (e) => {
        let value = e.target.value;
        setGender(value);
        if (!value) {
            document.getElementById("gender").blur();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (
            firstName.trim().length === 0 ||
            lastName.trim().length === 0 ||
            (allowedSlug.includes(FormFieldSlug.email) && email.trim().length === 0) ||
            birthday.trim().length === 0
        ) {
            setFirstNameError(firstName.trim().length === 0);
            setLastNameError(lastName.trim().length === 0);
            setEmailError(allowedSlug.includes(FormFieldSlug.email) && email.trim().length === 0);
            setBirthdayError(
                allowedSlug.includes(FormFieldSlug.birthday) && birthday.trim().length === 0
            );
            return;
        }
        if (
            allowedSlug.includes(FormFieldSlug.birthday) &&
            !moment(birthday, ShortDate).isValid()
        ) {
            setBirthdayError(true);
            return;
        }
        if (allowedSlug.includes(FormFieldSlug.email) && !validateEmail(email)) {
            setEmailError(true);
            return;
        }
        let SegmentIds = Object.keys(selectedSegments).map((id) => Number(id));
        let formattedBirthday = birthday.replace(/[/]/g, "-");
        let bodyData = {
            FirstName: firstName,
            LastName: lastName,

            ...(memberId && { MemberId: memberId }),
            ...(gender && { Gender: Number(gender) }),
            Code,
            SegmentIds
        };
        if (allowedSlug.includes(FormFieldSlug.email)) {
            bodyData.Email = email;
        }
        const validDate = isValidDate(formattedBirthday, dateTimeFormat.formattedBirthday);
        console.log(
            "🚀 ~ file: Registration.js ~ line 281 ~ handleSubmit ~ validDate",
            validDate,
            formattedBirthday
        );
        if (!validDate) {
            setBirthdayError("Please enter a valid date.");
            return;
        }
        // if ((allowedSlug.includes(FormFieldSlug.birthday))) {
        bodyData.BirthdayDate = formattedBirthday;
        // }
        bodyData.AgeGatedStatus =
            ageGatedStatus && isEligibleForAgeGated ? AGE_GATED_STATUS.Yes : AGE_GATED_STATUS.No;
        setIsLoading(true);
        dispatch(registerContact(bodyData))
            .then((res) => {
                setFirstName("");
                setLastName("");
                setSelectedSegments({});
                setEmail("");
                setBirthday("");
                setMemberId("");
                setGender("");
                props.history.replace("/opt-in-registration/success");
            })
            .catch((err) => {
                errorToaster(err.Message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const submitButtonDisabled = () => {
        return (
            firstNameError ||
            lastNameError ||
            (allowedSlug.includes(FormFieldSlug.email) && emailError) ||
            birthdayError
        );
    };

    return (
        <div style={{ height: "100vh", overflowY: "auto", fontFamily: "Montserrat !important" }}>
            <div className="registration-main-container">
                <div className="registration-main-wrapper">
                    <div className="registration-heading">
                        {company && company.Logo && company.Logo.Path && (
                            <div style={{ textAlign: "center" }}>
                                <img
                                    style={{ width: 100, height: 100 }}
                                    src={`${Config.env().BASE_IMG_PATH}/${company.Logo.Path}`}
                                    alt="logo"
                                />
                            </div>
                        )}
                        <h3 className="heading-title">Add your contact</h3>
                        <h3 className="heading-title mb">
                            {PhoneNumber ? `(•••) ••• ${PhoneNumber}` : null}
                        </h3>
                    </div>
                    <div className="registration-form-main formStyle">
                        <h6 className="Confirm-information">Confirm information</h6>
                        <form onSubmit={handleSubmit}>
                            <MaterialInput
                                onChange={handleFirstNameChange}
                                value={firstName}
                                classes={
                                    firstNameError ? "error" : firstName.length > 0 ? "typed" : ""
                                }
                                // label="First Name"
                                isError={firstNameError}
                                errorText="First name is required"
                                placeholder="First Name"
                            />
                            <MaterialInput
                                onChange={hanleLastNameChange}
                                value={lastName}
                                classes={
                                    lastNameError ? "error" : lastName.length > 0 ? "typed" : ""
                                }
                                // label="Last Name"
                                placeholder="Last Name"
                                isError={lastNameError}
                                errorText="Last name is required"
                            />
                            {allowedSlug.includes(FormFieldSlug.email) && (
                                <MaterialInput
                                    onChange={handleEmailChange}
                                    value={email}
                                    classes={`${emailError ? "error" : ""} ${
                                        email.length > 0 ? "typed" : ""
                                    }`}
                                    // label="Email"
                                    placeholder="Email"
                                    isError={emailError}
                                    errorText={
                                        email.trim().length === 0
                                            ? "Email name is required"
                                            : "Please enter valid email"
                                    }
                                />
                            )}

                            {allowedSlug.includes(FormFieldSlug.gender) && (
                                <div className="group">
                                    <select
                                        id="gender"
                                        onChange={handleGenderChange}
                                        value={gender}
                                        className={`${gender ? "typed" : ""}`}
                                        placeholder={"Gender"}
                                    >
                                        {!gender ? <option value="">Select Gender</option> : null}

                                        <option value={GenderTypes.MALE}>Male</option>
                                        <option value={GenderTypes.FEMALE}>Female</option>
                                    </select>
                                    <span className="highlight"></span>
                                    <span className="bar"></span>
                                    {/* <label>Gender</label> */}
                                </div>
                            )}
                            {allowedSlug.includes(FormFieldSlug.memberNo) && (
                                <MaterialInput
                                    onChange={handleMemberIdChange}
                                    value={memberId}
                                    classes={` ${memberId.length > 0 ? "typed" : ""}`}
                                    // label="Member # ( if you have one )"
                                    placeholder="Member #"
                                />
                            )}
                            {
                                // allowedSlug.includes(FormFieldSlug.birthday) &&
                                <div className="group">
                                    <InputMask
                                        maskPlaceholder={ShortDate}
                                        mask="99/99/9999"
                                        className={`registration-birthday  ${
                                            birthdayError ? "error" : ""
                                        }  ${
                                            birthday.length > 0 && birthday !== ShortDate
                                                ? "typed"
                                                : ""
                                        }
                                `}
                                        onChange={handleBirthdayChange}
                                        value={birthday}
                                        autoComplete="off"
                                        placeholder="Date of Birth"
                                    />

                                    <span className="highlight"></span>
                                    <span className="bar"></span>
                                    {/* <label>Date of Birth</label> */}
                                    {birthdayError && (
                                        <p className="error-msg">
                                            {birthday.trim().length === 0
                                                ? "Date of Birth is required"
                                                : birthdayError}
                                        </p>
                                    )}
                                </div>
                            }
                            {isEligibleForAgeGated && (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginTop: "40px"
                                    }}
                                >
                                    <div
                                        className="registration-checkbox-area"
                                        style={{ marginTop: 0 }}
                                    >
                                        <div className="main-checkbox-wrapper">
                                            <div className="checkbox-main">
                                                <div className="checkbox-container">
                                                    <input
                                                        type="checkbox"
                                                        onChange={(e) =>
                                                            handleAgeGatedStatusChange(e)
                                                        }
                                                        checked={ageGatedStatus}
                                                    />
                                                    <span className="tick-area" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <span
                                        htmlFor={"AgeGated"}
                                        style={{
                                            color: "#344C60",
                                            fontFamily: "Montserrat, sans-serif",
                                            fontSize: "15px",
                                            fontWeight: "700"
                                        }}
                                    >
                                        {`I confirm I am ${MIN_AGE_STATUS} or older and would like to receive messaging regarding alcohol, gambling, or tobacco as it relates to the Club.`}
                                    </span>
                                </div>
                            )}
                            <div className="registration-checkbox-area selectSubsClas">
                                <h5 className="title">Select Subscriptions</h5>
                                <p>Tap the segments you want to receive message about.</p>
                                <div
                                    className="main-checkbox-wrapper"
                                    style={segmentsLoading ? { justifyContent: "center" } : {}}
                                >
                                    {segmentsLoading ? (
                                        <Spinner color="green" />
                                    ) : segments.length ? (
                                        <div className="selectRegister">
                                            <ul>
                                                {segments.map((segment, index) => {
                                                    return (
                                                        <li
                                                            key={segment.Id}
                                                            className={
                                                                selectedSegments[segment.Id]
                                                                    ? "active"
                                                                    : null
                                                            }
                                                            onClick={(e) =>
                                                                handleSegmentSelect(e, segment)
                                                            }
                                                        >
                                                            {segment.Title}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    ) : (
                                        <p className="no-data-msg">
                                            No Subscriptions available for this company
                                        </p>
                                    )}
                                </div>
                            </div>

                            <div className="registration-button">
                                <button
                                    style={{
                                        backgroundColor: submitButtonDisabled()
                                            ? "#A8B9C5"
                                            : "#1E3251"
                                    }}
                                    type="submit"
                                    disabled={
                                        firstNameError ||
                                        lastNameError ||
                                        (allowedSlug.includes(FormFieldSlug.email) && emailError) ||
                                        birthdayError
                                    }
                                >
                                    Save & Accept {isLoading && <Spinner />}
                                </button>
                            </div>
                            <p className="registration-terms">
                                By confirming my contact information (with phone number above) and
                                clicking “Save & Accept” , I consent to the{" "}
                                <strong> Terms of Use </strong> and <strong>Privacy Policy</strong>{" "}
                                and to receive personal messages and automated text alerts (which
                                may be marketing in nature) from Digital Range via ClubChat. Consent
                                not a condition of any purchase. Text HELP for help or STOP to
                                cancel anytime. Msg & data rates may apply.
                            </p>
                        </form>
                    </div>
                </div>
                <div style={{ textAlign: "center", marginTop: "15px" }}>
                    <img
                        style={{ width: 100, height: 100, opacity: 0.6 }}
                        src={clubChatLogo}
                        alt="logo"
                    />
                </div>
            </div>
        </div>
    );
};

export default Registration;
