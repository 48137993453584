import React from "react";
import { connect } from "react-redux";
import { getSegments, updateCompanyInvitedMessage } from "../../redux/actions";
import { autoresponderTickIcon } from "../../assets/svg-icon/index";
import AccountSettingTab from "./AccountSettingTab";
import AccountSettingAutoResponseTab from "./AccountSettingAutoResponseTab";
import AccountSettingEmailAlertTab from "./AccountSettingEmailAlertTab";
import ContactAlertModal from "../../components/Contact/ContactAlertModal";
import withUser from "../../hoc/withUser";
import { ReplaceCompanyNameInInvitationMessage, ReplaceCompanyNameVariableInInvitationMessage } from "../../utils/commonUtils";
import AccountWebchat from "./AccountWebchat";
import { ApplicationTypes } from "../../config/constants";

class AccountSettingModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: props.user.Apps.find((app) => app.Id === ApplicationTypes.CLUB_CHAT)
                ? "account"
                : "webchat",
            segments: [],
            emailAlertsAlert: false,
            accountWebchat: false,
            autoResponseAlert: false,
            companyInviteMessage: ``,
            individualContactCardMessage: ``
        };
    }
    componentDidMount() {
        this.props.getSegments().then((res) => {
            this.setState({
                ...this.state,
                segments: res,
                companyInviteMessage: ReplaceCompanyNameInInvitationMessage(
                    this.props.user.Company.RCInviteMessage,
                    this.props.user.Company.Name
                ),
                individualContactCardMessage: ReplaceCompanyNameInInvitationMessage(
                    this.props.user.Company.RCContactCardMessage || ``,
                    this.props.user.Company.Name
                )
            });
        });
    }
    stopPropagation = (e) => {
        e.stopPropagation();
    };
    changeTab = (type) => {
        this.setState({ ...this.state, currentTab: type });
    };
    handleCloseModal = () => {
        this.props.closeModal();
    };
    handleGoBackToSetting = () => {
        this.setState({
            ...this.state,
            emailAlertsAlert: false,
            autoResponseAlert: false,
            accountWebchat: false
        });
    };
    handleEmailAlertConformationModal = () => {
        this.setState({
            ...this.state,
            emailAlertsAlert: true
        });
    };
    handleAccountWebchatModal = () => {
        this.setState({
            ...this.state,
            accountWebchat: true
        });
    };
    handleAutoResponseConformationModal = () => {
        this.setState({
            ...this.state,
            autoResponseAlert: true
        });
    };
    updateCompanyInviteMessage = (value) => {
        // console.log(value);
        this.setState({ companyInviteMessage: value });
    };
    updateContactCardMessage = (value) => {
        this.setState({ individualContactCardMessage: value });
    };
    submitContactCardMessage = () => {
        const { individualContactCardMessage } = this.state;
        let bodyData = {
            Id: this.props.user.Company.Id,
            RCContactCardMessage: individualContactCardMessage
        };
        this.props.updateCompanyInvitedMessage(bodyData);
    };
    submitCompanyInvitedMessage = () => {
        const { companyInviteMessage } = this.state;
        let bodyData = {
            Id: this.props.user.Company.Id,
            RCInviteMessage: ReplaceCompanyNameVariableInInvitationMessage(companyInviteMessage, this.props.user.Company.Name)
        };
        this.props.updateCompanyInvitedMessage(bodyData);
    };
    ShowWebChatTab = () => {
        return (
            this.props.user.Type === 3 &&
            this.props.user &&
            this.props.user.Apps &&
            this.props.user.Company &&
            Boolean(Object.keys(this.props.user.Apps.filter((item) => item.Id === 4)).length) &&
            this.props.user.Company.WebChatAccess === 1 &&
            this.props.user.ShowWebChatTab
        );
    };

    isClubChatAccessAccessAllowed = () => {
        let clubChat =
            this.props.user &&
            this.props.user.Apps.find((app) => app.Id === ApplicationTypes.CLUB_CHAT);
        if (clubChat) {
            return true;
        }
        return false;
    };

    render() {
        let content = "";
        if (this.state.emailAlertsAlert) {
            content = `Email Alert Is Update`;
        } else if (this.state.autoResponseAlert) {
            content = `Auto Response Alert Is Update`;
        } else if (this.state.accountWebchat) {
            content = `Widget Settings Updated`;
        }
        return (
            <div className="chats-autoresponder-modal" onClick={(e) => this.stopPropagation(e)}>
                {!this.state.emailAlertsAlert &&
                !this.state.autoResponseAlert &&
                !this.state.accountWebchat ? (
                    <div className="modal-content setting-modals">
                        <div className="modal-header">
                            <span onClick={() => this.handleCloseModal()} className="close">
                                &times;
                            </span>
                            <h3>Settings</h3>
                        </div>
                        <div className="setting-tab">
                            <div className="row">
                                {this.isClubChatAccessAccessAllowed() && (
                                    <>
                                        {/* <div className={`${this.ShowWebChatTab() ? 'w-3' : 'w-4'} ${this.state.currentTab === 'account' ? "active" : ""}`} onClick={() => this.changeTab('account')}> */}
                                        <div
                                            className={`w-3 ${
                                                this.state.currentTab === "account" ? "active" : ""
                                            }`}
                                            onClick={() => this.changeTab("account")}
                                        >
                                            <div className="tab-heading">
                                                <h4>Account</h4>
                                            </div>
                                        </div>
                                        <div
                                            className={`w-3 ${
                                                this.state.currentTab === "autoResponse"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() => this.changeTab("autoResponse")}
                                        >
                                            <div className="tab-heading">
                                                <h4>Autoresponder</h4>
                                            </div>
                                        </div>
                                        <div
                                            className={`w-3 ${
                                                this.state.currentTab === "emailAlert"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() => this.changeTab("emailAlert")}
                                        >
                                            <div className="tab-heading">
                                                <h4>Email Alerts</h4>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {this.ShowWebChatTab() ? (
                                    <div
                                        className={`w-3 ${
                                            this.state.currentTab === "webchat" ? "active" : ""
                                        }`}
                                        onClick={() => this.changeTab("webchat")}
                                    >
                                        <div className="tab-heading">
                                            <h4>WebChat</h4>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {this.state.currentTab === "account" ? (
                            <AccountSettingTab
                                submitCompanyInvitedMessage={this.submitCompanyInvitedMessage}
                                companyInviteMessage={this.state.companyInviteMessage}
                                updateCompanyInviteMessage={this.updateCompanyInviteMessage}
                                submitContactCardMessage={this.submitContactCardMessage}
                                contactCardMessage={this.state.individualContactCardMessage}
                                updateContactCardMessage={this.updateContactCardMessage}
                                isAdmin={this.props.isAdmin}
                                user={this.props.user}
                                segments={this.state.segments}
                            />
                        ) : null}
                        {this.state.currentTab === "autoResponse" ? (
                            <AccountSettingAutoResponseTab
                                handleAutoResponseConformation={
                                    this.handleAutoResponseConformationModal
                                }
                            />
                        ) : null}
                        {this.state.currentTab === "emailAlert" ? (
                            <AccountSettingEmailAlertTab
                                handleEmailAlertConformation={
                                    this.handleEmailAlertConformationModal
                                }
                            />
                        ) : null}
                        {this.state.currentTab === "webchat" ? (
                            <AccountWebchat
                                handleAccountWebchatModal={this.handleAccountWebchatModal}
                                loggedInUser={this.props.user}
                            />
                        ) : null}
                    </div>
                ) : (
                    <ContactAlertModal
                        color={"green"} // todo
                        handleClose={this.handleGoBackToSetting}
                        icon={autoresponderTickIcon}
                        content={content}
                        closeButtonContent="Go Back To Settings"
                    />
                )}
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getSegments: () => dispatch(getSegments()),
        updateCompanyInvitedMessage: (bodyData) => dispatch(updateCompanyInvitedMessage(bodyData))
    };
};
const mapStateToProps = (state) => {
    return {
        isAdmin: state.auth.isAdmin,
        user: state.auth.user
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withUser(AccountSettingModal));
