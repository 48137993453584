import Config from "../config";
import { handleFetchError } from "./errorHandlerUtils";

export const fetchUtil = (data) => {
    const { url, method = "GET", token = null, body = null, signal = null } = data;
    let headers = { "Content-Type": "application/json" };
    if (token) {
        headers = { ...headers, Authorization: token };
    }
    return fetch(`${Config.env().API_URL}${url}`, {
        method,
        headers,
        body,
        credentials: "include",
        ...(signal && { signal })
    }).then(handleFetchError);
};

export const ImageUtil = (data) => {
    const { url, method = "GET", token = null, body = null } = data;
    let headers = {};
    if (token) {
        headers = { ...headers, Authorization: token };
    }
    return fetch(`${Config.env().API_URL}${url}`, {
        method,
        headers,
        body
    }).then(handleFetchError);
};
