import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Button, Input, Statistic, Table, notification } from "antd";
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, MessageOutlined } from "@ant-design/icons";

import ExportModal from "../ExportModal";

import Header from "../../../../../components/atoms/Typography/Header";
import { useGetCampaignContacts } from "../../../../../hooks/api/campaigns";
import { ContactStatus } from "../../../../../config/constants";

import ContactMessageModalContent from "../../../../AllContacts/ContactMessageModalContent";
import { isEmpty } from "lodash";

const SMSCampaignDetails = ({campaign, segments}) => {
  const history = useHistory();
  const [notify] = notification.useNotification();
  const [showExportModal, setShowExportModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedContactsIds, setSelectedContactsIds] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: false
  })
  const {data, isFetching} = useGetCampaignContacts(
    campaign?.Id,
    {
    Limit: 10,
    Page: pagination.current
    }, {
      onSuccess: ({TotalCampaignContacts}) => {
        setPagination(prev => ({...prev, total: TotalCampaignContacts}))
      }
    });

  const selectedContacts = useMemo(() => 
    data?.CampaignContacts
    .map(({Contact}) => Contact)
    .filter(({Id}) => selectedContactsIds.includes(Id)),
    [data, selectedContactsIds])
  
  const columns = [{
    title: "Chat",
    dataIndex: "Status",
    key: "Chat",
    render: (_, { Contact }) => (
      <Button
        shape="circle"
        icon={<MessageOutlined style={{verticalAlign: 0}} />}
        onClick={() => {
          Contact.OptStatus === ContactStatus.OPT_IN
            ? history.push("/dashboard/inbox/all-messages/", { contactId: Contact.Id })
            : history.push("/dashboard/inbox/opt-out-messages/", { contactId: Contact.Id });
        }}
      />
    )
  }, {
    title: "Name",
    key: "Name",
    dataIndex: "Id",
    render: (_, { Contact }) => Contact.Name, 
  }, {
    title: "Phone Number",
    key: "Phone",
    render: (_, { Contact }) => Contact.PhoneNumber,
  }, {
    title: "Confirmed",
    key: "Confirmation",
    render: (_, { Confirmation }) => {
      switch(Confirmation) {
        case 1:
          return <CheckCircleOutlined className="text-main" />;
        
        case 2:
          return <CloseCircleOutlined className="text-[#c54646]" />;

        default:
          return <ClockCircleOutlined />; 
      }
    },
  }]

  const onSelectChange = (contacts) => {
    setSelectedContactsIds(contacts);
  };

  const rowSelection = {
    selectedContactsIds,
    onChange: onSelectChange,
  };

  return (
    <div className="flex flex-col gap-5">
      <div className="flex justify-between">
        <div className="flex flex-col gap-2">
          <Header>Campaign Reporting</Header>
          <span className="text-gray-400">
            Sent
            {moment(campaign?.SentOn).format('MM/DD/YYYY @h:ma zz')}
          </span>
        </div>
        <Input.Search
          className="w-1/3"
          size="large"
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder="Search for recipients"
        />
      </div>

      {!!campaign?.ConfirmationRequest && (
        <div className="flex justify-around">
          <Statistic
            title="Recipients"
            value={campaign?.TotalContacts}
          />
          <Statistic
            title="Positive Replies"
            value={campaign?.TotalRepliesWithYes} 
            valueStyle={{ color: '#3aaf50' }}
          />
          <Statistic
            title="Negative Replies"
            value={campaign?.TotalRepliesWithNo}
            valueStyle={{ color: '#c54646' }}
          />
        </div>
      )}

      <Table
        columns={columns}
        dataSource={data?.CampaignContacts.map(c => ({...c, key: c.Contact.Id}))}
        loading={isFetching}
        rowSelection={rowSelection}
        onChange={pagination => setPagination(prev => ({...prev, ...pagination}))}
        pagination={pagination}
      />
      
      <div className="flex justify-center gap-2">
        <Button onClick={() => setShowExportModal(true)} size="large">
          Export Responses
        </Button>
        <Button
          className="bg-[#3aaf50] text-white"
          disabled={isEmpty(selectedContacts)}
          onClick={() => setShowMessageModal(true)}
          size="large"
        >
          Message
        </Button>
      </div>

      {showExportModal && (
        <ExportModal
          exportLeadSuccessCallBack={() => setShowExportModal(false)}
          selectedCampain={campaign}
        />
      )}
      {showMessageModal && (
        <ContactMessageModalContent
          checkedContacts={selectedContacts}
          handleSendMessageModel={() => setShowMessageModal(false)}
          showSendMessageAlert={() => notify('Message sent')}
          segments={segments}
        />
      )}
    </div>
  )
}



const mapStateToProps = (state) => {
  return {
      segments: state.contacts.segments.data,
  };
};

export default connect(mapStateToProps)(SMSCampaignDetails);
