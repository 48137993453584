import withUser from "../../hoc/withUser";
import { connect } from "react-redux";
import React from "react";
import { Spinner } from "../../components/Loader";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import { setEmailAlert, getEmailAlertData } from "../../redux/actions";
import "react-multi-email/style.css";
class AccountSettingEmailAlertTab extends React.Component {
    constructor() {
        super();
        this.state = {
            emails: [],
            emailAlertTime: 5,
            status: false,
            loading: false,
            fetching: true
        };
    }
    componentDidMount() {
        this.props.getEmailAlertData().then((res) => {
            this.setState({
                status: res.status === 1 ? true : false,
                emailAlertTime: res.emailAlertTime,
                emails: res.emailAddresses.emails,
                fetching: false
            });
        });
    }
    handleUpdateEmailAlertData = () => {
        this.setState({ ...this.state, loading: true });
        let EmailStatusAlert = this.state.status ? 1 : 0;
        this.props
            .setEmailAlert(EmailStatusAlert, this.state.emails, Number(this.state.emailAlertTime))
            .then((res) => {
                this.setState({ ...this.state, loading: false });
                this.props.handleEmailAlertConformation();
            })
            .finally(() => {
                this.setState({ ...this.state, loading: false });
            });
    };
    handleEmailAlertTime(event) {
        this.setState({ emailAlertTime: event.target.value });
    }
    handleEmailAlertStatus(event) {
        this.setState({ ...this.state, status: !this.state.status });
    }
    render() {
        const { emails, loading, fetching } = this.state;
        return (
            <div className="setting-tab-content-emailalerts">
                <h5>Receive alerts when you have unread messages in your inbox </h5>
                <p className="no-select">
                    <input
                        type="checkbox"
                        checked={this.state.status}
                        value={this.state.status}
                        onChange={this.handleEmailAlertStatus.bind(this)}
                        id="active-label"
                        className="active-checkbox"
                    />
                    <label htmlFor="active-label"></label>
                    <span>Send an email alert for unread messages after</span>
                    <select
                        onChange={this.handleEmailAlertTime.bind(this)}
                        value={this.state.emailAlertTime}
                    >
                        <option>5</option>
                        <option>15</option>
                        <option>30</option>
                        <option>60</option>
                    </select>
                    <span>minutes</span>
                </p>
                {fetching ? (
                    <div style={{ textAlign: "center" }}>
                        {" "}
                        <Spinner size="xxl" color="green" />{" "}
                    </div>
                ) : (
                    <div>
                        <ReactMultiEmail
                            placeholder="Enter email address(es) upto 5"
                            emails={emails}
                            onChange={(_emails) => {
                                this.setState({ emails: _emails });
                            }}
                            validateEmail={(email) => {
                                return isEmail(email); // return boolean
                            }}
                            getLabel={(email, index, removeEmail) => {
                                return (
                                    <div data-tag key={index}>
                                        {email}
                                        <span data-tag-handle onClick={() => removeEmail(index)}>
                                            ×
                                        </span>
                                    </div>
                                );
                            }}
                        />
                        <br />
                    </div>
                )}

                <div className="row">
                    <div className="w-10">
                        <div className="alert-message-footer">
                            <p>
                                Email alert for unread messages after {this.state.emailAlertTime}{" "}
                                minutes is on.
                            </p>
                        </div>
                    </div>
                    <div className="w-2">
                        {!loading ? (
                            <button
                                className="chat-form-btn"
                                onClick={() => {
                                    this.handleUpdateEmailAlertData();
                                }}
                            >
                                Save
                            </button>
                        ) : (
                            <button disabled={true} className="createcontact-btn">
                                <Spinner size="lg" color={"white"} />
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setEmailAlert: (firstName, lastName, phone, segmentIds) =>
            dispatch(setEmailAlert(firstName, lastName, phone, segmentIds)),
        getEmailAlertData: () => dispatch(getEmailAlertData())
    };
};
export default connect(null, mapDispatchToProps)(withUser(AccountSettingEmailAlertTab));
