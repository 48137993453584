import React from "react";
import anchorme from "anchorme";

import { errorToaster } from "../../utils/loggerUtils";
class Link extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputFocus: false,
            textAreaRows: 1,
            loadingGiyf: false,
            giyfData: [],
            showGiyf: false,
            offset: 0,
            gifSearchValue: "",
            suggestions: []
        };
    }
    addLink = () => {
        const link = anchorme(this.inputBox.value);
        if (link === this.inputBox.value) {
            errorToaster("Invalid Link");
            return;
        }
        this.props.addLinkInText(`${this.inputBox.value}`);
        this.inputBox.value = "";
    };
    render() {
        return (
            <div
                className="newcampaign-inserturl"
                style={{ display: `${this.props.showLinkBox ? "block" : "none"}` }}
            >
                <div className="row">
                    <div className="w-10">
                        <div className="inserturl-input">
                            <input type="text" ref={(ref) => (this.inputBox = ref)} />
                        </div>
                    </div>
                    <div className="w-2">
                        <div className="inserturl-button text-center cursor-pointer">
                            <button onClick={this.addLink} className="newcampaign-form-btn">
                                Add URL
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Link;
