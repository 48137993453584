import React from "react";

import "../../App.css";
import { ClearWhiteIcon } from "../../assets/png-icon";

const TextSmsChip = ({ onCancelClick, title, classes = "", icon }) => {
    return (
        <div className={`selected-contacts ${classes}`}>
            <div className="selected-contacts-cross" onClick={onCancelClick}>
                <img src={ClearWhiteIcon} alt="clear-black" />
            </div>
            <div className="selected-contacts-text text-truncate" title={title}>
                {icon && (
                    <span className="icon">
                        <img src={icon} alt="segment" />
                    </span>
                )}{" "}
                {title}
            </div>
        </div>
    );
};

export default TextSmsChip;
