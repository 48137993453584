import { combineReducers } from "redux";

import { AUTH_FAIL, SIGN_OUT } from "../actions";
import authReducer from "./authReducer";
import ssoAuthReducer from "./ssoAuthReducer";
import contactsReducer from "./contactsReducer";
import campaignReducer from "./campaignReducer";
import chatReducer from "./chatReducer";
import socketReducer from "./socketReducer";

const appReducer = combineReducers({
    socketConnected: socketReducer,
    auth: authReducer,
    ssoAuth: ssoAuthReducer,
    contacts: contactsReducer,
    campaigns: campaignReducer,
    chat: chatReducer
});

const rootReducer = (state, action) => {
    if (action.type === AUTH_FAIL || action.type === SIGN_OUT) {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
