import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { getMe, changeAdminView } from "../../redux/actions";
import { LoadingPage } from "../../components/LodingPage";

class VerifyPage extends React.Component {
    componentDidMount() {
        const token = this.props.match.params.token;
        let params = new URLSearchParams(this.props.location.search);
        let isAdmin = params.get("isAdmin");
        let url = params.get("url");

        localStorage.RCToken = token;
        this.props
            .getMe()
            .then((res) => {
                if (isAdmin) {
                    this.props.changeAdminView(Boolean(isAdmin), url);
                }
            })
            .catch((err) => {});
    }

    render() {
        if (this.props.isAuthenticated && this.props.userToken === this.props.match.params.token) {
            return <Redirect replce to="/dashboard" />;
        }
        return <LoadingPage loading={this.props.fetching} error={this.props.error} />;
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.auth.error,
        fetching: state.auth.fetching,
        isAuthenticated: state.auth.isAuthenticated,
        userToken: state.auth.user.RC_Token
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMe: () => dispatch(getMe()),
        changeAdminView: (isAdmin, url) => dispatch(changeAdminView(isAdmin, url))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPage);
