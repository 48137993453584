import React from "react";
import { Picker } from "emoji-mart";
import "../../../App.css";
import Reactlink from "../../../assets/svg-icon/modal-svgs/link.svg";
import Reactimage from "../../../assets/svg-icon/modal-svgs/image.svg";
import Reactsmile from "../../../assets/svg-icon/modal-svgs/smile.svg";
import Reactgif from "../../../assets/svg-icon/modal-svgs/gif.svg";
import Gallery from "../../../assets/images/gallery.png";
import Cross from "../../../assets/images/close.svg";
import { Spinner } from "../../../components/Loader";
import { errorToaster } from "../../../utils/loggerUtils";
import Giyf from "../../Giyf/Giyf";
import Link from "../../Link/Link";
import { maximumMediaSize } from "../../../config/constants/MaximumMediaSize";

const MAX_COUNT = 1000;
class AutoReplyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputFocus: false,
            messageText: this.props.messageText || "",
            textAreaRows: 1,
            showEmoji: false,
            uploadedMedia: null,
            showGiyf: false,
            showLinkBox: false
        };
        this.emojiIconRef = React.createRef();
        this.emojiRef = React.createRef();
        this.linkRef = React.createRef();
        this.linkIconRef = React.createRef();
        this.giyfIconRef = React.createRef();
        this.giyfRef = React.createRef();
    }
    openLinkDialog = () => {
        this.setState({
            showLinkBox: !this.state.showLinkBox
        });
        this.bindDocumentListener();
    };
    handleEmojiClick = () => {
        this.setState({ showEmoji: !this.state.showEmoji });
        this.bindDocumentListener();
    };
    handleEmojiSelect = (emoji) => {
        let { messageText } = this.state;
        if (messageText.length + 1 < MAX_COUNT) {
            messageText += `${emoji.native}`;
            this.setState({ messageText });
        }
    };
    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            messageText: e.target.value
        });
    };
    handleChangeFromOptions = (e) => {
        let { messageText } = this.state;

        messageText = e.target.value;
        this.setState({ messageText });
    };
    bindDocumentListener = () => {
        document.getElementById("root").addEventListener("click", this.documentClick, false);
    };
    documentClick = (e) => {
        if (
            this.emojiIconRef.current &&
            this.emojiRef.current &&
            !this.emojiRef.current.contains(e.target) &&
            !this.emojiIconRef.current.contains(e.target)
        ) {
            this.setState({ showEmoji: false });
        }
        if (
            this.linkRef.current &&
            this.linkIconRef.current &&
            !this.linkIconRef.current.contains(e.target) &&
            !this.linkRef.current.contains(e.target)
        ) {
            this.setState({ showLinkBox: false });
        }
        if (
            this.giyfIconRef.current &&
            !this.giyfIconRef.current.contains(e.target) &&
            this.giyfRef.current &&
            !this.giyfRef.current.contains(e.target)
        ) {
            this.setState({ showGiyf: false });
        }
        this.setState({ inputFocus: false });
    };
    unbindDocumentListener = () => {
        document.getElementById("root").removeEventListener("click", this.documentClick, false);
    };
    openImageDialog = () => {
        this.inputFileReference.click();
    };
    _handleImageChange = (e) => {
        e.preventDefault();
        if (e.target.files[0].size / 1000000 > maximumMediaSize) {
            errorToaster("file size shouldn't exceed 1MB");
            return;
        }
        this.setState({ loader: true });
        const Obj = {
            Obj: e.target.files[0],
            type: "image",
            name: e.target.files[0].name
        };
        const { updateAutoReplyMediaObject } = this.props;
        updateAutoReplyMediaObject(Obj);
        this.setState({ loader: false });
    };
    clearFileInput = () => {
        this.inputFileReference.value = null;
        const { updateAutoReplyMediaObject } = this.props;
        updateAutoReplyMediaObject(null);
    };
    openGiffDialog = () => {
        this.setState({
            showGiyf: !this.state.showGiyf
        });
        this.bindDocumentListener();
    };
    addLinkInTextMessage = (link) => {
        let { messageText } = this.state;
        messageText += `${link}`;
        this.setState({
            messageText: messageText,
            showLinkBox: false
        });
    };
    render() {
        const { messageText } = this.state;

        if (this.props.clearImageFile) {
            this.inputFileReference.value = null;
            this.props.clearImageFileReset();
        }
        return (
            <div className={`${this.props.type}-modal-wrapper custom-modal`}>
                {/************************* Set auto-reply message starts here *************************/}

                <div className="modal-header-Auto-Reply">
                    <h3 className="modal-title">Set auto-reply message</h3>
                    <button
                        onClick={() => {
                            this.props.onClose();
                        }}
                        className="close"
                    >
                        &times;
                    </button>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col">
                            <div className="form-group custom-textarea">
                                <textarea
                                    maxLength="MAX_COUNT"
                                    placeholder="Type your message here…"
                                    value={messageText}
                                    onChange={this.handleChange}
                                    className="form-control"
                                    rows="8"
                                    id="comment"
                                    name="text"
                                ></textarea>
                                <div ref={this.giyfRef}>
                                    <Giyf
                                        showGiyf={this.state.showGiyf}
                                        getGiyf={this.props.getGiyf}
                                        getSuggestionGiyf={this.props.getSuggestionGiyf}
                                        updateMediaObj={this.props.updateAutoReplyMediaObject}
                                        inputFileReference={this.inputFileReference}
                                        openGiffDialog={this.openGiffDialog}
                                        class={"giff-modal-autoreply"}
                                    />
                                </div>
                                <div
                                    className="image-box-textarea"
                                    style={{
                                        display: this.props.autoReplyMediaObject ? "block" : "none"
                                    }}
                                >
                                    {
                                        <div className="image-box-value">
                                            <img
                                                src={Gallery}
                                                style={{ width: "23px" }}
                                                className="img-fluid"
                                                alt="React Icon 1"
                                            />
                                            <span
                                                style={{ marginLeft: "10px", position: "absolute" }}
                                            >
                                                {this.state.loader ? (
                                                    <Spinner size="lg" color="grey" />
                                                ) : this.props.autoReplyMediaObject !== null ? (
                                                    this.props.autoReplyMediaObject.name.length >
                                                    20 ? (
                                                        `${this.props.autoReplyMediaObject.name.substr(
                                                            1,
                                                            15
                                                        )}...`
                                                    ) : (
                                                        this.props.autoReplyMediaObject.name
                                                    )
                                                ) : (
                                                    ``
                                                )}
                                            </span>
                                            <img
                                                style={{
                                                    width: "10px",
                                                    float: "right",
                                                    marginRight: "5px"
                                                }}
                                                src={Cross}
                                                onClick={this.clearFileInput}
                                                className="img-fluid"
                                                alt="React Icon 1"
                                            />
                                        </div>
                                    }
                                </div>
                                <label className="social-bar">
                                    <img
                                        src={Reactlink}
                                        ref={this.linkIconRef}
                                        onClick={this.openLinkDialog}
                                        className="img-fluid"
                                        alt="React Icon 1"
                                    />
                                    <img
                                        onClick={this.openImageDialog}
                                        src={Reactimage}
                                        className="img-fluid"
                                        alt="React Icon 1"
                                    />
                                    <img
                                        onClick={this.handleEmojiClick}
                                        src={Reactsmile}
                                        className="img-fluid"
                                        ref={this.emojiIconRef}
                                        alt="React Icon 1"
                                    />
                                    <img
                                        onClick={this.openGiffDialog}
                                        src={Reactgif}
                                        className="img-fluid"
                                        ref={this.giyfIconRef}
                                        alt="React Icon 1"
                                    />
                                    <div className="smsCounter">
                                        <span>{this.state.messageText.length}/MAX_COUNT</span>
                                        <span>
                                            {this.props.autoReplyMediaObject ? "mms" : "sms"}
                                        </span>
                                    </div>
                                </label>
                                <div
                                    style={{ display: this.state.showEmoji ? "block" : "none" }}
                                    ref={this.emojiRef}
                                >
                                    {this.state.showEmoji && (
                                        <div>
                                            <Picker
                                                set="apple"
                                                emoji=""
                                                title=""
                                                showSkinTones={false}
                                                onSelect={this.handleEmojiSelect}
                                                style={{
                                                    position: "absolute",
                                                    left: 0,
                                                    bottom: "30px"
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div ref={this.linkRef}>
                                    <Link
                                        showLinkBox={this.state.showLinkBox}
                                        addLinkInText={this.addLinkInTextMessage}
                                    />
                                </div>
                                <input
                                    ref={(ref) => (this.inputFileReference = ref)}
                                    onChange={(e) => this._handleImageChange(e)}
                                    type="file"
                                    id="my_file"
                                    style={{ display: "none" }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-left">
                            <label>Or choose a standard response</label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-9">
                            <div className="form-group">
                                <select
                                    className="form-control"
                                    id="sel1"
                                    onChange={this.handleChangeFromOptions}
                                >
                                    <option>
                                        Thanks for the confirmation, how many people are in your
                                        party?
                                    </option>
                                    <option>
                                        Thanks for the confirmation, how many people are in your
                                        party?
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <button
                                    onClick={() => {
                                        this.props.onConfirm(messageText);
                                    }}
                                    className="custom-btn"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/************************* Set auto-reply message ends here *************************/}

                {/* 
            <div><h3 style={{float:"left",display:"inline-block"}}>{this.props.title}</h3> <div onClick={() =>{this.props.onClose()}} style={{float:"right",display:"inline-block"}}>Close</div></div>
            <button onClick={this.handleEmojiClick}>
                                    <img src={EmojiIcon} alt="emoji" />
            </button>
            {showEmoji && (
                                    <div ref={this.emojiRef}>
                                        <Picker
                                            set="apple"
                                            emoji=""
                                            title=""
                                            showSkinTones={false}
                                            onSelect={this.handleEmojiSelect}
                                            style={{
                                                position: "absolute",
                                                right: 0,
                                                bottom: "30px"
                                            }}
                                        />
                                    </div>
                                )}
            <div className="btn-wrapper">
                <textarea 
                   
                />
                <div className={`${this.props.type}-modal-description`}>Or Choose a standard response</div>
                <select id="cars">
                    <option value="volvo">Volvo</option>
                    <option value="saab">Saab</option>
                    <option value="opel">Opel</option>
                    <option value="audi">Audi</option>
                </select>
            </div>
            <button onClick={() =>{this.props.onConfirm(messageText)}}>
                Save
            </button> */}
            </div>
        );
    }
}

export default AutoReplyModal;
