import React from "react";

import { Select as AntSelect } from "antd";

import noop from "lodash/noop";
import InputWrapper from "./Wrapper";

const Select = ({
    value,
    options,
    label,
    placeholder,
    onChange = noop,
    multiple = false,
    freeInput = false,
    className,
    dropdownHeader,
    dropdownFooter
}) => {
    const inputMode = freeInput ? "tags" : "multiple";
    return (
        <InputWrapper label={label} className={className}>
            <AntSelect
                size="large"
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                dropdownRender={(menu) => (
                    <>
                        {dropdownHeader}
                        {menu}
                        {dropdownFooter}
                    </>
                )}
                options={options}
                mode={multiple ? inputMode : null}
                filterOption={(search, { label }) => new RegExp(`.*${search}.*`, 'gi').test(label)}
            />
        </InputWrapper>
    );
};

export default Select;
