import React, { useState, useMemo } from "react";
import classNames from "classnames";
import noop from "lodash/noop";
import isArray from "lodash/isArray";

import { Button } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import Card from "./";

const DataItem = ({ label, value, props }) => (
  <div
    {...props}
    className={classNames(
      props?.className,
      "flex flex-col text-[#344c60] font-['Montserrat']"
    )}
  >
    <span>{label}</span>
    <span className="font-bold">{value}</span>
  </div>
);

const ActionCard = ({
  title,
  data,
  isCompleted,
  hideActions = false,
  onSave = noop,
  onCancel = noop,
  children
}) => {
  const [isEditMode, setEditMode] = useState(false);

  const Icon = useMemo(() => {
    return isCompleted
      ? () => (
        <div className="h-[20px] w-[20px] rounded-full border-2 border-main bg-main flex justify-center items-center">
          <CheckOutlined className="text-white" />
        </div>
        )
      : () => <div className="h-[20px] w-[20px] rounded-full border-2 border-main bg-white" />
  }, [isCompleted]);

  return (
    <Card
      prefix={<Icon />}
      title={title}
      postfix={
        <>
          {!isEditMode && (
            <Button
              type="text"
              className="justify-self-end ml-auto text-[#344c60] font-medium"
              onClick={() => setEditMode(true)}
            >
              Edit
            </Button>
          )}
        </>
      }
    >
      <div className="flex flex-wrap gap-5 mx-10">
        {isEditMode && (
          <>
            <div className="w-full">{children}</div>
            {!hideActions && (
              <div className="flex gap-4 w-full">
                <Button
                  type="primary"
                  className="bg-[#344c60] text-white"
                  onClick={() => {
                    onSave();
                    setEditMode(false);
                  }}
                >
                  Save
                </Button>
                <Button
                  className="text-[#344c60] hover:border-[#344c60]"
                  onClick={() => {
                    onCancel();
                    setEditMode(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </>
        )}
        {
          !isEditMode && isArray(data) && data.map(item => <DataItem key={item.label} {...item}/>)
        }
        {
          !isEditMode && !isArray(data) && (<span className="font-bold text-[#344c60] font-['Montserrat']">{data}</span>)
        }
      </div>
    </Card>
  );
};

export default ActionCard;
