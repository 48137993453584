import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";

import { fetchUtil } from "../../../utils/fetchUtils";
import { getToken } from "../../../utils/tokenUtils";
import { appendQueryParams } from "../../../utils/urlUtils";

import { toast } from "react-toastify";

import isFunction from "lodash/isFunction";

const getCampaign = async (id) => {
  const token = getToken();
  const res =  fetchUtil({
      url: `/campaign/${id}`,
      token
  })
  return res
};

export const useGetCampaign = (id = false, options = {}) => {
  return useQuery({
    queryKey: ["campaign", id],
    queryFn: async () => await getCampaign(id),
    enabled: !!id,
    refetchOnWindowFocus: false,
    ...options,
  });
}
const getSentCampaigns = async (params = {}) => {
  const token = getToken();
  return fetchUtil({
      url: appendQueryParams("/report/campaigns", params),
      token
  })
};

export const useGetSentCampaigns = (params, options) => {
  return useQuery({
    queryKey: ["campaigns", "sent", params],
    queryFn: () => getSentCampaigns(params),
    refetchInterval: 10000,
    ...options,
  })
}

const getCampaignContacts = (id, params = {}) => {
  const token = getToken();
  return fetchUtil({
    url: appendQueryParams(`/campaign_contacts/${id}`, params),
    token
  })
}

export const useGetCampaignContacts = (id, params, options) => {
  return useQuery({
    queryKey: ["campaign", "contacts", id, params],
    queryFn: () => getCampaignContacts(id, params),
    enabled: !!id,
    ...options,
  })
}

const getScheduledCampaigns = async (params = {}) => {
  const token = getToken();
  return fetchUtil({
      url: appendQueryParams("/scheduled/campaigns", params),
      token
  })
};

export const useGetScheduledCampaigns = (params, options) => {
  return useQuery({
    queryKey: ["campaigns", "scheduled", params],
    queryFn: () => getScheduledCampaigns(params),
    refetchInterval: 10000,
    ...options,
  })
}

export const usePrefetchCampaigns = () => {
  const queryClient = useQueryClient();
  const params = {
    Limit: 5,
    Page: 1,
    Title: null
  };
  return () => {
    queryClient.prefetchQuery({
      queryKey: ["campaigns", "sent", params],
      queryFn: () => getSentCampaigns(params),
    });

    queryClient.prefetchQuery({
      queryKey: ["campaigns", "scheduled", params],
      queryFn: () => getScheduledCampaigns(params),
    });
  }
} 


const cancelCampaign = async (id) => {
  const token = getToken();
  return fetchUtil({
      url: `/scheduled/campaign/${id}`,
      method: "PUT",
      token
  })
};

export const useCancelCampaign = (options = {}) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
      ...options,
      mutationFn: cancelCampaign,
      onSuccess: (res) => {
          queryClient.invalidateQueries(["campaigns", "scheduled"]);
          if(isFunction(options.onSuccess)) options.onSuccess(res);
      },
      onError: (res) => {
          toast("Something went wrong", { containerId: "error" });
          if(isFunction(options.onError)) options.onError(res);
      },
  });

  return mutate;
};