import { useMutation, useQuery } from "@tanstack/react-query";

import { fetchUtil } from "../../../utils/fetchUtils";
import { getToken } from "../../../utils/tokenUtils";

import { toast } from "react-toastify";

const scheduleCampaign = async ({ data }) => {
    const token = getToken();
    return await fetchUtil({
        method: "POST",
        url: `/email/send`,
        body: JSON.stringify(data),
        token
    });
};

const updateCampaign = async ({ id, data }) => {
    const token = getToken();
    return await fetchUtil({
        method: "PUT",
        url: `/email/${id}`,
        body: JSON.stringify(data),
        token
    });
};

export const useUpsertCampaign = (id, data, options = {}) => {
    const { mutate, isLoading } = useMutation({
        mutationFn: id ? updateCampaign : scheduleCampaign,
        onSuccess: () => toast("Email Sent Successfully", { containerId: "success" }),
        onError: () => toast("Something went wrong", { containerId: "error" }),
        ...options
    });

    return {
        sendEmail: () => mutate({id, data}),
        isLoading,
    }
};

const sendTestEmail = async (mail) => {
    const token = getToken();
    return await fetchUtil({
        method: "POST",
        url: `/email/test`,
        body: JSON.stringify({
            ...mail,
            to: mail.testEmails
        }),
        token
    });
};

export const useSendTestEmail = (data) => {
    const { mutate, isLoading } = useMutation({
        mutationFn: sendTestEmail,
        onSuccess: () => toast("Test Email Sent Successfully", { containerId: "success" }),
        onError: () => toast("Something went wrong", { containerId: "error" })
    });

    return {
        sendTestEmail: () => mutate(data),
        isLoading,
    }
};

const getEmailStats = async (id) => {
    const token = getToken();
    return await fetchUtil({
        method: "GET",
        url: `/email/stats/${id}`,
        token
    });
};

export const useGetEmailStats = (id, options) => {
    return useQuery({
        queryKey: ["email", "stats", id],
        queryFn: () => getEmailStats(id),
        enabled: !!id,
        ...options
    });
};
