import React from "react";
import { connect } from "react-redux";
import "../../../App.css";
import { Spinner } from "../../Loader";

class InboxMessageUnselected extends React.Component {
    render() {
        let inboxMessageUnselected = null;
        if (this.props.selectedChatIdFromAllContact) {
            inboxMessageUnselected = (
                <div className="inbox-message-unselected-wrapper-spiner">
                    <div className="Center-spiner">
                        <Spinner size="xl" color="green" />
                    </div>
                </div>
            );
        } else {
            inboxMessageUnselected = (
                <div className="article-middle-content">
                    <h3>It’s a good day</h3>
                    <h3>to have a good day</h3>
                    <p>Select a message to continue chatting</p>
                </div>
            );
        }
        return (
            <div className="chats-article-content">
                <div className="inbox-message-unselected-overlay">{inboxMessageUnselected}</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { selectedChatIdFromAllContact: state.chat.selectedChatIdFromAllContact };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(InboxMessageUnselected);
