import React, { useMemo, useState, useCallback } from "react";

import StringMask from "string-mask";

import { Button, Input, Space, Tooltip, Select, DatePicker } from "antd";

import noop from "lodash/noop";
import { EditOutlined, RollbackOutlined, SaveOutlined } from "@ant-design/icons";

const EditableInput = ({
  label,
  mask,
  placeholder,
  value,
  type,
  prefix,
  options,
  extraInputProps = {},
  onSave = noop,
  onReset = noop,
  normalizeValue = val => val,
  direction = "horizontal",
  error
}) => {
  const maskValue = useCallback((value) => {
    if(mask) {
      const formatter = new StringMask(mask);
      
      return formatter.apply(normalizeValue(value));
    }
    return value;
  },  [ mask, normalizeValue ]);

  const [enabled, enable] = useState(false);
  const [ innerValue, setInnerValue ] = useState(maskValue(value));
  
  const InputComponent = useMemo(() => {
    switch(type) {
      case "select":
        return Select;
      case "date":
        return DatePicker;
      case "textarea":
        return Input.TextArea;
      default:
        return Input;
    }
  },
  [ type ]);

  const update = (val) => setInnerValue(maskValue(val));
  const reset = () => update(value);

  return (
     <div className="flex flex-col gap-1 w-full">
      <label className="text-md text-secondary">{label}</label>
      <Space.Compact direction={direction}>
        <InputComponent
          placeholder={placeholder}
          prefix={prefix}
          mask={mask}
          value={innerValue}
          onChange={
            ["date", "select"].includes(type)
              ? (date) => update(date)
              : (ev) => update(ev.target.value)
          }
          type={type}
          options={options}
          disabled={!enabled && !error}
          className="w-full"
          status={error ? "error" : null}
          {...extraInputProps}
        />
        {!enabled && !error && (
          <Button onClick={() => enable(true)}>
            <EditOutlined/>
          </Button>
        )}
        {(enabled || error) && (
          <>
          <Tooltip title="Revert">
            <Button onClick={() => reset() || onReset() || enable(false)}>
              <RollbackOutlined/>
            </Button>
          </Tooltip>
          <Tooltip title="Save">
            <Button
              onClick={() => onSave(innerValue) || enable(false)}
              className="bg-main text-white"
            >
              <SaveOutlined/>
            </Button>
          </Tooltip>
          </>
        )}
      </Space.Compact>
    </div>
  );
};

export default EditableInput;
