import React from "react";
import { NavLink, withRouter } from "react-router-dom";

import "../../App.css";

function AsideMenuItems(props) {
    const { icon, activeIcon, to, onClick, name } = props;
    let iconToShow = props.location.pathname.includes(to) ? activeIcon : icon;
    let unreadCounter;
    if ((name === "Texts" || name === "WebChat") && props.count > 0) {
        unreadCounter = (
            <div className="aside-menu-unread-message">
                <span className="unread-message-counter">{props.count}</span>
            </div>
        );
    }
    const item = (
        <li data-tip={name}>
            <div style={{ position: "relative" }}>
                <img src={iconToShow} alt="not loaded" />
                {unreadCounter}
                <span>{name}</span>
            </div>
        </li>
    );
    return to ? (
        <NavLink to={to} onClick={onClick} activeClassName="active">
            {item}
        </NavLink>
    ) : (
        <span onClick={onClick}>{item}</span>
    );
}

export default withRouter(AsideMenuItems);
