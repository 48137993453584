import React from "react";
import noop from "lodash/noop";

import { Input } from "antd";
import Wrapper from "./Wrapper";

const TextInput = ({ label, value, placeholder, onChange = noop, className }) => {
    return (
        <Wrapper label={label} className={className}>
            <Input size="large" value={value} placeholder={placeholder} onChange={onChange} />
        </Wrapper>
    );
};

export default TextInput;
