import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "./Account.css";
import Login from "./Login/Login";
import ForgetLogin from "./Login/ForgetLogin";
import ChangePassword from "./Login/ChangePassword";
const Account = (props) => {
    return (
        <div className="account">
            <div className="account__form flex-100 inline-flex-center">
                <Switch>
                    <Route
                        path="/account/login"
                        name="login"
                        render={(props) => <Login {...props} />}
                    />
                    <Route
                        path="/account/forget"
                        name="login"
                        render={(props) => <ForgetLogin {...props} />}
                    />
                    <Route
                        path="/account/change/password"
                        name="login"
                        render={(props) => <ChangePassword {...props} />}
                    />
                    <Redirect to="/account/login" />
                </Switch>
            </div>
        </div>
    );
};

export default Account;
