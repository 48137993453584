import React, { useState } from "react";
import { useGetSentCampaigns } from "../../../hooks/api/campaigns";
import { Button, Table, Tooltip, Drawer } from "antd";
import { MessageFilled, MailFilled } from "@ant-design/icons";
import moment from "moment";
import Header from "../../../components/atoms/Typography/Header";
import CampaignDetails from "./CampainDetail";

import { truncate } from "lodash";

const SentCampaigns = ({ search }) => {
  const [selectedCampaign, selectCampaign] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
    showSizeChanger: false
  })

  const {data, isLoading} = useGetSentCampaigns({
    Limit: 5,
    Page: pagination.current,
    Title: search ?? null,
  }, {
    onSuccess: ({TotalCampaigns}) => setPagination(prev => ({...prev, total: TotalCampaigns}))
  });

  const columns = [{
    title: "Name",
    dataIndex: "Title",
    key: "Title",
    render: (_, { Type, Title, Content }) =>
    <div className="flex gap-2">
      {([1, 2].includes(Type))
        ? <Tooltip title={Content}>{truncate(Content)}</Tooltip>
        : Title
      }
    </div>
  },{
    title: "Type",
    key: "Type",
    render: (_, { Type, AsEmailFallback }) =>
    <div className="flex gap-2">
      {([1, 2].includes(Type) || !!AsEmailFallback) && <MessageFilled className="text-2xl text-gray-500" />}
      {Type === 3 && <MailFilled className="text-2xl text-gray-500" />}
    </div>
  },{
    title: "Recipients",
    dataIndex: "TotalContacts",
    key: "TotalContacts",
    render: (_, {TotalContacts, Meta}) => TotalContacts + (Meta?.EmailContacts || 0)
  },{
    title: "Sent on",
    dataIndex: "SentOn",
    key: "SentOn",
    render: (_, {SentOn}) => moment(SentOn).format('MM/DD/YYYY @h:mma zz')
  },{
    title: "RSVP'd",
    key: "Replies",
    render: (_, {AutoReplyStatus, TotalRepliesWithNo, TotalRepliesWithYes}) => AutoReplyStatus ? TotalRepliesWithNo + TotalRepliesWithYes : '-'
  },{
    title: "",
    key: "Actions",
    render: (_, campaign) => (
      <Button onClick={() => selectCampaign(campaign)}>
        Results
      </Button>
    )
  }]
  return (
    <>
      <Header>Past Campaigns</Header>
      <Table
        columns={columns}
        dataSource={data?.Campaigns || []}
        pagination={pagination}
        loading={isLoading}
        onChange={pagination => setPagination(prev => ({...prev, ...pagination}))}
      />
      <Drawer
        width={720}
        open={selectedCampaign}
        onClose={() => selectCampaign(null)}
      >
        <CampaignDetails campaign={selectedCampaign} />
      </Drawer>
    </>
  );
}

export default SentCampaigns;
