import {
    CAMPAIGN_REPORTS_REQUEST,
    CAMPAIGN_REPORTS_SUCCESS,
    CAMPAIGN_REPORTS_FAIL,
    CAMPAIGN_CONTACTS_REQUEST,
    CAMPAIGN_CONTACTS_SUCCESS,
    CAMPAIGN_CONTACTS_FAIL,
    SCHEDULED_CAMPAIGN_REQUEST,
    SCHEDULED_CAMPAIGN_SUCCESS,
    SCHEDULED_CAMPAIGN_FAIL,
    CAMPAIGN_CANCEL_SUCCESS,
    CURRENT_CAMPAIGN_REQUEST,
    CURRENT_CAMPAIGN_SUCCESS,
    CURRENT_CAMPAIGN_FAIL,
    CHANGE_AUTO_REPLY_MODAL_STATE,
    CHANGE_TEST_MESSAGE_MODAL_STATE
} from "../actions";
import { PAGINATION_LIMIT } from "../../config/constants";

const initialState = {
    showAutoReplyModal: false,
    showTestMessageModal: false,
    campaignReports: {
        fetching: false,
        list: [],
        totalCampaigns: 0,
        dataAvailable: true,
        campaignPaginationList: []
    },
    campaignReportDetails: {
        fetching: false,
        list: [],
        totalCampaignContacts: 0,
        dataAvailable: true
    },
    scheduledCampaigns: {
        fetching: false,
        list: [],
        totalCampaigns: 0,
        dataAvailable: true,
        schduleCampaignPaginationList: []
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CAMPAIGN_REPORTS_REQUEST:
            return {
                ...state,
                campaignReports: {
                    ...state.campaignReports,
                    fetching: true,
                    list: (() => {
                        if (action.Page > 1) {
                            return [...state.campaignReports.list];
                        } else {
                            return [];
                        }
                    })()
                }
            };
        case CHANGE_AUTO_REPLY_MODAL_STATE:
            return {
                ...state,
                showAutoReplyModal: action.payload
            };
        case CHANGE_TEST_MESSAGE_MODAL_STATE:
            return {
                ...state,
                showTestMessageModal: action.payload
            };
        case CAMPAIGN_REPORTS_SUCCESS:
            return {
                ...state,
                campaignReports: {
                    ...state.campaignReports,
                    fetching: false,
                    dataAvailable: action.payload.Campaigns.length >= PAGINATION_LIMIT,
                    totalCampaigns: action.payload.TotalCampaigns,
                    campaignPaginationList: action.payload.Campaigns,
                    list: (() => {
                        if (action.Page && action.Page > 1) {
                            return [...state.campaignReports.list, ...action.payload.Campaigns];
                        } else {
                            return action.payload.Campaigns;
                        }
                    })()
                }
            };
        case CAMPAIGN_REPORTS_FAIL:
            return {
                ...state,
                campaignReports: {
                    ...state.campaignReports,
                    fetching: false
                }
            };
        case CAMPAIGN_CONTACTS_REQUEST:
            return {
                ...state,
                campaignReportDetails: {
                    ...state.campaignReportDetails,
                    fetching: true,
                    list: (() => {
                        if (action.Page > 1) {
                            return [...state.campaignReportDetails.list];
                        } else {
                            return [];
                        }
                    })()
                }
            };
        case CAMPAIGN_CONTACTS_SUCCESS:
            return {
                ...state,
                campaignReportDetails: {
                    ...state.campaignReportDetails,
                    fetching: false,
                    dataAvailable: action.payload.CampaignContacts.length >= PAGINATION_LIMIT,
                    totalCampaignContacts: action.payload.TotalCampaignContacts,
                    list: (() => {
                        if (action.Page && action.Page > 1) {
                            return [
                                ...state.campaignReportDetails.list,
                                ...action.payload.CampaignContacts
                            ];
                        } else {
                            return action.payload.CampaignContacts;
                        }
                    })()
                }
            };
        case CAMPAIGN_CONTACTS_FAIL:
            return {
                ...state,
                campaignReportDetails: {
                    ...state.campaignReportDetails,
                    fetching: false
                }
            };
        case SCHEDULED_CAMPAIGN_REQUEST:
            return {
                ...state,
                scheduledCampaigns: {
                    ...state.scheduledCampaigns,
                    fetching: true,
                    list: (() => {
                        if (action.Page > 1) {
                            return [...state.scheduledCampaigns.list];
                        } else {
                            return [];
                        }
                    })()
                }
            };
        case SCHEDULED_CAMPAIGN_SUCCESS:
            return {
                ...state,
                scheduledCampaigns: {
                    ...state.scheduledCampaigns,
                    fetching: false,
                    dataAvailable: action.payload.Campaigns.length >= PAGINATION_LIMIT,
                    totalCampaigns: action.payload.TotalCampaigns,
                    schduleCampaignPaginationList: action.payload.Campaigns,
                    list: (() => {
                        if (action.Page && action.Page > 1) {
                            return [...state.scheduledCampaigns.list, ...action.payload.Campaigns];
                        } else {
                            return action.payload.Campaigns;
                        }
                    })()
                }
            };
        case SCHEDULED_CAMPAIGN_FAIL:
            return {
                ...state,
                scheduledCampaigns: {
                    ...state.scheduledCampaigns,
                    fetching: false
                }
            };
        case CAMPAIGN_CANCEL_SUCCESS:
            return {
                ...state,
                scheduledCampaigns: {
                    ...state.scheduledCampaigns,
                    list: state.scheduledCampaigns.list.filter((campaign) => {
                        return campaign.Id !== action.payload;
                    }),
                    schduleCampaignPaginationList: state.scheduledCampaigns.schduleCampaignPaginationList.filter(
                        (campaign) => {
                            return campaign.Id !== action.payload;
                        }
                    )
                }
            };
        case CURRENT_CAMPAIGN_REQUEST:
            return {
                ...state,
                campaignReports: {
                    ...state.campaignReports,
                    fetching: true
                }
            };
        case CURRENT_CAMPAIGN_SUCCESS:
            return {
                ...state,
                campaignReports: {
                    ...state.campaignReports,
                    fetching: false,
                    list: [...state.campaignReports.list, action.payload]
                }
            };
        case CURRENT_CAMPAIGN_FAIL:
            return {
                ...state,
                campaignReports: {
                    ...state.campaignReports,
                    fetching: false
                }
            };
        default:
            return state;
    }
};
