import React from "react";
import { connect } from "react-redux";
import {
    // newBellIcon,
    newSetting,
    newSoundIcon,
    newLogoutIcon,
    LeftArrow
} from "../../assets/svg-icon/index";
import { getFormattedNumber } from "../../utils/commonUtils";
import CustomModal from "../modal/modal";
import withUser from "../../hoc/withUser";
import AccountSettingModal from "./AccountSettingModal";
import Switch from "react-input-switch";
import { setSoundStatus, changeAdminView } from "../../redux/actions";
import { errorToaster } from "../../utils/loggerUtils";
import { isSSOAuthenticated } from "../../utils/ssoUtils";
import Config from "../../config";
import { ApplicationTypes } from "../../config/constants";
class AccountSettingDropDown extends React.Component {
    constructor() {
        super();
        this.state = {
            isModalOpen: false
        };
    }
    handleModalState = () => {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });
    };
    handleSoundStatus = () => {
        let SoundStatus = this.props.user.Company.SoundStatus ? 0 : 1;
        this.props
            .setSoundStatus(SoundStatus)
            .then((res) => {
                const setSoundWebchat = () => {
                    try {
                        let webchatUrl = Config.env().WEBCHAT_URL_DOMAIN;
                        if (webchatUrl[webchatUrl.length - 1] === "/") {
                            webchatUrl = webchatUrl.slice(0, webchatUrl.length - 1);
                        }
                        if (webchatUrl) {
                            var iframe = document.getElementById("webchat-iframe");
                            if (iframe) {
                                iframe.contentWindow.postMessage(
                                    {
                                        Message: "SET_SOUND_STATUS",
                                        Data: { SoundStatus: SoundStatus }
                                    },
                                    webchatUrl
                                );
                            }
                        }
                    } catch (err) {
                        console.log("sound status error", err);
                    }
                };
                setSoundWebchat();
            })
            .catch((err) => errorToaster());
    };
    handleBackToAdminClick = () => {
        const { redirectUrl } = this.props;
        this.props.changeAdminView(false);
        window.location.replace(redirectUrl);
    };

    ShowWebChatTab = () => {
        return (
            this.props.user.Type === 3 &&
            this.props.user &&
            this.props.user.Apps &&
            this.props.user.Company &&
            Boolean(Object.keys(this.props.user.Apps.filter((item) => item.Id === 4)).length) &&
            this.props.user.Company.WebChatAccess === 1 &&
            this.props.user.ShowWebChatTab &&
            this.props.user.WelcomeAutomationCompleted
        );
    };

    isSettingsEnabled = () => {
        let clubChat =
            this.props.user &&
            this.props.user.Apps.find((app) => app.Id === ApplicationTypes.CLUB_CHAT);
        // if(clubChat || this.ShowWebChatTab()) {
        if (clubChat) {
            return true;
        }
        return false;
    };

    render() {
        const { user } = this.props;
        return (
            <div className="setting-dropdown z-index-10 ">
                <ul>
                    <li id="acc-info">
                        <p>Your Text # {getFormattedNumber(user.Company.PhoneNumber)}</p>
                        <h4>{user.Company.Name}</h4>
                    </li>
                    {/* <li>
                        <img src={newBellIcon} className="img-fluid" alt="img" />
                        <h3>What’s New</h3>
                    </li> */}
                    <li
                        className={`${!this.isSettingsEnabled() ? "disabled" : ""}`}
                        onClick={() => {
                            this.handleModalState();
                        }}
                    >
                        <img src={newSetting} className="img-fluid" alt="img" />
                        <h3>Settings</h3>
                        {this.state.isModalOpen ? (
                            <CustomModal isOpen={this.state.isModalOpen}>
                                <AccountSettingModal closeModal={this.handleModalState} />
                            </CustomModal>
                        ) : null}
                    </li>
                    <li id="sound-toggle">
                        <img src={newSoundIcon} className="img-fluid" alt="img" />
                        <h3>Sounds {user.Company.SoundStatus ? "ON" : "OFF"}</h3>
                        <div className="soundtoggle">
                            <div className="toggleswitching">
                                <Switch
                                    value={user.Company.SoundStatus}
                                    onChange={this.handleSoundStatus}
                                />
                            </div>
                        </div>
                    </li>
                    <li onClick={() => (window.location.href = "/logout")}>
                        <img src={newLogoutIcon} className="img-fluid" alt="img" />
                        <a href="/logout">Log Out </a>
                    </li>
                    {this.props.isAdmin && !isSSOAuthenticated() && (
                        <li onClick={() => this.handleBackToAdminClick()}>
                            <img src={LeftArrow} className="img-fluid" alt="img" />
                            <h3>Back To Admin </h3>
                        </li>
                    )}
                </ul>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSoundStatus: (status) => dispatch(setSoundStatus(status)),
        changeAdminView: (isAdmin) => dispatch(changeAdminView(isAdmin))
    };
};
const mapStateToProps = (state) => {
    return {
        isAdmin: state.auth.isAdmin,
        redirectUrl: state.auth.redirectUrl
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withUser(AccountSettingDropDown));
