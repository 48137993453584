import React from "react";
import { connect } from "react-redux";
import withUser from "../../hoc/withUser";
import { getSegments, addContact, getRegisterFormFields } from "../../redux/actions";

import { Spinner } from "../../components/Loader";

class BrandRegisterForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iframeLoading: true
        };
    }

    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://form.123formbuilder.com/embed/5892495.js";
        script.async = true;
        const handlerIframeLoading = () => {
            // return;
            setTimeout(() => {
                this.setState({ ...this.state, iframeLoading: !this.state.iframeLoading });
            }, 2000);
        };
        script.addEventListener("load", handlerIframeLoading);
        document.body.appendChild(script);
    }

    render() {
        const { handleClose } = this.props;
        return (
            <div className="brand-registration-modal">
                <div className="brand-registration-content">
                    <div className="brand-registration-modal-header">
                        <span
                            onClick={handleClose}
                            className="close cursor-pointer"
                            id="contacts-newcontact-closebutton"
                        >
                            &times;
                        </span>
                        <h3>ClubChat Brand Registration </h3>
                        <p className="paragraph">
                            We’ve noticed you haven’t registered your brand yet. Fill out the form
                            below with your company’s information and we’ll get you registered.
                        </p>
                        <p className="paragraph">
                            Not registering can result in slower sending rates and worse
                            deliverability due to filters.
                        </p>
                        {this.state.iframeLoading ? (
                            <div style={{ textAlign: "center", paddingTop: "50px" }}>
                                <Spinner color="green" size="xxxl" />
                            </div>
                        ) : null}
                        <form onSubmit={this.handleAddContactSubmit}>
                            <script
                                type="text/javascript"
                                defer
                                src="https://form.123formbuilder.com/embed/5892495.js"
                                data-role="form"
                                data-default-width="650px"
                            ></script>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getSegments: () => dispatch(getSegments()),
        addContact: (firstName, lastName, phone, segmentIds, email) =>
            dispatch(addContact(firstName, lastName, phone, segmentIds, email)),
        getRegisterFormFields: (data) => dispatch(getRegisterFormFields(data))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withUser(BrandRegisterForm));
