import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import "../../App.css";
import { handleAddContactModal, changeAdminView, handleBrandregister } from "../../redux/actions";
import Socket from "../../utils/socketUtils";
import withUser from "../../hoc/withUser";
import { CustomModal } from "../../components/modal";
import BrandRegisterForm from "../../views/BrandRegisterForm/BrandRegisterForm";
import BrandStatus from "../../config/constants/BrandStatus";

class DefaultSideBar extends React.Component {
    constructor() {
        super();
        this.state = {
            brandRegisterForm: BrandStatus.PENDING
        };
    }
    handleBrandRegisterFormHandler = () => {
        this.setState({ ...this.state, brandRegisterForm: BrandStatus.CANCEL });
        const { handleBrandregister } = this.props;
        handleBrandregister(BrandStatus.CANCEL);
    };
    componentDidMount() {
        this.setState({ ...this.state, brandRegisterForm: this.props.isBrandVaildToOpen });
        if (this.props.location.pathname.includes("inbox") && !this.props.isSocketConnected) {
            Socket.init();
        }
    }

    handleAddContactClick = () => {
        const { showAddContactModal, history } = this.props;
        history.push("/dashboard/contacts/all-contacts/active");
        showAddContactModal();
    };
    handleBackToAdminClick = () => {
        const { redirectUrl } = this.props;
        this.props.changeAdminView(false);
        window.location.replace(redirectUrl);
    };
    render() {
        const { childRoutes } = this.props;
        return (
            <>
                <div className="main-view-content">
                    {/* <div style={{ color: 'red' }}> */}
                    {this.state.brandRegisterForm === BrandStatus.NOT_SET ||
                    this.state.brandRegisterForm === BrandStatus.NOT_AVALIABLE ? (
                        <CustomModal
                            isOpen={this.state.brandRegisterForm}
                            handleModalState={this.handleBrandRegisterFormHandler}
                        >
                            <BrandRegisterForm
                                // handleAddContactCallBack={this.AddContactCallBack}
                                handleClose={this.handleBrandRegisterFormHandler}
                            />
                        </CustomModal>
                    ) : null}

                    {/* </div> */}
                    <div className="container main-view-content-wrapper">
                        <Switch>
                            {childRoutes &&
                                childRoutes.map((route, index) => {
                                    return route.component ? (
                                        <Route
                                            key={index}
                                            path={route.path}
                                            name={route.name}
                                            exact={route.exact}
                                            render={(props) => <route.component {...props} />}
                                        />
                                    ) : null;
                                })}
                            <Redirect to={childRoutes[0].path} />
                        </Switch>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isSocketConnected: state.socketConnected,
        isAdmin: state.auth.isAdmin,
        redirectUrl: state.auth.redirectUrl,
        isBrandVaildToOpen: state.auth.isBrandVaildToOpen
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleBrandregister: (status) => dispatch(handleBrandregister(status)),
        showAddContactModal: () => dispatch(handleAddContactModal(true)),
        changeAdminView: (isAdmin) => dispatch(changeAdminView(isAdmin))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withUser(DefaultSideBar));
