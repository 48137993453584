import React from "react";
import { Switch, Route } from "react-router-dom";

import "../../App.css";
import { ChatFilters } from "../../config/constants";
import { InboxMessageUnselected, InboxChatBox, InboxContactsList } from "../../components/Inbox";

class InboxAllMessages extends React.Component {
    handleChatSelectClick = (id) => {
        this.props.history.replace(`/dashboard/inbox/opt-out-messages/${id}`);
    };

    render() {
        return (
            <div className="inbox-container">
                <Switch>
                    <Route
                        path="/dashboard/inbox/opt-out-messages/:id?"
                        render={(props) => (
                            <InboxContactsList
                                {...props}
                                onConversationClick={this.handleChatSelectClick}
                                filterType={ChatFilters.CLOSED}
                            />
                        )}
                    />
                </Switch>
                <div className=" article-middle-chat inbox-chatbox-container">
                    <Switch>
                        <Route
                            path="/dashboard/inbox/opt-out-messages/:id"
                            exact={true}
                            render={(props) => <InboxChatBox {...props} isOptedOut={true} />}
                        />
                        <Route component={InboxMessageUnselected} />
                    </Switch>
                </div>
            </div>
        );
    }
}

export default InboxAllMessages;
