import React from "react";
import { Redirect } from "react-router-dom";
import withUser from "../../hoc/withUser";
import { connect } from "react-redux";
import "../../App.css";
import { getToken } from "../../utils/tokenUtils";
import { getMe } from "../../redux/actions";
import Config from "../../config";
import { Spinner } from "../../components/Loader";

class WebChat extends React.Component {
    constructor() {
        super();
        this.searchSubmit = null;
        this.state = { iframeLoading: true };
    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({ iframeLoading: false });
        }, 3500);
        this.props.getMe();
        window.addEventListener("message", this.iframeEvent, false);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.iframeEvent, false);
    }

    iframeEvent(event) {
        let webchatUrl = Config.env().WEBCHAT_URL_DOMAIN;
        if (webchatUrl[webchatUrl.length - 1] === "/") {
            webchatUrl = webchatUrl.slice(0, webchatUrl.length - 1);
        }
        if (event.origin !== webchatUrl) {
            return;
        }
        let data = event.data;
        if (data) {
            if (data.Message === "OPEN_INTERCOM") {
                if (data.Data) {
                    try {
                        window.Intercom("show");
                    } catch (err) {
                        console.log("err ", err);
                    }
                }
            }
        }
    }

    render() {
        // const { user } = this.props;
        let token = getToken();
        const iframe = `<iframe id="webchat-iframe" src="${
            Config.env().WEBCHAT_URL_DOMAIN
        }dashboard/inbox?token=${token}&header=true"  allow="clipboard-read; clipboard-write"
    width="100%" onload={this.handleFileLoad} height="100%" position="fixed" ></iframe>`;
        return (
            <div className="tabs-container">
                {/* !user.ShowWebChatTab */}
                {false ? (
                    <Redirect to="/dashboard/" />
                ) : (
                    <>
                        <div style={{ display: !this.state.iframeLoading ? "block" : "none" }}>
                            <div
                                dangerouslySetInnerHTML={{ __html: iframe }}
                                style={{ height: "calc(100vh - 80px)" }}
                            />
                        </div>
                        <div
                            style={{
                                display: !this.state.iframeLoading ? "none" : "flex",
                                height: "100vh",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            {" "}
                            <Spinner size="xxl" color="green" />{" "}
                        </div>
                    </>
                )}
            </div>
        );
    }
}

// export default WebChat;
const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMe: () => dispatch(getMe())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withUser(WebChat));
