import React from "react";
import classNames from "classnames";

const Header = ({children, className}) => (
  <h3
    className={classNames(
      "font-bold text-[22px] text-[#344c60] font-['Montserrat']",
      className
    )}
  >
    {children}
  </h3>
);

export default Header;
