import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Config from "../../config";
import { NotificationPermissionTypes, ServiceWorkerTypes } from "../../constant";
import { IsPatternExist } from "../../utils/commonUtils";
import "../../App.css";
import {
    newLogoImage,
    newChatIcon,
    newDropDownIcon,
    newCampainsIcon,
    NewChatMenuContactIcon,
    newContactDropDownIcon,
    downloadIcon,
    newChatBoxActiveIcon,
    NewChatMenuContactActiveIcon,
    newCampainsActiveIcon,
    WebchatInActiveSvg,
    WebchatActiveSvg,
    LifePreserverIconSvg
} from "../../assets/svg-icon/index";
import ConfirmationModal from "../../views/AllContacts/ConfirmationModal";
import { Dropdown } from "../../components/Dropdown";
import AsideMenuItems from "./AsideMenuItems";
import { ApplicationTypes, ChatReadStatus } from "../../config/constants";
import Alert from "../../assets/Audio/2_Blop.wav";
import AccountSettingDropDown from "../../components/AccountSetting/AccountSettingDropDown";
import {
    updateChatReadStatus,
    addMessage,
    getMe,
    updateChatAfterAddingMessage,
    getUnreadChatCountAndList,
    setUnreadChatAndList,
    setContactAddStatus,
    setShowPermisionNotification,
    setUnreadWebChatList
} from "../../redux/actions";
import Socket from "../../utils/socketUtils";
// import { msg1, msg2, msg3, msg4, msg5, msg6, favicon } from "../../assets/images/index";
import { Howl } from "howler";
import { CloseNotifcation } from "../../assets/svg-icon";
import { NavLink } from "react-router-dom";
import { CustomModal } from "../../components/modal";
import AddContactModal from "../../views/AllContacts/AddContactModal";
import ContactAlertModal from "../../components/Contact/ContactAlertModal";
import { getToken } from "../../utils/tokenUtils";
import Favicon from "../../components/Favicon";
import sendNotification from "../../utils/sendNotification";
import { MailOutlined, MessageOutlined } from "@ant-design/icons";

class DefaultAside extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newDropDown: false,
            settingDropDown: false,
            NotificationIsBlocked: false,
            showAddContact: false,
            showAddContactAlert: false,
            showCampainCancle: false,
            NotificationAllowed: false,
            unreadWebChatList: props.unreadWebChatList
        };
        this.newButtonDropDownRef = React.createRef();
        this.NewButtonReference = React.createRef();
        this.settingDropDownRef = React.createRef();
        this.innerReference = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.unreadWebChatList !== state.unreadWebChatList) {
            //Change in props
            return {
                unreadWebChatList: props.unreadWebChatList
            };
        }

        return null; // No change to state
    }

    static sound = new Howl({
        src: [Alert]
    });

    componentDidMount() {
        this.props.getMe();
        this.props.getUnreadChatCountAndList();
        // let unreadChat = 0;
        // if (this.props.unreadChatCountAndList) {
        //     unreadChat = this.props.unreadChatCountAndList.Count;
        // }
        // if (unreadChat > 0) {
        //     let msgRecivedIcon = msg1;
        //     if (unreadChat === 2) {
        //         msgRecivedIcon = msg2;
        //     } else if (unreadChat === 3) {
        //         msgRecivedIcon = msg3;
        //     } else if (unreadChat === 4) {
        //         msgRecivedIcon = msg4;
        //     } else if (unreadChat === 5) {
        //         msgRecivedIcon = msg5;
        //     } else if (unreadChat > 5) {
        //         msgRecivedIcon = msg6;
        //     }
        //     //this logic is replaced below with dynamic favicon component
        //     (() => {
        //         // let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        //         // link.type = 'image/x-icon';
        //         // link.rel = 'shortcut icon';
        //         // link.href = msgRecivedIcon;
        //         // document.getElementsByTagName('head')[0].appendChild(link);
        //     })();
        // } else {
        //     (() => {
        //         // let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        //         // link.type = 'image/x-icon';
        //         // link.rel = 'shortcut icon';
        //         // link.href = Favicon;
        //         // document.getElementsByTagName('head')[0].appendChild(link);
        //     })();
        // }

        Socket.listenMessage(this.handleListenMessage);
        Socket.listenMessageRead((data) => {
            this.handleToPullUnreadChat(data.ChatId);
        });

        Socket.listenReadUnreadWebchat((data) => this.handleOnReadUnreadTrigger(data));

        this.GetNotificationPermission();
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.addEventListener(
                "message",
                this.listenMessageFromServiceWorker
            );
        }
    }
    changeNewButtonDropDownStatus = () => {
        if (
            this.newButtonDropDownRef &&
            this.newButtonDropDownRef.current &&
            this.newButtonDropDownRef.current.isDropDownInstance()
        ) {
            this.newButtonDropDownRef.current.openDropDown();
        }
    };
    changeSettingDropDownStatus = () => {
        if (
            this.settingDropDownRef &&
            this.settingDropDownRef.current &&
            this.settingDropDownRef.current.isDropDownInstance()
        ) {
            this.settingDropDownRef.current.openDropDown();
        }
    };
    handleOnReadUnreadTrigger = (data) => {
        const unreadConvos = this.props.unreadWebChatList;
        const newConvo = unreadConvos.find(
            (item) => item.Id === data.ChatId && data.data.ReadStatus === 2
        );

        if (data.data.ChatData) {
            sendNotification(
                "Notification",
                `${data.data.ChatData.at(0).user.Name} sent a new message!`,
                Boolean(this.props.user.Company.SoundStatus)
            );
        }

        //adding unread conversation to state
        if (!newConvo && data.data.ReadStatus === 2) {
            unreadConvos.push(data.data);
            this.props.setUnreadWebChatList([...unreadConvos]);
        } else if (data.data.ReadStatus === 1) {
            //removing read conversation from state
            const newUnreadConvos = unreadConvos.filter((item) => item.Id !== data.ChatId);
            this.props.setUnreadWebChatList([...newUnreadConvos]);
        }
    };
    handleNotificationPermissionAccept = () => {
        try {
            window.Notification &&
                window.Notification.requestPermission((p) => {
                    localStorage.notificationPermission = p;
                });
        } catch (e) {
            console.log(e);
        }
    };
    sendMessageToServiceWorker = ({ Type, Payload }) => {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.ready.then((reg) => {
                reg.active.postMessage({ Type, Payload });
            });
        }
    };
    listenMessageFromServiceWorker = (event) => {
        const { Type, Payload } = event.data;
        switch (Type) {
            case ServiceWorkerTypes.ShowNotification:
                this.showNotification(Payload);
                break;
            default:
                break;
        }
    };
    showNotification = (data) => {
        if (!data) {
            return;
        }
        if (data.SenderId === this.props.user.Id) {
            return;
        }
        if (this.props.user && this.props.user.Company && this.props.user.Company.SoundStatus) {
            DefaultAside.sound.play();
        }
        if (
            "serviceWorker" in navigator &&
            window.Notification &&
            window.Notification.permission === NotificationPermissionTypes.GRANTED
        ) {
            let content = data.Content;
            if (content && content.length > 20) {
                content = content.substring(0, 20) + "...";
            } else {
                content = "...";
            }
            if (data && (data.FirstName || data.LastName) && data.ChatId) {
                navigator.serviceWorker.ready.then((reg) => {
                    reg.showNotification("New Message Alert", {
                        icon:
                            "https://dev-connect.digitalrange.com/static/media/favicon.822e2648.png",
                        body: `${data.FirstName} ${data.LastName} says ${content}`,
                        data: {
                            ChatId: data.ChatId,
                            Path: `/dashboard/inbox/all-messages/${data.ChatId}`
                        },
                        silent: true
                    });
                });
            }
        }
    };
    handleListenMessage = (data) => {
        if (
            this.props.location &&
            this.props.location.pathname.includes("inbox") &&
            data.ChatId === this.props.currentChatData.Id
        ) {
            this.props.addMessage(data);

            Socket.messageRead({ chatId: data.ChatId, chatMessageId: data.Id });
            if (data.Chat) {
                data.Chat.ReadStatus = ChatReadStatus.READ;
            }
            if (data.Chat && data.Chat.Id) {
                this.handleToPullUnreadChat(data.Chat.Id);
            }
        }
        if (this.props.location && this.props.location.pathname.includes("all-messages")) {
            this.props.updateChatAfterAddingMessage(data);
        }
        this.sendMessageToServiceWorker({
            Type: ServiceWorkerTypes.ShowNotification,
            Payload: {
                SenderId: data.SenderId,
                UniqueId: data.Id,
                Content: data.Content,
                ChatId: data.ChatId,
                FirstName: data.ContactFirstName,
                LastName: data.ContactLastName
            }
        });
        this.handleListenMessageFormMain(data);
    };
    handleToPullUnreadChat = (chatId) => {
        let isExitsIndex = this.props.unreadChatCountAndList.unreadChatId.findIndex(
            (unreadChat) => {
                return chatId === unreadChat.id;
            }
        );
        if (isExitsIndex > -1) {
            this.props.updateChatReadStatus(chatId);
            this.props.unreadChatCountAndList.unreadChatId.splice(isExitsIndex, 1);
            this.props.unreadChatCountAndList.Count = this.props.unreadChatCountAndList.unreadChatId.length;
            this.props.setUnreadChatAndList({
                unreadChatId: this.props.unreadChatCountAndList.unreadChatId,
                Count: this.props.unreadChatCountAndList.Count
            });
        }
    };
    componentDidUpdate() {
        // let unreadChat = 0;
        // if (this.props.unreadChatCountAndList) {
        //     unreadChat = this.props.unreadChatCountAndList.Count;
        // }
        // if (unreadChat > 0) {
        //     let msgRecivedIcon = msg1;
        //     if (unreadChat === 2) {
        //         msgRecivedIcon = msg2;
        //     } else if (unreadChat === 3) {
        //         msgRecivedIcon = msg3;
        //     } else if (unreadChat === 4) {
        //         msgRecivedIcon = msg4;
        //     } else if (unreadChat === 5) {
        //         msgRecivedIcon = msg5;
        //     } else if (unreadChat > 5) {
        //         msgRecivedIcon = msg6;
        //     }
        //     (() => {
        //         let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        //         link.type = 'image/x-icon';
        //         link.rel = 'shortcut icon';
        //         link.href = msgRecivedIcon;
        //         document.getElementsByTagName('head')[0].appendChild(link);
        //     })();
        // } else {
        //     (() => {
        //         // let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        //         // link.type = 'image/x-icon';
        //         // link.rel = 'shortcut icon';
        //         // link.href = `data:image/svg+xml;utf8,${encodeURIComponent(
        //         //     ,
        //         // )}`;
        //         // document.getElementsByTagName('head')[0].appendChild(link);
        //     })();
        // }
    }
    changeNotificationPermission = () => {
        this.props.setShowPermisionNotification();
    };
    GetNotificationPermission = () => {
        try {
            window.Notification &&
                window.Notification.requestPermission((p) => {
                    localStorage.notificationPermission = p;
                    if (p === "granted") {
                        this.setState({ NotificationAllowed: true });
                    }
                    if (p === "denied") {
                        this.setState({
                            ...this.state,
                            NotificationIsBlocked: !this.state.NotificationIsBlocked
                        });
                    } else {
                        this.changeNotificationPermission();
                    }
                });
        } catch (e) {
            console.log(e);
        }
    };
    handleListenMessageFormMain = (data) => {
        if (
            data.SenderType === 1 ||
            (this.props.location &&
                this.props.location.pathname.includes("inbox") &&
                this.props.currentChat &&
                this.props.currentChat.data &&
                this.props.currentChat.data.ContactId &&
                data &&
                this.props.currentChat.data.ContactId === data.SenderId)
        ) {
            return;
        }
        let isMessageStop = IsPatternExist("^stop", "i", data.Content);
        let isExits = this.props.unreadChatCountAndList.unreadChatId.findIndex((chat) => {
            return data.ChatId === chat.id;
        });
        if (isExits === -1 && !isMessageStop) {
            this.props.unreadChatCountAndList.unreadChatId.push({
                id: data.ChatId,
                read_status: 2,
                contact_id: data.SenderId
            });

            this.props.unreadChatCountAndList.Count = this.props.unreadChatCountAndList.unreadChatId.length;
            this.props.setUnreadChatAndList({
                unreadChatId: this.props.unreadChatCountAndList.unreadChatId,
                Count: this.props.unreadChatCountAndList.Count
            });
        }
    };
    interComChat = () => {
        var intercomContainers = document.getElementsByClassName('intercom-lightweight-app');
        let intercomContainerById = document.getElementById('intercom-container');
        let intercomContainer = intercomContainers[0]
        if(intercomContainerById){
            intercomContainer = intercomContainerById
        }
        // Check if the element is not null (exists)
        if (intercomContainer) {
            // Check the current display property
            var currentDisplay = intercomContainer.style.display;
            // Toggle the display property
            intercomContainer.style.display = (currentDisplay === 'none') ? 'block' : 'none';
            intercomContainer.style.display = (currentDisplay === 'none') ? window.Intercom('show'): window.Intercom('hide');
        }
    }
    handleAddContactChange = () => {
        this.setState({ showAddContact: !this.state.showAddContact });
    };
    handleCancleCampain = () => {
        this.setState({ showCampainCancle: !this.state.showCampainCancle });
    };
    handleCampainCancleConformation = () => {
        this.setState({ showCampainCancle: !this.state.showCampainCancle });
        this.props.history.replace("/inbox/all-messages");
    };
    handleAddContactAlertChange = () => {
        this.setState({ showAddContactAlert: !this.state.showAddContactAlert });
    };
    AddContactCallBack = (res) => {
        this.handleAddContactChange();
        this.handleAddContactAlertChange();
        this.props.setContactAddStatus(res);
    };

    isClubChatAccessAccessAllowed = () => {
        let clubChat = this.props.user.Apps.find((app) => app.Id === ApplicationTypes.CLUB_CHAT);
        if (clubChat) {
            return true;
        }
        return false;
    };

    handleImpersonate = () => {
        const token = getToken();
        let redirectUrl = `${Config.env().WEBCHAT_APP_DOMAIN}verify/${token}?isAdmin=true&url=${window.origin
            }`;
        window.open(redirectUrl);
    };

    render() {
        const { user, showNotificationPermission } = this.props;
        const {
            NotificationIsBlocked,
            showAddContact,
            showAddContactAlert,
            NotificationAllowed
        } = this.state;
        let count = 0;
        if (this.props.unreadChatCountAndList) {
            count = this.props.unreadChatCountAndList.Count;
        }
        return (
            <div>
                <Favicon count={count + this.props.unreadWebChatList.length} />
                {showNotificationPermission && !NotificationAllowed ? (
                    <div className="top-header">
                        {NotificationIsBlocked ? (
                            <label>
                                {" "}
                                Your Notifications is blocked by browser settings, Please enable it
                                manually{" "}
                            </label>
                        ) : (
                            <label>
                                {" "}
                                ClubChat needs you permission to show notifications
                                <span
                                    className="cursor-pointer"
                                    onClick={() => this.GetNotificationPermission()}
                                >
                                    Enable notifications
                                </span>{" "}
                            </label>
                        )}

                        <img
                            onClick={() => this.changeNotificationPermission()}
                            src={CloseNotifcation}
                            className="img-fluid"
                            alt="img"
                        />
                    </div>
                ) : null}

                <nav className="chat-navigation">
                    {window &&
                        window.location.pathname &&
                        !window.location.pathname.includes("sms-campaign") &&
                        !window.location.pathname.includes("edit-campaign") ? (
                        <ul className="chat-menu">
                            <li onClick={() => this.props.history.push("/dashboard/inbox")}>
                                <img
                                    src={newLogoImage}
                                    style={{ width: "70px" }}
                                    className="img-fluid"
                                    alt="img"
                                />
                            </li>

                            {this.isClubChatAccessAccessAllowed() && (
                                <>
                                    {window &&
                                        window.location.pathname &&
                                        window.location.pathname.includes("inbox") ? (
                                        <AsideMenuItems
                                            icon={newChatIcon}
                                            activeIcon={newChatIcon}
                                            to="/dashboard/inbox"
                                            name="Texts"
                                            count={count}
                                        />
                                    ) : (
                                        <AsideMenuItems
                                            icon={newChatBoxActiveIcon}
                                            activeIcon={newChatBoxActiveIcon}
                                            to="/dashboard/inbox"
                                            name="Texts"
                                            count={count}
                                        />
                                    )}
                                    {window &&
                                        window.location.pathname &&
                                        window.location.pathname.includes("contacts") ? (
                                        <AsideMenuItems
                                            icon={NewChatMenuContactActiveIcon}
                                            activeIcon={NewChatMenuContactActiveIcon}
                                            to="/dashboard/contacts"
                                            name="Contacts"
                                        />
                                    ) : (
                                        <AsideMenuItems
                                            icon={NewChatMenuContactIcon}
                                            activeIcon={NewChatMenuContactIcon}
                                            to="/dashboard/contacts"
                                            name="Contacts"
                                        />
                                    )}
                                    {window &&
                                        window.location.pathname &&
                                        window.location.pathname.includes("reports") ? (
                                        <AsideMenuItems
                                            icon={newCampainsActiveIcon}
                                            activeIcon={newCampainsActiveIcon}
                                            to="/dashboard/reports"
                                            name="Campaigns"
                                        />
                                    ) : (
                                        <AsideMenuItems
                                            icon={newCampainsIcon}
                                            activeIcon={newCampainsIcon}
                                            to="/dashboard/reports"
                                            name="Campaigns"
                                        />
                                    )}
                                </>
                            )}
                            {/* {
                                    user && user.Apps && user.Company && Boolean(Object.keys(user.Apps.filter(item => item.Id === 4)).length) && user.Company.WebChatAccess === 1 ?

                                        window && window.location.pathname && window.location.pathname.includes("webchat") ?
                                            <AsideMenuItems
                                                icon={WebchatInActiveSvg}
                                                activeIcon={WebchatActiveSvg}
                                                to=" "
                                                onClick={this.handleImpersonate}
                                                name="WebChat"
                                            /> : <AsideMenuItems
                                                icon={WebchatInActiveSvg}
                                                activeIcon={WebchatActiveSvg}
                                                onClick={this.handleImpersonate}
                                                to=" "
                                                name="WebChat"
                                            /> : null
                                } */}

                            {user &&
                                user.Apps &&
                                user.Company &&
                                Boolean(
                                    Object.keys(user.Apps.filter((item) => item.Id === 4)).length
                                ) &&
                                user.Company.WebChatAccess === 1 ? (
                                window &&
                                    window.location.pathname &&
                                    window.location.pathname.includes("webchat") ? (
                                    <AsideMenuItems
                                        icon={WebchatInActiveSvg}
                                        activeIcon={WebchatActiveSvg}
                                        to="/dashboard/webchat"
                                        name="WebChat"
                                        count={this.props.unreadWebChatList.length}
                                    />
                                ) : (
                                    <AsideMenuItems
                                        icon={WebchatInActiveSvg}
                                        activeIcon={WebchatActiveSvg}
                                        to="/dashboard/webchat"
                                        name="WebChat"
                                        count={this.props.unreadWebChatList.length}
                                    />
                                )
                            ) : null}
                        </ul>
                    ) : (
                        <>
                            <ul className="chat-menu">
                                <li>
                                    <img
                                        src={newLogoImage}
                                        style={{ width: "60px" }}
                                        className="img-fluid"
                                        alt="img"
                                    />
                                </li>
                            </ul>
                            <ul className="chat-menu">
                                <button
                                    className="campain-cancel-button"
                                    onClick={this.handleCancleCampain}
                                >
                                    Cancel
                                </button>
                            </ul>
                        </>
                    )}

                    <ul className="chat-right-menu">
                        {!(
                            window &&
                            window.location.pathname &&
                            window.location.pathname.includes("webchat")
                        ) ? (
                            <li>
                                <img
                                    onClick={this.interComChat}
                                    src={LifePreserverIconSvg}
                                    style={{ width: "35px", height: "35px", marginRight: "20px" }}
                                    className="img-fluid"
                                    alt="img"
                                />
                                <button
                                    className="chatsnew-btn"
                                    ref={this.NewButtonReference}
                                    onClick={this.changeNewButtonDropDownStatus}
                                >
                                    + New
                                </button>
                                <Dropdown
                                    ref={this.newButtonDropDownRef}
                                    innerReference={this.NewButtonReference}
                                >
                                    <div className="newbutton-dropdown z-index-10">
                                        <ul>
                                            <li>
                                                <NavLink to="/dashboard/text/sms-campaign">
                                                    <MessageOutlined
                                                        className="img-fluid"
                                                        style={{
                                                            color: "#1d3152",
                                                            margin: "0 5px"
                                                        }}
                                                    />
                                                    <h3>New SMS Campaign</h3>
                                                </NavLink>
                                            </li>
                                            {this.props.user.CanUseEmailCampaings && (
                                                <li>
                                                    <NavLink to="/dashboard/email/email-campaign">
                                                        <MailOutlined
                                                            className="img-fluid"
                                                            style={{
                                                                color: "#1d3152",
                                                                margin: "0 5px"
                                                            }}
                                                        />
                                                        <h3>New Email Campaign</h3>
                                                    </NavLink>
                                                </li>
                                            )}
                                            <li onClick={this.handleAddContactChange}>
                                                <img
                                                    src={newContactDropDownIcon}
                                                    className="img-fluid"
                                                    alt="img"
                                                />
                                                <h3>New Contact</h3>
                                            </li>
                                        </ul>
                                    </div>
                                </Dropdown>
                            </li>
                        ) : null}
                        <li>
                            <span>{user.Company.Name}</span>
                            {user.Company && user.Company.Logo && (
                                <label
                                    className="profile-setting-btn"
                                    style={{
                                        backgroundImage: `url(${Config.env().BASE_IMG_PATH}/${user.Company.Logo.ThumbPath
                                            }`
                                    }}
                                >
                                    {" "}
                                </label>
                            )}

                            <img
                                src={newDropDownIcon}
                                className="img-fluid"
                                ref={this.innerReference}
                                onClick={this.changeSettingDropDownStatus}
                                alt="img"
                            />
                            <Dropdown
                                ref={this.settingDropDownRef}
                                innerReference={this.innerReference}
                            >
                                <AccountSettingDropDown />
                            </Dropdown>
                        </li>
                    </ul>
                </nav>
                {showAddContact ? (
                    <CustomModal
                        isOpen={showAddContact}
                        handleModalState={this.handleAddContactChange}
                    >
                        <AddContactModal
                            handleAddContactCallBack={this.AddContactCallBack}
                            handleClose={this.handleAddContactChange}
                        />
                    </CustomModal>
                ) : null}
                {this.state.showCampainCancle ? (
                    <CustomModal
                        isOpen={this.state.showCampainCancle}
                        handleModalState={this.handleCancleCampain}
                    >
                        <ConfirmationModal
                            color={"red"}
                            handleModalClose={this.handleCancleCampain}
                            handleModalClick={this.handleCampainCancleConformation}
                            title={"Confirm"}
                            content={
                                window &&
                                    window.location.pathname &&
                                    !window.location.pathname.includes("sms-campaign")
                                    ? `Are you sure you want to close edit campaign mode? it won't be saved.`
                                    : `Are you sure you want to cancel this campaign? it won't be saved.`
                            }
                            buttonContent={"Confirm"}
                        />
                    </CustomModal>
                ) : null}
                {showAddContactAlert ? (
                    <CustomModal
                        isOpen={showAddContactAlert}
                        handleModalState={this.handleAddContactAlertChange}
                    >
                        <ContactAlertModal
                            color={"green"} // todo
                            handleClose={this.handleAddContactAlertChange}
                            icon={downloadIcon}
                            content={`Contact has been created successfully.`}
                        />
                    </CustomModal>
                ) : null}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUnreadChatCountAndList: () => dispatch(getUnreadChatCountAndList()),
        setShowPermisionNotification: () => dispatch(setShowPermisionNotification()),
        setUnreadChatAndList: (data) => dispatch(setUnreadChatAndList(data)),
        addMessage: (data) => dispatch(addMessage(data)),
        updateChatAfterAddingMessage: (data) => dispatch(updateChatAfterAddingMessage(data)),
        updateChatReadStatus: (data) => dispatch(updateChatReadStatus(data)),
        setContactAddStatus: (data) => dispatch(setContactAddStatus(data)),
        getMe: () => dispatch(getMe()),
        setUnreadWebChatList: (data) => dispatch(setUnreadWebChatList(data))
    };
};
const mapStateToProps = (state) => {
    return {
        currentChatData: state.chat.currentChat.data,
        unreadChatCountAndList: state.chat.unreadChatCountAndList,
        currentChat: state.chat.currentChat,
        showNotificationPermission: state.auth.showNotificationPermission,
        user: state.auth.user,
        unreadWebChatList: state.chat.unreadWebChatList
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DefaultAside));
