import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ReactTooltip from "react-tooltip";
import Modal from "react-modal";
import { DefaultLayout } from "./containers";
import VerifyPage from "./views/VerifyPage/VerifyPage";
import Account from "./views/Account/Account";
import Logout from "./components/Logout/Logout";
import Signin from "./views/Signin/Signin";
import Registration from "./views/Registration/Registration";
import RegistrationSuccess from "./views/RegistrationSuccess/RegistrationSuccess";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";

function App(props) {
    const [isBooted, setIsBooted] = useState(false);
    const runScript = () => {
        if (window.Intercom && !isBooted) {
            window.Intercom("boot", {
                app_id: "ri83jky5",
                email: props.user.Email,
                name: props.user.Name,
                user_id: props.user.Id,
                alignment: "left",
                user_hash: props.user.intercomHash
            });
            setIsBooted(true);
        }
    };
    const unbootScript = () => {
        if (window.Intercom && isBooted) {
            window.Intercom("shutdown", {});
            setIsBooted(false);
        }
    };
    const toastProps = {
        autoClose: 3000,
        enableMultiContainer: true,
        closeButton: false,
        hideProgressBar: true,
        style: { marginTop: "3.125em", minWidth: "36.625em", right: "0em" },
        className: "custom-toast-container"
    };
    console.log("CHANGES ARE Reflecting")
    Modal.setAppElement("#root");

    return (
        <div id="range-connect">
            {props.isAuthenticated ? runScript() : unbootScript()}
            <ToastContainer
                containerId="success"
                toastClassName="custom-toast-message success-message"
                {...toastProps}
            />
            <ToastContainer
                containerId="error"
                toastClassName={"custom-toast-message error-message"}
                {...toastProps}
            />
            <Router>
                <Switch>
                    <Route path="/dashboard" component={DefaultLayout} />
                    <Route path="/verify/:token" component={VerifyPage} />
                    <Route path="/SSO/:apiKey" component={Signin} />
                    <Route path="/account" component={Account} />
                    <Route path="/logout" component={Logout} />
                    <Route path="/opt-in-registration" exact={true} component={Registration} />
                    <Route path="/opt-in-registration/success" component={RegistrationSuccess} />
                    <Redirect to="/account/login" />
                </Switch>
            </Router>
            <ReactTooltip place="right" type="dark" effect="solid" />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        isAuthenticated: state.auth.isAuthenticated
    };
};

export default connect(mapStateToProps, null)(App);
