import React from "react";
import "../../App.css";
import Modal from "react-modal";
class CustomModal extends React.Component {
    afterOpenModal = () => {
        document.getElementById("app-wrapper").classList.add("blur-effect");
    };
    closeModal = () => {
        document.getElementById("app-wrapper").classList.remove("blur-effect");
    };
    onAfterClose = () => {
        document.getElementById("app-wrapper").classList.remove("blur-effect");
    };
    render() {
        // function afterOpenModal() {
        //     // references are now sync'd and can be accessed.

        // }
        const { isOpen } = this.props;

        return (
            <div>
                <Modal
                    shouldCloseOnOverlayClick={true}
                    shouldCloseOnEsc={false}
                    isOpen={isOpen}
                    onRequestClose={this.closeModal}
                    onAfterClose={this.onAfterClose}
                    onAfterOpen={this.afterOpenModal}
                    className="modal-content"
                    overlayClassName="modal-overlay"
                >
                    <div>{this.props.children}</div>
                </Modal>
            </div>
        );
    }
}

export default CustomModal;
