export const NotificationPermissionTypes = {
    GRANTED: "granted",
    DENIED: "denied"
};
export const ServiceWorkerTypes = {
    ShowNotification: "ShowNotification"
};
export const ContactStatus = {
    OptIn: 1,
    OptOut: 2,
    OptInvited: 3,
    Closed: 4
};
export const GiyfValidationContraints = {
    maximumGiyfSize: 3.6,
    convertionDivisibleValue: 1000000
};
export const MMSStatus = {
    Yes: 1,
    No: 0
};
export const WebChatStatus = {
    Yes: 1,
    No: 0
};
export const InputType = {
    email: 1,
    number: 2,
    text: 3
};
export const FieldStatus = {
    Show: 1,
    Hide: 2
};
export const FormType = {
    OnBoard: 1
};
export const FormFieldSlug = {
    email: 3,
    birthday: 4,
    gender: 5,
    memberNo: 6
};

export const SELECT_ALL_CONTACTS_TYPES = {
    COMPANY: 1,
    CAMPAIGN: 2,
    TEST_CAMPAIGN: 3,
    NO: 0
};
export const SEGMENT_TYPE = {
    NoSegment: 0,
    ActualSegment: 1
};
export const AGE_GATED_STATUS = {
    Yes: 1,
    No: 2
};

export const MIN_AGE_STATUS = 21;
export const MIN_AGE_TO_JOIN_CLUBCHAT = 13;
export const NOT_AVALIABLE = "Not Avaliable";
