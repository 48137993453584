import React from "react";
import classNames from "classnames";

import Label from ".";

const LabelWithCount = ({
  label,
  count,
  className = "",
  labelClassName = "",
  countClassName = "",
}) => (
  <div
    className={classNames(className, "flex w-full items-center gap-2 justify-between")}
  >
    <Label className={labelClassName}>
      {label}
    </Label>
    
    <span
      className={classNames(countClassName, "text-lg text-gray-400 font-medium")}
    >
      {count}
    </span>
  </div>
);

export default LabelWithCount;

