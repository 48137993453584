import React from "react";

import "./MaterialInput.css";

const MaterialInput = (props) => {
    const {
        onChange,
        value,
        classes,
        label,
        isError,
        errorText,
        placeholder,
        type = "text"
    } = props;
    return (
        <div className="group">
            <input
                onChange={onChange}
                type={type}
                value={value}
                className={classes}
                placeholder={placeholder}
            />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>{label}</label>
            {isError && <p className="error-msg">{errorText}</p>}
        </div>
    );
};

export default MaterialInput;
