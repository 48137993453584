import React, { useEffect, useMemo } from "react";

import isEmpty from "lodash/isEmpty";
import noop from "lodash/noop";
import find from "lodash/find";

import { Tree } from "antd";

import { useGetContactsTotalCount } from "../../../hooks/api/contacts";
import LabelWithCount from "../../atoms/Typography/Label/WithCount";
import { connect } from "react-redux";
import { showLeadFeature } from "../../../utils/commonUtils";

const DEFAULT_STYLE = {
  display: "flex",
  padding: "10px 0",
};

export const PRESET_SEGMENTS_KEYS = {
  ACTIVE: "ACTIVE",
  INVITED: "INVITED",
  UNSUBSCRIBED: "UNSUBSCRIBED",
  LEAD: "LEAD",
  COLD_LEAD: 'COLD_LEAD',
};

let PRESET_SEGMENTS = [
  {
    title: ({total}) =>
      <LabelWithCount
        label="Active"
        count={total}
        className="w-56"
        labelClassName="sm:text-xl text-secondary"
        countClassName="sm:text-xl text-secondary"
      />,
    key: PRESET_SEGMENTS_KEYS.ACTIVE,
    total: null,
    style: {
      ...DEFAULT_STYLE,
      padding: "20px 0",
      borderBottom: "1px solid #e5e7eb",
    },
    children: [
      {
        title: ({total}) =>
          <LabelWithCount
            className="w-52"
            label="No Segment"
            count={total}
          />,
        key: -1,
        total: null,
        style: {
          ...DEFAULT_STYLE,
          paddingBottom: 80,
          borderBottom: "1px solid #e5e7eb",
        },
      }
    ],
  },
  // {
  //   title: ({total}) =>
  //     <LabelWithCount
  //       className="w-56"
  //       label="Lead"
  //       count={total}
  //     />,
  //   key: PRESET_SEGMENTS_KEYS.LEAD,
  //   total: null,
  //   style: DEFAULT_STYLE,
  //   children: [],
  // },
  // {
  //   title: ({total}) =>
  //     <LabelWithCount
  //       className="w-56"
  //       label="Cold Lead"
  //       count={total}
  //     />,
  //   key: PRESET_SEGMENTS_KEYS.COLD_LEAD,
  //   total: null,
  //   style: DEFAULT_STYLE,
  //   children: [],
  // },
  {
    title: ({total}) =>
      <LabelWithCount
        className="w-56"
        label="Invited"
        count={total}
      />,
    key: PRESET_SEGMENTS_KEYS.INVITED,
    total: null,
    style: DEFAULT_STYLE,
  },
  {
    title: ({total}) =>
      <LabelWithCount
        className="w-56"
        label="Unsubscribed"
        count={total}
      />,
    key: PRESET_SEGMENTS_KEYS.UNSUBSCRIBED,
    total: null,
    style: DEFAULT_STYLE,
  }
];

const ContactSegments = ({
  onChange = noop,
  value = [PRESET_SEGMENTS_KEYS.ACTIVE],
  user,
}) => {
  const contactsTotalCount = useGetContactsTotalCount();

  useEffect(() => {
    if (user) {
      if (showLeadFeature(user.Company?.Id)) {
        if (user.Type === 3 &&
          user &&
          user.Apps &&
          user.Company &&
          Boolean(Object.keys(user.Apps.filter((item) => item.Id === 4)).length) &&
          user.Company.WebChatAccess === 1 &&
          user.ShowWebChatTab) {
            let leadSegment = find(segments, { key: PRESET_SEGMENTS_KEYS.LEAD })
            if (!leadSegment) {
              PRESET_SEGMENTS.splice(1,0, {
                title: ({total}) =>
                  <LabelWithCount
                    className="w-56"
                    label="Lead"
                    count={total}
                  />,
                key: PRESET_SEGMENTS_KEYS.LEAD,
                total: null,
                style: DEFAULT_STYLE,
                children: [],
              })
            }
            let coldSegment = find(segments, { key: PRESET_SEGMENTS_KEYS.COLD_LEAD })
            if (!coldSegment) {
              PRESET_SEGMENTS.splice(2,0, {
                title: ({total}) =>
                  <LabelWithCount
                    className="w-56"
                    label="Cold Lead"
                    count={total}
                  />,
                key: PRESET_SEGMENTS_KEYS.COLD_LEAD,
                total: null,
                style: DEFAULT_STYLE,
                children: [],
              },)
            }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]) 

  const segments = useMemo(() => {
    const segments = [...PRESET_SEGMENTS];
    if(!isEmpty(contactsTotalCount)) {
      const normalizedSegmentsCount = contactsTotalCount
        .Segments
        .map(({
          TotalActiveContacts,
          segment
        }) => ({
          count: TotalActiveContacts,
          id: segment.Id,
        }));

      const leadSegmentsCount = contactsTotalCount
        .Segments
        .filter(({segment}) => segment.SegmentType === 2)
        .map(({
          TotalLeadContacts,
          segment
        }) => ({
          count: TotalLeadContacts,
          id: segment.Id,
        }));

      const coldLeadSegmentsCount = contactsTotalCount
        .Segments
        .filter(({segment}) => segment.SegmentType === 2)
        .map(({
          TotalColdContacts,
          segment
        }) => ({
          count: TotalColdContacts,
          id: segment.Id,
        }));

      const activeSegments = find(segments, { key: PRESET_SEGMENTS_KEYS.ACTIVE });

      activeSegments.total = contactsTotalCount.TotalActiveContacts;
      find(segments, { key: PRESET_SEGMENTS_KEYS.INVITED }).total = contactsTotalCount.TotalContactsInInvite;
      find(segments, { key: PRESET_SEGMENTS_KEYS.UNSUBSCRIBED }).total = contactsTotalCount.TotalInactiveContacts;
      let leadSegment = find(segments, { key: PRESET_SEGMENTS_KEYS.LEAD })
      if (leadSegment) {
        leadSegment.total = contactsTotalCount.TotalLeadContacts || 0;
      }
      let coldSegment = find(segments, { key: PRESET_SEGMENTS_KEYS.COLD_LEAD })
      if (coldSegment) {
        coldSegment.total = contactsTotalCount.TotalColdContacts || 0;
      }

      const leadSegments = find(segments, { key: PRESET_SEGMENTS_KEYS.LEAD });
      if (leadSegments) {
        leadSegments.children = [...contactsTotalCount.Segments
          .filter(({segment}) => segment.SegmentType === 2)
          .map(({segment}) => ({
            title: ({total}) =>
              <LabelWithCount
                className="w-52"
                label={segment.Title}
                count={total}
              />,
            key: `LEAD-${segment.Id}`,
            total: find(leadSegmentsCount, { id: segment.Id }).count,
            style: DEFAULT_STYLE,
          }))];
      }
      
      const coldLeadSegments = find(segments, { key: PRESET_SEGMENTS_KEYS.COLD_LEAD });
      if (coldLeadSegments) {
        coldLeadSegments.children = [...contactsTotalCount.Segments
          .filter(({segment}) => segment.SegmentType === 2)
          .map(({segment}) => ({
            title: ({total}) =>
              <LabelWithCount
                className="w-52"
                label={segment.Title}
                count={total}
              />,
            key: `COLD-LEAD-${segment.Id}`,
            total: find(coldLeadSegmentsCount, { id: segment.Id }).count,
            style: DEFAULT_STYLE,
          }))];
      }

      const noSegmentChild = find(activeSegments.children, { key: -1 });
      // ADD CHILD SEGMENTS
      activeSegments.children = [...contactsTotalCount.Segments
        .filter(({segment}) => segment.SegmentType === 1)
        .map(({segment}) => ({
          title: ({total}) =>
            <LabelWithCount
              className="w-52"
              label={segment.Title}
              count={total}
            />,
          key: segment.Id,
          total: find(normalizedSegmentsCount, { id: segment.Id }).count,
          style: DEFAULT_STYLE,
        }))
        // TO ENSURE THAT NO SEGMENT IS ALWAYS AT THE END
        .concat([noSegmentChild])];

      // NO SEGMENT COUNT
      find(activeSegments.children, { key: -1 }).total = contactsTotalCount.TotalActiveWithNoSegment;
    }
    return segments;
  }, [contactsTotalCount]);

  const onCheck = (checkedKeys, ev) => {
    if(isEmpty(checkedKeys)) {
      onChange([PRESET_SEGMENTS_KEYS.ACTIVE]);
      return;
    }

    if(ev.checked && isNaN(ev.node.key)) {
      if (ev.node.key.startsWith('LEAD-')) {
        onChange(checkedKeys.filter(k => typeof k === 'string' && k.startsWith('LEAD-')));
      } else if (ev.node.key.startsWith('COLD-LEAD-')) {
        onChange(checkedKeys.filter(k => typeof k === 'string' && k.startsWith('COLD-LEAD-')));
      } else {
        onChange([ev.node.key])
      }
    } else {
      if (typeof ev.node.key == 'string') {
        if (ev.node.key.startsWith('LEAD-')) {
          onChange(checkedKeys.filter(k => typeof k === 'string' && k.startsWith('LEAD-')));
        } else if (ev.node.key.startsWith('COLD-LEAD-')) {
          onChange(checkedKeys.filter(k => typeof k === 'string' && k.startsWith('COLD-LEAD-')));
        } else {
          onChange(checkedKeys.filter(k => !isNaN(k)));
        }
      } else {
        onChange(checkedKeys.filter(k => !isNaN(k)));
      }
    }
  } 

  return (
    <div className="px-2 border h-full w-80 border-gray-200 overflow-y-auto shrink-0">
      <Tree
        checkable
        defaultExpandAll
        blockNode
        selectable={false}
        treeData={segments}
        checkedKeys={value}
        onCheck={onCheck}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
      user: state.auth.user
  };
};
export default connect(mapStateToProps)(ContactSegments);
