import React from "react";

import "../../../App.css";

function CancelBtnRed({ onClick }) {
    return (
        <button onClick={onClick} className="cancel-btn-red">
            Cancel
        </button>
    );
}

export default CancelBtnRed;
