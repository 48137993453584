import React from "react";

import "../../App.css";

function Checkbox({ checked, onChange, id }) {
    return (
        <div className="checkbox-wrapper">
            <input type="checkbox" checked={checked} onChange={onChange} id={`label-item-${id}`} />
            <label className="checkmark" htmlFor={`label-item-${id}`}></label>
        </div>
    );
}

export default Checkbox;
