import {
    SET_UNREAD_CHAT_AND_LIST,
    CHAT_LIST_REQUEST,
    CHAT_LIST_SUCCESS,
    CHAT_LIST_FAIL,
    SET_CURRENT_CHAT_DETAILS,
    SET_CHAT_DETAIL_FROM_ALL_CONTACTS,
    CHAT_MESSAGES_REQUEST,
    CHAT_MESSAGES_SUCCESS,
    CHAT_MESSAGES_FAIL,
    ADD_MESSAGE,
    CHANGE_MESSAGE_ACKNOWLEDGEMENT,
    DELETE_MESSAGE,
    SET_MESSAGE_ERROR,
    MOVE_CHAT_TO_TOP,
    READ_MESSAGE_SUCCESS,
    CHANGE_CURRENT_CHAT_CONTACT_STATUS,
    CHANGE_CHAT_CONTACT_STATUS,
    REMOVE_CURRENT_CHAT,
    SET_IMAGE_LOADING_STATUS,
    SET_IMAGE_LOADING_SCROLL,
    UPDATE_CHAT_LIST_OBJECT,
    UPDATE_CHAT_LIST_ON_STATUS_CHANGE,
    UPDATE_CHAT_READ_STATUS,
    CHAT_NOTIFICATION_STATUS,
    SET_UNREAD_WEBCHAT_LIST
} from "../actions";
import { PAGINATION_LIMIT, ChatReadStatus } from "../../config/constants";

const initialState = {
    isImageUploading: false,
    isImageLoadingScroll: false,
    chatList: {
        fetching: false,
        list: [],
        totalChats: 0,
        dataAvailable: true
    },
    selectedChatIdFromAllContact: null,
    currentChat: {
        fetching: false,
        dataAvailable: true,
        messages: [],
        data: {},
        totalMessages: 0
    },
    unreadChatCountAndList: {
        Count: 0,
        unreadChatId: []
    },
    unreadWebChatList: [],
    notificationStatus: {
        onBoardStatus: null,
        contactCardStatus: null
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_CHAT_READ_STATUS:
            let Chatlists = [...state.chatList.list];

            const IndexOfChat = state.chatList.list.findIndex((chat) => chat.Id === action.payload);
            if (IndexOfChat > -1) {
                Chatlists[IndexOfChat].ReadStatus = 1;
            }
            return {
                ...state,
                chatList: { ...state.chatList, list: Chatlists }
            };
        case SET_UNREAD_CHAT_AND_LIST:
            return {
                ...state,
                unreadChatCountAndList: action.payload
            };
        case UPDATE_CHAT_LIST_ON_STATUS_CHANGE:
            const IndexOfChatIdOfContacts = state.chatList.list.findIndex(
                (chat) => chat.ContactId === action.payload.chatId
            );
            let Chatslist = [...state.chatList.list];
            Chatslist.splice(IndexOfChatIdOfContacts, 1);
            if (
                action.payload &&
                action.payload.chatId &&
                state.currentChat &&
                state.currentChat.data &&
                action.payload.chatId === state.currentChat.data.ContactId
            ) {
                return {
                    ...state,
                    chatList: { ...state.chatList, list: Chatslist },
                    currentChat: {
                        fetching: false,
                        dataAvailable: true,
                        messages: [],
                        data: {},
                        totalMessages: 0
                    }
                };
            } else {
                return {
                    ...state,
                    chatList: { ...state.chatList, list: Chatslist }
                };
            }

        case REMOVE_CURRENT_CHAT:
            return {
                ...state,
                currentChat: {
                    fetching: false,
                    dataAvailable: true,
                    messages: [],
                    data: {},
                    totalMessages: 0
                }
            };
        case UPDATE_CHAT_LIST_OBJECT:
            const IndexOfChatId = state.chatList.list.findIndex(
                (chat) => chat.ContactId === action.payload.Id
            );
            let Chatlist = [...state.chatList.list];
            if (action.payload.Meta) {
                Chatlist[IndexOfChatId].Contact.Meta = action.payload.Meta;
            }
            if (action.payload.Email) {
                Chatlist[IndexOfChatId].Contact.Email = action.payload.Email;
            }
            if (action.payload.MemberId) {
                Chatlist[IndexOfChatId].Contact.MemberId = action.payload.MemberId;
            }
            if (action.payload.FirstName) {
                Chatlist[IndexOfChatId].Contact.FirstName = action.payload.FirstName;
            }
            if (action.payload.LastName) {
                Chatlist[IndexOfChatId].Contact.LastName = action.payload.LastName;
            }
            if (action.payload.PhoneNumber) {
                Chatlist[IndexOfChatId].Contact.PhoneNumber = action.payload.PhoneNumber;
            }
            if (action.payload.Segments) {
                Chatlist[IndexOfChatId].Contact.Segments = action.payload.Segments;
            }
            return {
                ...state,
                chatList: { ...state.chatList, list: Chatlist },
                currentChat: { ...state.currentChat }
            };
        case SET_IMAGE_LOADING_STATUS:
            return {
                ...state,
                isImageUploading: action.payload
            };
        case SET_IMAGE_LOADING_SCROLL:
            return {
                ...state,
                isImageLoadingScroll: action.payload
            };
        case CHAT_LIST_REQUEST:
            return {
                ...state,
                chatList: {
                    ...state.chatList,
                    fetching: true,
                    list: (() => {
                        if (action.Page > 1) {
                            return [...state.chatList.list];
                        } else {
                            return [];
                        }
                    })()
                }
            };
        case CHAT_LIST_SUCCESS:
            return {
                ...state,
                chatList: {
                    ...state.chatList,
                    fetching: false,
                    dataAvailable: action.payload.Chats.length >= PAGINATION_LIMIT,
                    totalChats: action.payload.TotalChats,
                    list: (() => {
                        if (action.Page && action.Page > 1) {
                            return [...state.chatList.list, ...action.payload.Chats];
                        } else {
                            return action.payload.Chats;
                        }
                    })()
                }
            };
        case CHAT_LIST_FAIL:
            return {
                ...state,
                chatList: {
                    ...state.chatList,
                    fetching: false
                }
            };
        case SET_CHAT_DETAIL_FROM_ALL_CONTACTS:
            return {
                ...state,
                selectedChatIdFromAllContact: action.payload
            };
        case SET_CURRENT_CHAT_DETAILS:
            return {
                ...state,
                currentChat: {
                    ...state.currentChat,
                    data: action.payload
                }
            };
        case SET_UNREAD_WEBCHAT_LIST:
            return {
                ...state,
                unreadWebChatList: action.payload
            };
        case CHAT_MESSAGES_REQUEST:
            return {
                ...state,
                currentChat: {
                    ...state.currentChat,
                    fetching: true,
                    messages: (() => {
                        if (action.Page > 1) {
                            return [...state.currentChat.messages];
                        } else {
                            return [];
                        }
                    })()
                }
            };
        case CHAT_MESSAGES_SUCCESS:
            return {
                ...state,
                currentChat: {
                    ...state.currentChat,
                    fetching: false,
                    dataAvailable: action.payload.length >= PAGINATION_LIMIT,
                    totalMessages: action.totalMessages,
                    messages: (() => {
                        if (action.Page && action.Page > 1) {
                            return [...action.payload, ...state.currentChat.messages];
                        } else {
                            return action.payload;
                        }
                    })()
                }
            };
        case CHAT_MESSAGES_FAIL:
            return {
                ...state,
                currentChat: {
                    ...state.currentChat,
                    fetching: false
                }
            };
        case ADD_MESSAGE:
            return {
                ...state,
                currentChat: {
                    ...state.currentChat,
                    messages: [...state.currentChat.messages, action.payload]
                }
            };
        case CHANGE_MESSAGE_ACKNOWLEDGEMENT:
            return {
                ...state,
                currentChat: {
                    ...state.currentChat,
                    messages: state.currentChat.messages.map((msgData) => {
                        if (msgData.Id === action.payload.messageId) {
                            return {
                                ...msgData,
                                isAcknowledged: action.payload.acknowledge,
                                Error: action.payload.error
                            };
                        }
                        return {
                            ...msgData
                        };
                    })
                }
            };
        case DELETE_MESSAGE:
            const messages = [...state.currentChat.messages];
            const index = messages.map((o) => o.Id).indexOf(action.payload.messageId);
            console.log(index);
            if (index > -1) {
                messages.splice(index, 1);
            }
            return {
                ...state,
                currentChat: {
                    ...state.currentChat,
                    messages: [...messages]
                }
            };
        case SET_MESSAGE_ERROR:
            return {
                ...state,
                currentChat: {
                    ...state.currentChat,
                    messages: state.currentChat.messages.map((msg) => {
                        if (msg.hasOwnProperty("isAcknowledged") && !msg.isAcknowledged) {
                            return {
                                ...msg,
                                Error: true
                            };
                        }
                        return {
                            ...msg
                        };
                    })
                }
            };
        case MOVE_CHAT_TO_TOP:
            return {
                ...state,
                chatList: {
                    ...state.chatList,
                    list: [...action.payload]
                }
            };
        case READ_MESSAGE_SUCCESS:
            return {
                ...state,
                chatList: {
                    ...state.chatList,
                    list: state.chatList.list.map((chat) => {
                        if (chat.Id === action.payload) {
                            return {
                                ...chat,
                                ReadStatus: ChatReadStatus.READ
                            };
                        }
                        return {
                            ...chat
                        };
                    })
                }
            };
        case CHANGE_CURRENT_CHAT_CONTACT_STATUS:
            return {
                ...state,
                currentChat: {
                    ...state.currentChat,
                    data: {
                        ...state.currentChat.data,
                        Contact: {
                            ...state.currentChat.data.Contact,
                            OptStatus: action.payload
                        }
                    }
                }
            };
        case CHANGE_CHAT_CONTACT_STATUS:
            return {
                ...state,
                chatList: {
                    ...state.chatList,
                    list: state.chatList.list.filter((chat) => {
                        if (chat.ContactId === action.payload.contactId) {
                            return null;
                        } else {
                            return {
                                ...chat
                            };
                        }
                    })
                }
            };
        case CHAT_NOTIFICATION_STATUS:
            return {
                ...state,
                notificationStatus: {
                    onBoardStatus: action.payload.Onboard,
                    contactCardStatus: action.payload.ContactCard
                }
            };
        default:
            return state;
    }
};

