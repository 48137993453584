import {
    CheckCircleFilled,
    CloseCircleFilled,
    InfoCircleFilled,
    LoadingOutlined
} from "@ant-design/icons";
import { Button, Card, Spin } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUploadList } from "../../redux/actions";
import { CustomModal } from "../modal";
import ImportContactModal from "../../views/AllContacts/ImportContactModal";

function UploadList() {
    const { uploads } = useSelector((state) => state.contacts);
    const [isImportFormVisible, showImportForm] = React.useState(false);
    const [currentIndex, setCurrentIndex] = React.useState(undefined);
    const dispatch = useDispatch();
    console.log("----------Uploads", uploads);

    const deleteUploadItem = (index) => {
        console.log("Delete Upload Item", index);
        const newUploads = [...uploads];
        newUploads.splice(index, 1);
        dispatch(setUploadList(newUploads));
    };

    const openUploadDetails = (e, index) => {
        e.preventDefault();
        console.log("Open Upload Details", index);
        showImportForm(true);
        setCurrentIndex(index);
    };

    return uploads.length > 0 ? (
        <>
            <div className="upload-list-container">
                {uploads.map((o, index) => (
                    <Card
                        key={o.id}
                        title=""
                        bordered={true}
                        hoverable
                        style={{ marginBottom: 10 }}
                        actions={
                            o.uploaded
                                ? [
                                      <span className="upload-list-action-status">
                                          <CheckCircleFilled
                                              style={{
                                                  color: "#52c41a",
                                                  fontSize: 18,
                                                  marginRight: 5
                                              }}
                                              key="success"
                                          />{" "}
                                          <span>{o.SuccessContacts?.length || 0}</span>
                                      </span>,
                                      <span className="upload-list-action-status">
                                          <InfoCircleFilled
                                              style={{
                                                  color: "#2196f3",
                                                  fontSize: 18,
                                                  marginRight: 5
                                              }}
                                              key="info"
                                          />
                                          <span>{o.UpdatedContacts?.length || 0}</span>
                                      </span>,
                                      <span className="upload-list-action-status">
                                          <CloseCircleFilled
                                              style={{
                                                  color: "#f44336",
                                                  fontSize: 18,
                                                  marginRight: 5
                                              }}
                                              key="error"
                                          />
                                          <span>{o.FailedContacts?.length || 0}</span>
                                      </span>
                                  ]
                                : null
                        }
                        onClick={(e) => o.uploaded && openUploadDetails(e, index)}
                    >
                        <div className="flex items-center justify-between">
                            <Title level={4} style={{ margin: 0 }}>
                                {o.filename}
                                {/* <span style={{marginLeft: 5, color: '#2196f3', fontSize: 12}}>View</span> */}
                            </Title>
                            {o.uploaded ? (
                                <Button
                                    type="text"
                                    style={{ color: "#3aaf50" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        deleteUploadItem(index);
                                    }}
                                >
                                    Confirm
                                </Button>
                            ) : (
                                <Spin indicator={<LoadingOutlined spin />} />
                            )}
                        </div>
                    </Card>
                ))}
            </div>
            {isImportFormVisible && (
                <CustomModal isOpen={isImportFormVisible}>
                    <ImportContactModal
                        handleImportContactModalClose={() => showImportForm(false)}
                        handleImportContactAlertOpen={() => showImportForm(false)}
                        currentIndex={currentIndex}
                        onSuccess={() => {
                            showImportForm(false);
                            // queryClient.invalidateQueries(["contacts", fetchParams]);
                            // queryClient.invalidateQueries(["contactsTotalCount"]);
                            // onAction();
                        }}
                    />
                </CustomModal>
            )}
        </>
    ) : null;
}

export default UploadList;

