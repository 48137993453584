import React, { useEffect, useRef } from "react";

const Favicon = ({ children, count }) => {
    const ref = useRef(null);

    console.log(count);

    useEffect(() => {
        // alternatively, use React Helmet etc.
        const favicon = document.querySelector('link[rel="shortcut icon"]');

        if (favicon && ref.current) {
            favicon.href = `data:image/svg+xml;utf8,${encodeURIComponent(ref.current.outerHTML)}`;
        }
    }, [children, count]);

    return (
        <div hidden={true}>
            <svg
                ref={ref}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 17.2 15.8"
                style={{
                    enableBackground: "new 0 0 17.2 15.8"
                }}
                xmlSpace="preserve"
            >
                <path
                    d="M16.9 14.5c.3.3.2.8-.1 1-.1.1-.3.2-.5.2H9.7c-2.2 0-4.2-.9-5.7-2.5h3.5c4.5-.1 8.1-3.1 8.3-6.9.9 1 1.3 2.2 1.3 3.5v.4c-.1 1.2-.6 2.2-1.4 3.1l-.1.1 1.3 1.1m-2.4-8.4c0 3.3-3.2 5.8-7 5.9H.8c-.4 0-.8-.4-.8-.8 0-.2.1-.4.2-.5l.1-.1 1.2-1.1h-.1C.6 8.7.2 7.6.1 6.4v-.3C0 2.8 3.3.2 7.3.2s7.2 2.6 7.2 5.9zM9 7.3c0-.4-.3-.7-.7-.7H3.7c-.4.1-.6.5-.5.9.1.3.3.4.6.5h4.6c.3 0 .6-.3.6-.7zm2-2c0-.4-.3-.7-.8-.7H3.7c-.4 0-.7.3-.7.7 0 .4.3.7.8.7h6.6c.3.1.6-.3.6-.7z"
                    style={{
                        fill: "#00a850"
                    }}
                />
                {count && (
                    <>
                        <circle
                            cx={13.3}
                            cy={3.9}
                            r={3.9}
                            style={{
                                fill: "#e84d4e"
                            }}
                        />

                        <text
                            transform={
                                count < 9
                                    ? "translate(10.894 6.949)"
                                    : "matrix(1 0 0 1 9.7016 5.6405)"
                            }
                            style={{
                                fontSize: "9.3813px",
                                fontFamily: "'MyriadPro-Regular'",
                                fill: "#fff"
                            }}
                        >
                            {count > 9 ? "9+" : count}
                        </text>
                    </>
                )}
            </svg>
        </div>
    );
};

export default Favicon;
