import { fetchUtil } from "../../utils/fetchUtils";
import { getToken } from "../../utils/tokenUtils";
import { useQuery } from "@tanstack/react-query";

const getChatNotificationsStatus = async (contactId) => {
    const token = getToken();
    const data = await fetchUtil({
        url: `/chat/notification/history/${contactId}`,
        token
    });
    return data;
};

export const useGetChatNotificationsStatus = (contactId) => 
    useQuery({
        queryKey: ["chatNotificationStatus", contactId],
        queryFn: () => getChatNotificationsStatus(contactId),
        enabled: !!contactId,
        refetchOnWindowFocus: false,
    });