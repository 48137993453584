import { connect } from "react-redux";
import React, { useState } from "react";
import { validateEmail } from "../../../../utils/validateHelperUtils";
import { Spinner } from "../../../../components/Loader";
import { exportContactsDetailsByCampaignId } from "../../../../redux/actions";
import { Modal } from "antd";

const ExportModal = (props) => {
    const [email, setEmail] = useState("");
    const [emailError, setemailError] = useState(false);
    const [loadingExport, setloadingExport] = useState(false);
    const exportCampaignContacts = (e) => {
        if (!emailError) {
            if (email === "") {
                setemailError(true);
                return;
            }
            setloadingExport(true);
            props.exportCampaignContacts(props.selectedCampain.Id, { Email: email }).then(
                (res) => {
                    setloadingExport(false);
                    props.exportLeadSuccessCallBack();
                },
                (err) => {
                    setloadingExport(false);
                }
            );
        }
    };
    const onChange = (e) => {
        setEmail(e.target.value);
        if (!validateEmail(e.target.value)) {
            setemailError(true);
        } else {
            setemailError(false);
        }
    };
    return (
                <Modal
                    open={true}
                    onCancel={() => props.exportLeadSuccessCallBack()}
                    footer={null}
                >
                    <div className="reports-export-modal">
                        <div className="modal-content export-modals border-none">
                            <div className="modal-header">
                                <h2>Export Recipient List</h2>
                            </div>
                            <div className="export-modal-form">
                                <h3>
                                    In the field below, enter the email address to which we should
                                    send the CSV file containing the List.
                                </h3>
                                <input
                                    className={emailError ? "error-email" : ``}
                                    onChange={(e) => onChange(e)}
                                    type="text"
                                    placeholder="Type email"
                                />
                                {loadingExport ? (
                                    <button disabled>
                                        <Spinner size="lg" color="grey" />
                                    </button>
                                ) : (
                                    <button onClick={() => exportCampaignContacts()}>Export</button>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => {
    return {
        exportCampaignContacts: (id, body) =>
            dispatch(exportContactsDetailsByCampaignId(id, body))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportModal);
