import isURL from "validator/lib/isURL";
import Config from "../config";

export const appendQueryParams = (url, paramsObj) => {
    let newUrl;
    const paramsArr = [];
    Object.keys(paramsObj).forEach((key) => {
        if (Array.isArray(paramsObj[key])) {
            paramsObj[key].forEach((paramsVal) => {
                paramsArr.push(`${key}[]=${paramsVal}`);
            });
        } else {
            paramsArr.push(`${key}=${paramsObj[key]}`);
        }
    });
    newUrl = `${url}?${paramsArr.join("&")}`;
    return newUrl;
};

export const validateUrl = (url) => {
    return isURL(url);
};

export const isDomainExist = (url) => {
    return url.includes(`${Config.env().SHORTEN_URL_DOMAIN}`) ? false : true;
};
export const cloneEvent = (e) => {
    if (e === undefined || e === null) return undefined;
    function ClonedEvent() {}
    let clone = new ClonedEvent();
    for (let p in e) {
        let d = Object.getOwnPropertyDescriptor(e, p);
        if (d && (d.get || d.set)) Object.defineProperty(clone, p, d);
        else clone[p] = e[p];
    }
    Object.setPrototypeOf(clone, e);
    return clone;
};
