import React from "react";
import "../../../App.css";

class InboxChatboxInputCharCount extends React.Component {
    render() {
        const { charCount, maxCount = 1000 } = this.props;
        return (
            <span>
                {" "}
                {charCount}/{maxCount} characters
            </span>
        );
    }
}

export default InboxChatboxInputCharCount;
