import React from "react";
// import { MIN_AGE_STATUS } from '../../constant'
import { AgeGate } from "../../assets/images/index";
const AgeStatusContainer = React.memo(() => {
    return (
        <div
            style={{
                display: "flex",
                borderRadius: "15px",
                alignItems: "center",
                minWidth: "30px"
            }}
        >
            {/* <span className="last-name"
                style={{ color: 'white', fontSize: '0.5vw' }}
            >
                {`${MIN_AGE_STATUS}+`}

            </span> */}
            <img
                src={AgeGate}
                alt={"Green tick"}
                style={{
                    width: "22px",
                    height: "22px"
                }}
            />
        </div>
    );
});

export default AgeStatusContainer;
