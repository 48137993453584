export default {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thurday: 4,
    Firday: 5,
    Saturday: 6,
    Sunday: 0,
    EveryDay: 7,
    Weekend: 8,
    Weekday: 9
};
