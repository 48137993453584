import { useReducer, useCallback } from "react";

const useFormState = (initialState = {}) => {
    const reducer = (state, action) => {
        switch (action.type) {
            case "set":
                return {
                    ...state,
                    [action.field]: action.value
                };
            case "clear":
                return {
                    ...state,
                    [action.field]: undefined
                };
            case "reinit":
                return initialState;
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const set = useCallback((field) => (value) =>
        dispatch({
            type: "set",
            field,
            value
        }), [dispatch]);

    const reset = useCallback((field) => () =>
        dispatch({
            type: "set",
            field,
            value: initialState[field]
        }), [dispatch, initialState]);

    const clear = useCallback((field) => () =>
        dispatch({
            type: "clear",
            field
        }), [dispatch]);

    const reinit = useCallback(() => dispatch({ type: 'reinit' }), [dispatch]);

    return [
        state,
        {
            set,
            reset,
            clear,
            reinit
        }
    ];
};

export default useFormState;
