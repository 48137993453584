import { useQuery } from "@tanstack/react-query";

import { fetchUtil } from "../../utils/fetchUtils";
import { getToken } from "../../utils/tokenUtils";

const getCompany = async () => {
  const token = getToken();
  const res =  fetchUtil({
      url: '/get/user/company',
      token
  })
  return res
};

export const useGetCompany = (options = {}) => {
  return useQuery({
    queryKey: ["company"],
    queryFn:  getCompany,
    refetchOnWindowFocus: false,
    ...options,
  });
}