import React from "react";
import { whiteCheck } from "../../assets/images";

const RoundCheckBox = ({ checked, onChange, id }) => {
    const handleClick = () => {
        if (id) {
            onChange(id);
        }
    };
    const isChecked = () => {
        return checked === id;
    };
    return (
        <div
            onClick={handleClick}
            style={{
                display: "flex",
                width: "2.5vh",
                height: "2.6vh",
                backgroundColor: isChecked() ? "#3AAF50" : null,
                borderRadius: "100%",
                justifyContent: "center",
                alignItems: "center",
                borderStyle: !isChecked() ? "solid" : null,
                borderWidth: !isChecked() ? 0.5 : 0,
                border: !isChecked() ? "2px solid " : null,
                borderColor: !isChecked() ? "black" : null
            }}
        >
            {isChecked() && (
                <img src={whiteCheck} alt="white check" style={{ width: "75%", height: "75%" }} />
            )}
        </div>
    );
};

export default RoundCheckBox;
