import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { footerLogoImage, LogoSvg } from "../../../assets/svg-icon/index";
import "../Form.css";
import { Spinner } from "../../../components/Loader";
import { validateEmail } from "../../../utils/validateHelperUtils";
import { resetPassword } from "../../../redux/actions";
const ForgetLogin = (props) => {
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [emailError, setemailError] = useState(null);
    const [forgotPasswordStatus, setforgotPasswordStatus] = useState(false);
    const [toLogin, settoLogin] = useState(false);

    const handleLoginSubmit = (e) => {
        e.preventDefault();

        if (!validateEmail(email)) {
            setemailError("Please enter valid email address");
            return;
        }
        setIsLoading(true);
        props
            .resetPassword(email)
            .then((res) => {
                setIsLoading(false);
                setforgotPasswordStatus(true);
            })
            .catch((err) => {
                setemailError("There's no user associated with this email");
                setIsLoading(false);
            });
    };
    const handleOnChange = (e) => {
        setemailError(null);
        setEmail(e.target.value);
    };
    if (toLogin) {
        return <Redirect to="/account/login" />;
    }
    return (
        <div className="login-screen">
            <div className="container">
                <div className="row">
                    <div className="w-12">
                        <div className="login-box">
                            <form className="login-form" onSubmit={handleLoginSubmit}>
                                <div className="login-logo">
                                    <img src={LogoSvg} className="img-fluid" alt="img" />
                                </div>
                                {!forgotPasswordStatus ? (
                                    <>
                                        <div className="login-form">
                                            <div className="form-group">
                                                <h2>Need a reset?</h2>
                                                <h5>
                                                    {emailError
                                                        ? emailError
                                                        : `Enter your account email`}
                                                </h5>
                                                <input
                                                    type="email"
                                                    className={`form-control ${
                                                        emailError ? `red` : ``
                                                    }`}
                                                    value={email}
                                                    required
                                                    onChange={handleOnChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" disabled={isLoading}>
                                                    <span>Reset Password</span>
                                                    {isLoading ? (
                                                        <Spinner color="white" size="lg" />
                                                    ) : null}
                                                </button>
                                                <a href="/account/login">Return to login</a>
                                            </div>
                                        </div>
                                        <div className="login-footer-logo">
                                            <img
                                                src={footerLogoImage}
                                                className="img-fluid"
                                                alt="img"
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="login-form">
                                            <div className="form-group">
                                                <h2>Check your email</h2>
                                                <h5>
                                                    You will receive a link instructions on
                                                    resetting the password.
                                                </h5>
                                            </div>
                                            <div className="form-group">
                                                <button
                                                    type="submit"
                                                    onClick={() => settoLogin(true)}
                                                >
                                                    <span>Return to login</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="login-footer-logo">
                                            <img
                                                src={footerLogoImage}
                                                className="img-fluid"
                                                alt="img"
                                            />
                                        </div>
                                    </>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        resetPassword: (email) => dispatch(resetPassword(email))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgetLogin);
